import {
    GET_AKUNBUKUBESAR,
    ADD_AKUNBUKUBESAR,
    UPDATE_AKUNBUKUBESAR,
    EDIT_AKUNBUKUBESAR,
    DELETE_AKUNBUKUBESAR,

    GET_AKUN,
    ADD_AKUN,
    UPDATE_AKUN,
    EDIT_AKUN,
    DELETE_AKUN,

    GET_KELOMPOK,
    ADD_KELOMPOK,
    UPDATE_KELOMPOK,
    EDIT_KELOMPOK,
    DELETE_KELOMPOK,

    GET_GOLONGAN,
    ADD_GOLONGAN,
    UPDATE_GOLONGAN,
    EDIT_GOLONGAN,
    DELETE_GOLONGAN,

    GET_SUBGOLONGAN,
    ADD_SUBGOLONGAN,
    UPDATE_SUBGOLONGAN,
    EDIT_SUBGOLONGAN,
    DELETE_SUBGOLONGAN,
  } from '../../pages/actions/datacoaAction';
  
  const datacoa = (state, action) => {
    const { type } = action;
    switch (type) {
      case GET_AKUNBUKUBESAR:
        return {
          ...state,
          getAkunBukuBesarResult: action.payload.data,
          getAkunBukuBesarLoading: action.payload.loading,
          getAkunBukuBesarError: action.payload.errorMessage,
        };
      case ADD_AKUNBUKUBESAR:
        return {
          ...state,
          addAkunBukuBesarResult: action.payload.data,
          addAkunBukuBesarLoading: action.payload.loading,
          addAkunBukuBesarError: action.payload.errorMessage,
        };
      case EDIT_AKUNBUKUBESAR:
        return {
          ...state,
          editAkunBukuBesarResult: action.payload.data,
        };
      case UPDATE_AKUNBUKUBESAR:
        return {
          ...state,
          updateAkunBukuBesarResult: action.payload.data,
          updateAkunBukuBesarLoading: action.payload.loading,
          updateAkunBukuBesarError: action.payload.errorMessage,
        };
      case DELETE_AKUNBUKUBESAR:
        return {
          ...state,
          deleteAkunBukuBesarResult: action.payload.data,
        };
      case GET_AKUN:
        return {
          ...state,
          getAkunResult: action.payload.data,
          getAkunLoading: action.payload.loading,
          getAkunError: action.payload.errorMessage,
        };
      case ADD_AKUN:
        return {
          ...state,
          addAkunResult: action.payload.data,
          addAkunLoading: action.payload.loading,
          addAkunError: action.payload.errorMessage,
        };
      case EDIT_AKUN:
        return {
          ...state,
          editAkunResult: action.payload.data,
        };
      case UPDATE_AKUN:
        return {
          ...state,
          updateAkunResult: action.payload.data,
          updateAkunLoading: action.payload.loading,
          updateAkunError: action.payload.errorMessage,
        };
      case DELETE_AKUN:
        return {
          ...state,
          deleteAkunResult: action.payload.data,
        };
      case GET_KELOMPOK:
        return {
          ...state,
          getKelompokResult: action.payload.data,
          getKelompokLoading: action.payload.loading,
          getKelompokError: action.payload.errorMessage,
        };
      case ADD_KELOMPOK:
        return {
          ...state,
          addKelompokResult: action.payload.data,
          addKelompokLoading: action.payload.loading,
          addKelompokError: action.payload.errorMessage,
        };
      case EDIT_KELOMPOK:
        return {
          ...state,
          editKelompokResult: action.payload.data,
        };
      case UPDATE_KELOMPOK:
        return {
          ...state,
          updateKelompokResult: action.payload.data,
          updateKelompokLoading: action.payload.loading,
          updateKelompokError: action.payload.errorMessage,
        };
      case DELETE_KELOMPOK:
        return {
          ...state,
          deleteKelompokResult: action.payload.data,
        };
      case GET_GOLONGAN:
        return {
          ...state,
          getGolonganResult: action.payload.data,
          getGolonganLoading: action.payload.loading,
          getGolonganError: action.payload.errorMessage,
        };
      case ADD_GOLONGAN:
        return {
          ...state,
          addGolonganResult: action.payload.data,
          addGolonganLoading: action.payload.loading,
          addGolonganError: action.payload.errorMessage,
        };
      case EDIT_GOLONGAN:
        return {
          ...state,
          editGolonganResult: action.payload.data,
        };
      case UPDATE_GOLONGAN:
        return {
          ...state,
          updateGolonganResult: action.payload.data,
          updateGolonganLoading: action.payload.loading,
          updateGolonganError: action.payload.errorMessage,
        };
      case DELETE_GOLONGAN:
        return {
          ...state,
          deleteGolonganResult: action.payload.data,
        };
      case GET_SUBGOLONGAN:
        return {
          ...state,
          getSubGolonganResult: action.payload.data,
          getSubGolonganLoading: action.payload.loading,
          getSubGolonganError: action.payload.errorMessage,
        };
      case ADD_SUBGOLONGAN:
        return {
          ...state,
          addSubGolonganResult: action.payload.data,
          addSubGolonganLoading: action.payload.loading,
          addSubGolonganError: action.payload.errorMessage,
        };
      case EDIT_SUBGOLONGAN:
        return {
          ...state,
          editSubGolonganResult: action.payload.data,
        };
      case UPDATE_SUBGOLONGAN:
        return {
          ...state,
          updateSubGolonganResult: action.payload.data,
          updateSubGolonganLoading: action.payload.loading,
          updateSubGolonganError: action.payload.errorMessage,
        };
      case DELETE_SUBGOLONGAN:
        return {
          ...state,
          deleteSubGolonganResult: action.payload.data,
        };
      default:
        return state;
    }
  };
  
  export default datacoa;
  