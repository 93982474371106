import logo from "../../assets/img/logo/logo.jpg";
// import logo from "../../assets/img/logo/gpl_png_2.png";
import sidebarBgImage from "../../assets/img/sidebar/sidebar-4.jpg";
import SourceLink from "../../components/SourceLink";
import React from "react";
import { FaMoneyCheck, FaWifi, FaCoins, FaUsers, FaMoneyBillWaveAlt, FaListUl, FaPencilRuler, FaBuilding, FaUserCircle, FaThList } from "react-icons/fa";
import { MdDashboard, MdKeyboardArrowDown, MdRadioButtonChecked } from "react-icons/md";
import { NavLink } from "react-router-dom";
import {
  // UncontrolledTooltip,
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from "reactstrap";
import { isAuthenticated } from "../api/authenticationApi";
import bn from "../../utils/bemnames";

const sidebarBackground = {
  // backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
};

const navDashboard = [
  { to: "/", name: "Dashboard Keuangan", exact: true, Icon: MdRadioButtonChecked },
  { to: "/dashboard-teknis", name: "Dashboard Teknis", exact: true, Icon: MdRadioButtonChecked },
  { to: "/dashboard-sales", name: "Dashboard Sales", exact: true, Icon: MdRadioButtonChecked },
];
const navFileMaster = [
  {
    to: "/tipebayar",
    name: "Tipe Bayar",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/tipearuskas",
    name: "Tipe Arus Kas",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/rekeningbank",
    name: "Rekening Bank",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: '/satuanproduk',
    name: 'Satuan Produk',
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: '/lokasiinventori',
    name: 'Lokasi Inventori',
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: '/sales',
    name: 'Sales',
    exact: false,
    Icon: MdRadioButtonChecked,
  },
];
const navPelanggan = [
  {
    to: "/registrasi",
    name: "registrasi",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/datapelanggan",
    name: "data pelanggan",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: '/tiketgangguan',
    name: 'tiket gangguan',
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/datatagihan",
    name: "data tagihan",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/datapembayaran",
    name: "data pembayaran",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/datapiutang",
    name: "data piutang",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/penerimaan",
    name: "Kasir & Finance",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
];

const navMitrakerja = [
  {
    to: "/pemasok",
    name: "Pemasok",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/vendor",
    name: "Vendor",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/freelence",
    name: "Freelence",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/chanel",
    name: "Chanel",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
];

const navProduk = [
  {
    to: "/produk-ritel",
    name: "Ritel",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/perusahaan",
    name: "Perusahaan",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/pemerintah",
    name: "Pemerintah",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
];

const navProject = [
  {
    to: "/manajemen-proyek",
    name: "Manajemen Proyek",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/artefak-proyek",
    name: "Artefak Proyek",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
];

const navArtakelola = [
  {
    to: "/preakseptasi",
    name: "Preakseptasi",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/prakeluaran",
    name: "Prakeluaran",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/realisasi",
    name: "Realisasi",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
];

const navInventori = [
  {
    to: '/inventori/pengadaan',
    name: 'Pengadaan',
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: '/dataaset',
    name: 'Data Aset',
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: '/inventori/datastok',
    name: 'Data Stok',
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: '/inventori/pengelola',
    name: 'Pengelolaan',
    exact: false,
    Icon: MdRadioButtonChecked,
  },
];

const navAnggaran = [
  {
    to: "/anggaran-pendapatan",
    name: "Pendapatan",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/anggaran-belanja",
    name: "Belanja",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
];

// const navPreakseptasi = [
//   {
//     to: "/preakseptasi-pengajuan",
//     name: "Pengajuan",
//     exact: false,
//     Icon: MdRadioButtonChecked,
//   },
//   {
//     to: "/preakseptasi-penagihan",
//     name: "Penagihan",
//     exact: false,
//     Icon: MdRadioButtonChecked,
//   },
// ];

const navPengelolaanbeban = [
  {
    to: "/bebantagihan",
    name: "Tagihan",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/hutang",
    name: "Hutang",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
];

const navDana = [
  {
    to: "/dana-pemasukan",
    name: "Pemasukan",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/dana-pengeluaran",
    name: "Pengeluaran",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
];
const navLaporankeuangan = [
  {
    to: "/kasdanbank",
    name: "Kas & Bank",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/rekonsiliasi",
    name: "Rekonsiliasi",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/posisikeuangan",
    name: "Posisi Keuangan",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/kinerja",
    name: "Kinerja",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/netincome",
    name: "Net Income",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/ekuitas",
    name: "Ekuitas",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/likuiditas",
    name: "Likuiditas",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/ringkasanbisnis",
    name: "Ringkasan Bisnis",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
];

const navAkuntansi = [
  {
    to: "/coa",
    name: "Kode Akun",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/draftransaksi",
    name: "Draf Transaksi",
    exact: false,
    Icon: MdRadioButtonChecked,
  },

  {
    to: "/transaksi",
    name: "Transaksi",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/datajurnal",
    name: "Jurnal",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/neraca",
    name: "Neraca",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/aruskas",
    name: "Arus Kas",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/labarugi",
    name: "Laba/Rugi",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/pajak",
    name: "Pajak",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/perubahanmodal",
    name: "Perubahan Modal",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/ringkasanbisnis",
    name: "Ringkasan Bisnis",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/menejemenanggaran",
    name: "Menejemen Anggaran",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
];

const navPersonalia = [
  {
    to: "/karyawan",
    name: "Karyawan",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/tunjangan",
    name: "Tunjangan",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/pangkat",
    name: "Pangkat",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/jabatan",
    name: "Jabatan",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
];

const bem = bn.create("sidebar");

class Sidebar extends React.Component {
  state = {
    isOpenPelanggan: false,
    isOpenMitrakerja: false,
    isOpenProject: false,
    isOpenProduk: false,
    isOpenInventori: false,
    isOpenPengelolaanbeban: false,
    isOpenDana: false,
    isOpenMenegemenanggaran: false,
    isOpenLaporankeuangan: false,
    isOpenAkuntansi: false,
    isOpenPersonalia: false,
    isOpenFilemaster: false,
    isOpenDokumen: false,
    isOpenPreakseptasi: false,
    isOpenDashboard: false,
  };

  handleClick = (name) => () => {
    this.setState((prevState) => {
      const isOpen = prevState[`isOpen${name}`];
      const prev = this.state["prevOpen"];
      return {
        [prev]: false,
        [`isOpen${name}`]: !isOpen,
        ["prevOpen"]: `isOpen${name}`,
      };
    });
  };

  render() {
    return (
      <aside className={bem.b()} data-image={sidebarBgImage}>
        <div className={bem.e("background themeappscyan")} style={sidebarBackground} />
        <div className={bem.e("content")}>
          <Navbar>
            <SourceLink className="navbar-brand d-flex">
            <img src={logo} width="150" height="90" className="pr-2" alt="" />
              {/* <span className="text-white">
                <p className="text-white">
                  KEUANGAN GPL <FaWifi />
                </p>
              </span> */}
            </SourceLink>
          </Navbar>
          <Nav vertical>
            {/* {navItems.map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e("nav-item")}>
                <BSNavLink id={`navItem-${name}-${index}`} className="" tag={NavLink} to={to} activeClassName="active" exact={exact}>
                  <Icon className={bem.e("nav-item-icon")} />
                  <span className="">{name}</span>
                </BSNavLink>
              </NavItem>
            ))} */}

            <NavItem className={bem.e("nav-item")} onClick={this.handleClick("Dashboard")}>
              <BSNavLink className={bem.e("nav-item-collapse")}>
                <div className="d-flex">
                  <MdDashboard className={bem.e("nav-item-icon")} />
                  <span className=" align-self-start">Dashboard</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e("nav-item-icon")}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenDashboard ? "rotate(0deg)" : "rotate(-90deg)",
                    transitionDuration: "0.3s",
                    transitionProperty: "transform",
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenDashboard}>
              {navDashboard.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e("nav-item ml-4")}>
                  <BSNavLink id={`navItem-${name}-${index}`} className="text-capitalize" tag={NavLink} to={to} activeClassName="active" exact={exact}>
                    <Icon className={bem.e("nav-item-icon")} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>
            <NavItem className={bem.e("nav-item")} onClick={this.handleClick("Filemaster")}>
              <BSNavLink className={bem.e("nav-item-collapse")}>
                <div className="d-flex">
                  <FaThList className={bem.e("nav-item-icon")} />
                  <span className=" align-self-start">File Master</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e("nav-item-icon")}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenFilemaster ? "rotate(0deg)" : "rotate(-90deg)",
                    transitionDuration: "0.3s",
                    transitionProperty: "transform",
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenFilemaster}>
              {navFileMaster.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e("nav-item ml-4")}>
                  <BSNavLink id={`navItem-${name}-${index}`} className="text-capitalize" tag={NavLink} to={to} activeClassName="active" exact={exact}>
                    <Icon className={bem.e("nav-item-icon")} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>
            <NavItem className={bem.e("nav-item")} onClick={this.handleClick("Pelanggan")}>
              <BSNavLink className={bem.e("nav-item-collapse")}>
                <div className="d-flex">
                  <FaUsers className={bem.e("nav-item-icon")} />
                  <span className=" align-self-start">Pelanggan</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e("nav-item-icon")}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenPelanggan ? "rotate(0deg)" : "rotate(-90deg)",
                    transitionDuration: "0.3s",
                    transitionProperty: "transform",
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenPelanggan}>
              {navPelanggan.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e("nav-item ml-4")}>
                  <BSNavLink id={`navItem-${name}-${index}`} className="text-capitalize" tag={NavLink} to={to} activeClassName="active" exact={exact}>
                    <Icon className={bem.e("nav-item-icon")} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>

            <NavItem className={bem.e("nav-item")} onClick={this.handleClick("Mitrakerja")}>
              <BSNavLink className={bem.e("nav-item-collapse")}>
                <div className="d-flex">
                  <FaBuilding className={bem.e("nav-item-icon")} />
                  <span className=" align-self-start">Mitra</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e("nav-item-icon")}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenMitrakerja ? "rotate(0deg)" : "rotate(-90deg)",
                    transitionDuration: "0.3s",
                    transitionProperty: "transform",
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenMitrakerja}>
              {navMitrakerja.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e("nav-item ml-4")}>
                  <BSNavLink id={`navItem-${name}-${index}`} className="text-capitalize" tag={NavLink} to={to} activeClassName="active" exact={exact}>
                    <Icon className={bem.e("nav-item-icon")} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>

            <NavItem className={bem.e("nav-item")} onClick={this.handleClick("Produk")}>
              <BSNavLink className={bem.e("nav-item-collapse")}>
                <div className="d-flex">
                  <FaListUl className={bem.e("nav-item-icon")} />
                  <span className=" align-self-start">Produk</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e("nav-item-icon")}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenProduk ? "rotate(0deg)" : "rotate(-90deg)",
                    transitionDuration: "0.3s",
                    transitionProperty: "transform",
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenProduk}>
              {navProduk.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e("nav-item ml-4")}>
                  <BSNavLink id={`navItem-${name}-${index}`} className="" tag={NavLink} to={to} activeClassName="active" exact={exact}>
                    <Icon className={bem.e("nav-item-icon")} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>

            <NavItem className={bem.e("nav-item")} onClick={this.handleClick("Project")}>
              <BSNavLink className={bem.e("nav-item-collapse")}>
                <div className="d-flex">
                  <FaPencilRuler className={bem.e("nav-item-icon")} />
                  <span className=" align-self-start">Project</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e("nav-item-icon")}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenProject ? "rotate(0deg)" : "rotate(-90deg)",
                    transitionDuration: "0.3s",
                    transitionProperty: "transform",
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenProject}>
              {navProject.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e("nav-item ml-4")}>
                  <BSNavLink id={`navItem-${name}-${index}`} className="text-capitalize" tag={NavLink} to={to} activeClassName="active" exact={exact}>
                    <Icon className={bem.e("nav-item-icon")} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>

            <NavItem className={bem.e("nav-item")} onClick={this.handleClick("Inventori")}>
              <BSNavLink className={bem.e("nav-item-collapse")}>
                <div className="d-flex">
                  <FaListUl className={bem.e("nav-item-icon")} />
                  <span className=" align-self-start">Inventori</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e("nav-item-icon")}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenInventori ? "rotate(0deg)" : "rotate(-90deg)",
                    transitionDuration: "0.3s",
                    transitionProperty: "transform",
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenInventori}>
              {navInventori.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e("nav-item ml-4")}>
                  <BSNavLink id={`navItem-${name}-${index}`} className="" tag={NavLink} to={to} activeClassName="active" exact={exact}>
                    <Icon className={bem.e("nav-item-icon")} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>

            {isAuthenticated() !== null ? (
              isAuthenticated().level == "Keuangan" || isAuthenticated().level == "Admin" || isAuthenticated().level == "Direktur" ? (
                <>
                  {/* <NavItem
                    className={bem.e("nav-item")}
                    onClick={this.handleClick("Dokumen")}
                  >
                    <BSNavLink className={bem.e("nav-item-collapse")}>
                      <div className="d-flex">
                        <FaListUl className={bem.e("nav-item-icon")} />
                        <span className=" align-self-start">Dokumen</span>
                      </div>
                      <MdKeyboardArrowDown
                        className={bem.e("nav-item-icon")}
                        style={{
                          padding: 0,
                          transform: this.state.isOpenDokumen
                            ? "rotate(0deg)"
                            : "rotate(-90deg)",
                          transitionDuration: "0.3s",
                          transitionProperty: "transform",
                        }}
                      />
                    </BSNavLink>
                  </NavItem>

                  <Collapse isOpen={this.state.isOpenDokumen}>
                    {navDokumen.map(({ to, name, exact, Icon }, index) => (
                      <NavItem key={index} className={bem.e("nav-item ml-4")}>
                        <BSNavLink
                          id={`navItem-${name}-${index}`}
                          className=""
                          tag={NavLink}
                          to={to}
                          activeClassName="active"
                          exact={exact}
                        >
                          <Icon className={bem.e("nav-item-icon")} />
                          <span className="">{name}</span>
                        </BSNavLink>
                      </NavItem>
                    ))}
                  </Collapse> */}

                  <NavItem className={bem.e("nav-item")} onClick={this.handleClick("Anggaran")}>
                    <BSNavLink className={bem.e("nav-item-collapse")}>
                      <div className="d-flex">
                        <FaListUl className={bem.e("nav-item-icon")} />
                        <span className=" align-self-start">Anggaran</span>
                      </div>
                      <MdKeyboardArrowDown
                        className={bem.e("nav-item-icon")}
                        style={{
                          padding: 0,
                          transform: this.state.isOpenAnggaran ? "rotate(0deg)" : "rotate(-90deg)",
                          transitionDuration: "0.3s",
                          transitionProperty: "transform",
                        }}
                      />
                    </BSNavLink>
                  </NavItem>

                  <Collapse isOpen={this.state.isOpenAnggaran}>
                    {navAnggaran.map(({ to, name, exact, Icon }, index) => (
                      <NavItem key={index} className={bem.e("nav-item ml-4")}>
                        <BSNavLink id={`navItem-${name}-${index}`} className="" tag={NavLink} to={to} activeClassName="active" exact={exact}>
                          <Icon className={bem.e("nav-item-icon")} />
                          <span className="">{name}</span>
                        </BSNavLink>
                      </NavItem>
                    ))}
                  </Collapse>

                  <NavItem className={bem.e("nav-item")} onClick={this.handleClick("Artakelola")}>
                    <BSNavLink className={bem.e("nav-item-collapse")}>
                      <div className="d-flex">
                        <FaListUl className={bem.e("nav-item-icon")} />
                        <span className=" align-self-start">Artakelola</span>
                      </div>
                      <MdKeyboardArrowDown
                        className={bem.e("nav-item-icon")}
                        style={{
                          padding: 0,
                          transform: this.state.isOpenArtakelola ? "rotate(0deg)" : "rotate(-90deg)",
                          transitionDuration: "0.3s",
                          transitionProperty: "transform",
                        }}
                      />
                    </BSNavLink>
                  </NavItem>

                  <Collapse isOpen={this.state.isOpenArtakelola}>
                    {navArtakelola.map(({ to, name, exact, Icon }, index) => (
                      <NavItem key={index} className={bem.e("nav-item ml-4")}>
                        <BSNavLink id={`navItem-${name}-${index}`} className="" tag={NavLink} to={to} activeClassName="active" exact={exact}>
                          <Icon className={bem.e("nav-item-icon")} />
                          <span className="">{name}</span>
                        </BSNavLink>
                      </NavItem>
                    ))}
                  </Collapse>

                  {/* <NavItem className={bem.e("nav-item")} onClick={this.handleClick("Preakseptasi")}>
                    <BSNavLink className={bem.e("nav-item-collapse")}>
                      <div className="d-flex">
                        <FaListUl className={bem.e("nav-item-icon")} />
                        <span className=" align-self-start">Preakseptasi</span>
                      </div>
                      <MdKeyboardArrowDown
                        className={bem.e("nav-item-icon")}
                        style={{
                          padding: 0,
                          transform: this.state.isOpenPreakseptasi ? "rotate(0deg)" : "rotate(-90deg)",
                          transitionDuration: "0.3s",
                          transitionProperty: "transform",
                        }}
                      />
                    </BSNavLink>
                  </NavItem>

                  <Collapse isOpen={this.state.isOpenPreakseptasi}>
                    {navPreakseptasi.map(({ to, name, exact, Icon }, index) => (
                      <NavItem key={index} className={bem.e("nav-item ml-4")}>
                        <BSNavLink id={`navItem-${name}-${index}`} className="" tag={NavLink} to={to} activeClassName="active" exact={exact}>
                          <Icon className={bem.e("nav-item-icon")} />
                          <span className="">{name}</span>
                        </BSNavLink>
                      </NavItem>
                    ))}
                  </Collapse> */}

                  {/* <NavItem className={bem.e("nav-item")} onClick={this.handleClick("Pengelolaanbeban")}>
                    <BSNavLink className={bem.e("nav-item-collapse")}>
                      <div className="d-flex">
                        <FaListUl className={bem.e("nav-item-icon")} />
                        <span className=" align-self-start">Beban</span>
                      </div>
                      <MdKeyboardArrowDown
                        className={bem.e("nav-item-icon")}
                        style={{
                          padding: 0,
                          transform: this.state.isOpenPengelolaanbeban ? "rotate(0deg)" : "rotate(-90deg)",
                          transitionDuration: "0.3s",
                          transitionProperty: "transform",
                        }}
                      />
                    </BSNavLink>
                  </NavItem>
                  <Collapse isOpen={this.state.isOpenPengelolaanbeban}>
                    {navPengelolaanbeban.map(({ to, name, exact, Icon }, index) => (
                      <NavItem key={index} className={bem.e("nav-item ml-4")}>
                        <BSNavLink id={`navItem-${name}-${index}`} className="" tag={NavLink} to={to} activeClassName="active" exact={exact}>
                          <Icon className={bem.e("nav-item-icon")} />
                          <span className="">{name}</span>
                        </BSNavLink>
                      </NavItem>
                    ))}
                  </Collapse>

                  <NavItem className={bem.e("nav-item")} onClick={this.handleClick("Dana")}>
                    <BSNavLink className={bem.e("nav-item-collapse")}>
                      <div className="d-flex">
                        <FaMoneyCheck className={bem.e("nav-item-icon")} />
                        <span className=" align-self-start">Dana</span>
                      </div>
                      <MdKeyboardArrowDown
                        className={bem.e("nav-item-icon")}
                        style={{
                          padding: 0,
                          transform: this.state.isOpenDana ? "rotate(0deg)" : "rotate(-90deg)",
                          transitionDuration: "0.3s",
                          transitionProperty: "transform",
                        }}
                      />
                    </BSNavLink>
                  </NavItem>
                  <Collapse isOpen={this.state.isOpenDana}>
                    {navDana.map(({ to, name, exact, Icon }, index) => (
                      <NavItem key={index} className={bem.e("nav-item ml-4")}>
                        <BSNavLink id={`navItem-${name}-${index}`} className="" tag={NavLink} to={to} activeClassName="active" exact={exact}>
                          <Icon className={bem.e("nav-item-icon")} />
                          <span className="">{name}</span>
                        </BSNavLink>
                      </NavItem>
                    ))}
                  </Collapse> */}

                  {/* <NavItem
                  className={bem.e("nav-item")}
                  onClick={this.handleClick("Menegemenanggaran")}
                >
                  <BSNavLink className={bem.e("nav-item-collapse")}>
                    <div className="d-flex">
                      <FaListUl className={bem.e("nav-item-icon")} />
                      <span className=" align-self-start">Anggaran </span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e("nav-item-icon")}
                      style={{
                        padding: 0,
                        transform: this.state.isOpenMenegemenanggaran
                          ? "rotate(0deg)"
                          : "rotate(-90deg)",
                        transitionDuration: "0.3s",
                        transitionProperty: "transform",
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={this.state.isOpenMenegemenanggaran}>
                  {navMenegemenanggaran.map(
                    ({ to, name, exact, Icon }, index) => (
                      <NavItem key={index} className={bem.e("nav-item ml-4")}>
                        <BSNavLink
                          id={`navItem-${name}-${index}`}
                          className=""
                          tag={NavLink}
                          to={to}
                          activeClassName="active"
                          exact={exact}
                        >
                          <Icon className={bem.e("nav-item-icon")} />
                          <span className="">{name}</span>
                        </BSNavLink>
                      </NavItem>
                    )
                  )}
                </Collapse> */}

                  <NavItem className={bem.e("nav-item")} onClick={this.handleClick("Laporankeuangan")}>
                    <BSNavLink className={bem.e("nav-item-collapse")}>
                      <div className="d-flex">
                        <FaCoins className={bem.e("nav-item-icon")} />
                        <span className=" align-self-start">Keuangan</span>
                      </div>
                      <MdKeyboardArrowDown
                        className={bem.e("nav-item-icon")}
                        style={{
                          padding: 0,
                          transform: this.state.isOpenLaporankeuangan ? "rotate(0deg)" : "rotate(-90deg)",
                          transitionDuration: "0.3s",
                          transitionProperty: "transform",
                        }}
                      />
                    </BSNavLink>
                  </NavItem>
                  <Collapse isOpen={this.state.isOpenLaporankeuangan}>
                    {navLaporankeuangan.map(({ to, name, exact, Icon }, index) => (
                      <NavItem key={index} className={bem.e("nav-item ml-4")}>
                        <BSNavLink id={`navItem-${name}-${index}`} className="" tag={NavLink} to={to} activeClassName="active" exact={exact}>
                          <Icon className={bem.e("nav-item-icon")} />
                          <span className="">{name}</span>
                        </BSNavLink>
                      </NavItem>
                    ))}
                  </Collapse>

                  <NavItem className={bem.e("nav-item")} onClick={this.handleClick("Akuntansi")}>
                    <BSNavLink className={bem.e("nav-item-collapse")}>
                      <div className="d-flex">
                        <FaMoneyBillWaveAlt className={bem.e("nav-item-icon")} />
                        <span className=" align-self-start">Akuntansi</span>
                      </div>
                      <MdKeyboardArrowDown
                        className={bem.e("nav-item-icon")}
                        style={{
                          padding: 0,
                          transform: this.state.isOpenAkuntansi ? "rotate(0deg)" : "rotate(-90deg)",
                          transitionDuration: "0.3s",
                          transitionProperty: "transform",
                        }}
                      />
                    </BSNavLink>
                  </NavItem>
                  <Collapse isOpen={this.state.isOpenAkuntansi}>
                    {navAkuntansi.map(({ to, name, exact, Icon }, index) => (
                      <NavItem key={index} className={bem.e("nav-item ml-4")}>
                        <BSNavLink id={`navItem-${name}-${index}`} className="" tag={NavLink} to={to} activeClassName="active" exact={exact}>
                          <Icon className={bem.e("nav-item-icon")} />
                          <span className="">{name}</span>
                        </BSNavLink>
                      </NavItem>
                    ))}
                  </Collapse>
                </>
              ) : null
            ) : null}

            <NavItem className={bem.e("nav-item")} onClick={this.handleClick("Personalia")}>
              <BSNavLink className={bem.e("nav-item-collapse")}>
                <div className="d-flex">
                  <FaUserCircle className={bem.e("nav-item-icon")} />
                  <span className="">Personalia</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e("nav-item-icon")}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenPersonalia ? "rotate(0deg)" : "rotate(-90deg)",
                    transitionDuration: "0.3s",
                    transitionProperty: "transform",
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenPersonalia}>
              {navPersonalia.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e("nav-item ml-4")}>
                  <BSNavLink id={`navItem-${name}-${index}`} className="" tag={NavLink} to={to} activeClassName="active" exact={exact}>
                    <Icon className={bem.e("nav-item-icon")} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>
          </Nav>
        </div>
      </aside>
    );
  }
}

export default Sidebar;
