import {
  GET_LOKASI_INVENTORI,
  ADD_LOKASI_INVENTORI,
  EDIT_LOKASI_INVENTORI,
  UPDATE_LOKASI_INVENTORI,
  DELETE_LOKASI_INVENTORI,
} from '../../pages/actions/lokasiinventoriAction';

const LokasiInventori = (state, action) => {
  const { type } = action;
  switch (type) {
    case GET_LOKASI_INVENTORI:
      return {
        ...state,
        getLokasiInventoriResult: action.payload.data,
        getLokasiInventoriLoading: action.payload.loading,
        getLokasiInventoriError: action.payload.errorMessage,
      };

    case ADD_LOKASI_INVENTORI:
      return {
        ...state,
        addLokasiInventoriResult: action.payload.data,
        addLokasiInventoriLoading: action.payload.loading,
        addLokasiInventoriError: action.payload.errorMessage,
      };
    case EDIT_LOKASI_INVENTORI:
      return {
        ...state,
        editLokasiInventoriResult: action.payload.data,
      };
    case UPDATE_LOKASI_INVENTORI:
      return {
        ...state,
        updateLokasiInventoriResult: action.payload.data,
        updateLokasiInventoriLoading: action.payload.loading,
        updateLokasiInventoriError: action.payload.errorMessage,
      };
    case DELETE_LOKASI_INVENTORI:
      return {
        ...state,
        deleteLokasiInventoriResult: action.payload.data,
        deleteLokasiInventoriLoading: action.payload.loading,
        deleteLokasiInventoriError: action.payload.errorMessage,
      };

    default:
      return state;
  }
};

export default LokasiInventori;
