import axios from 'axios';
import Swal from 'sweetalert2';
import {
     API_URL_datarekeningbank,
     API_URL_createrekeningbank,
     API_URL_edelrekeningbank 
} from '../constants';

export const GET_REKENING_BANK = 'GET_REKENING_BANK';
export const ADD_REKENING_BANK = 'ADD_REKENING_BANK';
export const EDIT_REKENING_BANK = 'EDIT_REKENING_BANK';
export const UPDATE_REKENING_BANK = ' UPDATE_REKENING_BANK';
export const DELETE_REKENING_BANK = ' DELETE_REKENING_BANK';

export const getRekeningbank = (dispatch, data) => {
  dispatch({
    type: GET_REKENING_BANK,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_datarekeningbank + data.param,
  })
    .then(response => {
      dispatch({
        type: GET_REKENING_BANK,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_REKENING_BANK,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const addRekeningbank = (dispatch, data) => {
  dispatch({
    type: ADD_REKENING_BANK,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_createrekeningbank,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: ADD_REKENING_BANK,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_REKENING_BANK,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const editRekeningbank = (dispatch, data) => {
  dispatch({
    type: EDIT_REKENING_BANK,
    payload: {
      data: data,
    },
  });
};

export const updateRekeningbank = (dispatch, data) => {
  dispatch({
    type: UPDATE_REKENING_BANK,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'PUT',
    url: API_URL_edelrekeningbank + data.pk,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
         Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: UPDATE_REKENING_BANK,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: UPDATE_REKENING_BANK,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const deleteRekeningbank = (dispatch, pk) => {
    dispatch({
      type: DELETE_REKENING_BANK,
      payload: {
        loading: true,
        data: false,
        errorMessage: false,
      },
    });
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#6a82fb',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(result => {
      if (result.isConfirmed) {
        axios({
          method: 'DELETE',
          url: API_URL_edelrekeningbank + pk,
          timeout: 120000,
        })
          .then(response => {
            Swal.fire('Deleted!', response.data.messages, 'success');
            dispatch({
              type: DELETE_REKENING_BANK,
              payload: {
                loading: false,
                data: response.data,
                errorMessage: false,
              },
            });
          })
          .catch(error => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error,
            });
            dispatch({
              type: DELETE_REKENING_BANK,
              payload: {
                loading: false,
                data: false,
                errorMessage: error.message,
              },
            });
          });
      }
    });
  };
  