import axios from "axios";
import Swal from "sweetalert2";
import { API_URL_createcoapelanggan, API_URL_datapelanggan, API_URL_deletecoapelanggan, API_URL_getcoapelanggan, API_URL_pilihdkpelanggan } from "../constants";
import { API_URL_createpelanggan } from "../constants";
import { API_URL_datapelanggandetail } from "../constants";
import { API_URL_pelanggandetail } from "../constants";
import { API_URL_createdetailpelanggan } from "../constants";
import { API_URL_edeldetailpelanggan } from "../constants";
import { API_URL_getdppelanggan } from "../constants";
import { API_URL_createdppelanggan } from "../constants";
import { API_URL_edeldppelanggan } from "../constants";

export const GET_PELANGGAN = "GET_PELANGGAN";
export const ADD_PELANGGAN = "ADD_PELANGGAN";
export const EDIT_PELANGGAN = "EDIT_PELANGGAN";
export const UPDATE_PELANGGAN = "UPDATE_PELANGGAN";
export const DELETE_PELANGGAN = "DELETE_PELANGGAN";
export const JENIS_PELANGGAN_SELECTED = "JENIS_PELANGGAN_SELECTED";
export const REGPELANGGAN_SELECTED = "REGPELANGGAN_SELECTED";
export const DATAPEMBAYARAN_SELECTED = " DATAPEMBAYARAN_SELECTED";
export const DETAIL_PELANGGAN = "DETAIL_PELANGGAN";
export const ADD_DETAIL_PELANGGAN = "ADD_DETAIL_PELANGGAN";
export const DELETE_DETAIL_PELANGGAN = "DELETE_DETAIL_PELANGGAN";
export const UPDATE_DETAIL_PELANGGAN = "UPDATE_DETAIL_PELANGGAN";
export const GET_COAPELANGGAN = "GET_COAPELANGGAN";
export const ADD_COAPELANGGAN = "ADD_COAPELANGGAN";
export const DELETE_COAPELANGGAN = "DELETE_COAPELANGGAN";
export const GET_DP_PELANGGAN = "GET_DP_PELANGGAN";
export const ADD_DP_PELANGGAN = "ADD_DP_PELANGGAN";
export const DELETE_DP_PELANGGAN = "DELETE_DP_PELANGGAN";

export const uploadDataPelanggan = (dispatch, data) => {
  dispatch({
    type: UPDATE_PELANGGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'PUT',
    url: API_URL_datapelanggandetail + data.pk,
    timeout: 120000,
    data: data.formData,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: UPDATE_PELANGGAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: UPDATE_PELANGGAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const addDKPelanggan = (dispatch, data) => {
  dispatch({
    type: ADD_COAPELANGGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: "PUT",
    url: API_URL_pilihdkpelanggan + data.pk,
    timeout: 120000,
    data: data,
  })
    .then((response) => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.messages,
        });
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: response.data.messages,
        });
        dispatch({
          type: ADD_COAPELANGGAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error,
      });
      dispatch({
        type: ADD_COAPELANGGAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const deleteCOAPelanggan = (dispatch, pk) => {
  dispatch({
    type: DELETE_COAPELANGGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#6a82fb",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      axios({
        method: "DELETE",
        url: API_URL_deletecoapelanggan + pk,
        timeout: 120000,
      })
        .then((response) => {
          Swal.fire("Deleted!", response.data.messages, "success");
          dispatch({
            type: DELETE_COAPELANGGAN,
            payload: {
              loading: false,
              data: response.data,
              errorMessage: false,
            },
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error,
          });
          dispatch({
            type: DELETE_COAPELANGGAN,
            payload: {
              loading: false,
              data: false,
              errorMessage: error.message,
            },
          });
        });
    }
  });
};

export const addCOAPelanggan = (dispatch, data) => {
  dispatch({
    type: ADD_COAPELANGGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: "POST",
    url: API_URL_createcoapelanggan,
    timeout: 120000,
    data: data,
  })
    .then((response) => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.messages,
        });
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: response.data.messages,
        });
        dispatch({
          type: ADD_COAPELANGGAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error,
      });
      dispatch({
        type: ADD_COAPELANGGAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const getCOAPelanggan = (dispatch, data) => {
  dispatch({
    type: GET_COAPELANGGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: "GET",
    url: API_URL_getcoapelanggan + data.pk,
  })
    .then((response) => {
      dispatch({
        type: GET_COAPELANGGAN,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_COAPELANGGAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const updateDetailpelanggan = (dispatch, data, formData) => {
  dispatch({
    type: UPDATE_DETAIL_PELANGGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: "PUT",
    url: API_URL_edeldetailpelanggan + data.pk,
    timeout: 120000,
    data: formData,
  })
    .then((response) => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Good job!",
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: UPDATE_DETAIL_PELANGGAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error,
      });
      dispatch({
        type: UPDATE_DETAIL_PELANGGAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const addDetailpelanggan = (dispatch, data) => {
  dispatch({
    type: ADD_DETAIL_PELANGGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: "POST",
    url: API_URL_createdetailpelanggan,
    timeout: 120000,
    data: data,
  })
    .then((response) => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Good job!",
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: ADD_DETAIL_PELANGGAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error,
      });
      dispatch({
        type: ADD_DETAIL_PELANGGAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const jenisPelangganSelected = (dispatch, data) => {
  dispatch({
    type: JENIS_PELANGGAN_SELECTED,
    payload: {
      data: data,
    },
  });
};

export const RegpelangganSelected = (dispatch, data) => {
  dispatch({
    type: REGPELANGGAN_SELECTED,
    payload: {
      data: data,
    },
  });
};

export const datapembayaranSelected = (dispatch, data) => {
  dispatch({
    type: DATAPEMBAYARAN_SELECTED,
    payload: {
      data: data,
    },
  });
};

export const getDetailPelanggan = (dispatch, data) => {
  dispatch({
    type: DETAIL_PELANGGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: "GET",
    url: API_URL_pelanggandetail + data.idpelanggan,
  })
    .then((response) => {
      dispatch({
        type: DETAIL_PELANGGAN,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: DETAIL_PELANGGAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const getPelanggan = (dispatch, data) => {
  dispatch({
    type: GET_PELANGGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url:
      API_URL_datapelanggan +
      data.jenispelanggan +
      '-' +
      data.tipepelanggan + '-' +
      data.status +
      data.param,
  })
    .then((response) => {
      dispatch({
        type: GET_PELANGGAN,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_PELANGGAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const addPelanggan = (dispatch, data) => {
  dispatch({
    type: ADD_PELANGGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: "POST",
    url: API_URL_createpelanggan,
    timeout: 120000,
    data: data,
  })
    .then((response) => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Good job!",
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: ADD_PELANGGAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error,
      });
      dispatch({
        type: ADD_PELANGGAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const editPelanggan = (dispatch, data) => {
  dispatch({
    type: EDIT_PELANGGAN,
    payload: {
      data: data,
    },
  });
};

export const updatePelanggan = (dispatch, data) => {
  dispatch({
    type: UPDATE_PELANGGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: "PUT",
    url: API_URL_datapelanggandetail + data.idpelanggan,
    timeout: 120000,
    data: data,
  })
    .then((response) => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Good job!",
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: UPDATE_PELANGGAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error,
      });
      dispatch({
        type: UPDATE_PELANGGAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const deleteDetailpelanggan = (dispatch, data) => {
  dispatch({
    type: DELETE_DETAIL_PELANGGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#6a82fb",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      axios({
        method: "DELETE",
        url: API_URL_edeldetailpelanggan + data,
        timeout: 120000,
      })
        .then((response) => {
          Swal.fire("Deleted!", response.data.messages, "success");
          dispatch({
            type: DELETE_DETAIL_PELANGGAN,
            payload: {
              loading: false,
              data: response.data,
              errorMessage: false,
            },
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error,
          });
          dispatch({
            type: DELETE_DETAIL_PELANGGAN,
            payload: {
              loading: false,
              data: false,
              errorMessage: error.message,
            },
          });
        });
    }
  });
};

export const deletePelanggan = (dispatch, data) => {
  dispatch({
    type: DELETE_PELANGGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#6a82fb",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      axios({
        method: "DELETE",
        url: API_URL_datapelanggandetail + data,
        timeout: 120000,
      })
        .then((response) => {
          Swal.fire("Deleted!", response.data.messages, "success");
          dispatch({
            type: DELETE_PELANGGAN,
            payload: {
              loading: false,
              data: response.data,
              errorMessage: false,
            },
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error,
          });
          dispatch({
            type: DELETE_PELANGGAN,
            payload: {
              loading: false,
              data: false,
              errorMessage: error.message,
            },
          });
        });
    }
  });
};
export const getDpPelanggan = (dispatch, data) => {
  dispatch({
    type: GET_DP_PELANGGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: "GET",
    url: API_URL_getdppelanggan + data.idpelanggan,
  })
    .then((response) => {
      dispatch({
        type: GET_DP_PELANGGAN,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_DP_PELANGGAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const addDppelanggan = (dispatch, data) => {
  dispatch({
    type: ADD_DP_PELANGGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: "POST",
    url: API_URL_createdppelanggan,
    timeout: 120000,
    data: data,
  })
    .then((response) => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Good job!",
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: ADD_DP_PELANGGAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error,
      });
      dispatch({
        type: ADD_DP_PELANGGAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const deleteDppelanggan = (dispatch, data) => {
  dispatch({
    type: DELETE_DP_PELANGGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#6a82fb",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      axios({
        method: "DELETE",
        url: API_URL_edeldppelanggan + data,
        timeout: 120000,
      })
        .then((response) => {
          Swal.fire("Deleted!", response.data.messages, "success");
          dispatch({
            type: DELETE_DP_PELANGGAN,
            payload: {
              loading: false,
              data: response.data,
              errorMessage: false,
            },
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error,
          });
          dispatch({
            type: DELETE_DP_PELANGGAN,
            payload: {
              loading: false,
              data: false,
              errorMessage: error.message,
            },
          });
        });
    }
  });
};
