import {
    GET_REKENING_BANK,
    ADD_REKENING_BANK,
    EDIT_REKENING_BANK,
    UPDATE_REKENING_BANK,
    DELETE_REKENING_BANK
  } from '../../pages/actions/rekeningbankAction';
  
  const Rekeningbank = (state, action) => {
    const { type } = action;
    switch (type) {
      case GET_REKENING_BANK:
        return {
          ...state,
          getRekeningbankResult: action.payload.data,
          getRekeningbankLoading: action.payload.loading,
          getRekeningbankError: action.payload.errorMessage,
        };
  
      case ADD_REKENING_BANK:
        return {
          ...state,
          addRekeningbankResult: action.payload.data,
          addRekeningbankLoading: action.payload.loading,
          addRekeningbankError: action.payload.errorMessage,
        };
      case EDIT_REKENING_BANK:
        return {
          ...state,
          editRekeningbankResult: action.payload.data,
        };
      case UPDATE_REKENING_BANK:
        return {
          ...state,
          updateRekeningbankResult: action.payload.data,
          updateRekeningbankLoading: action.payload.loading,
          updateRekeningbankError: action.payload.errorMessage,
        };
      case DELETE_REKENING_BANK:
        return {
          ...state,
          deleteRekeningbankResult: action.payload.data,
          deleteRekeningbankLoading: action.payload.loading,
          deleteRekeningbankError: action.payload.errorMessage,
        };
  
      default:
        return state;
    }
  };
  
  export default Rekeningbank;
  