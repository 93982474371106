import {
  ADD_JURNAL,
  DELETE_JURNAL,
  GET_TRANSAKSI,
  ADD_TRANSAKSI,
  EDIT_TRANSAKSI,
  UPDATE_TRANSAKSI,
  DELETE_TRANSAKSI,
  GET_DETAIL_TRANSAKSI,
  GET_DATA_JURNAL,
} from '../../pages/actions/transaksiAction';

const transaksi = (state, action) => {
  const { type } = action;
  switch (type) {
    case DELETE_JURNAL:
      return {
        ...state,
        deleteJurnalResult: action.payload.data,
        deleteJurnalLoading: action.payload.loading,
        deleteJurnalError: action.payload.errorMessage,
      };
    case GET_DATA_JURNAL:
      return {
        ...state,
        getJurnalResult: action.payload.data,
        getJurnalLoading: action.payload.loading,
        getJurnalError: action.payload.errorMessage,
      };
    case ADD_JURNAL:
      return {
        ...state,
        addJurnalResult: action.payload.data,
        addJurnalLoading: action.payload.loading,
        addJurnalError: action.payload.errorMessage,
      };
    case GET_TRANSAKSI:
      return {
        ...state,
        getTransaksiResult: action.payload.data,
        getTransaksiLoading: action.payload.loading,
        getTransaksiError: action.payload.errorMessage,
      };
    case EDIT_TRANSAKSI:
      return {
        ...state,
        editTransaksiResult: action.payload.data,
      };
    case ADD_TRANSAKSI:
      return {
        ...state,
        addTransaksiResult: action.payload.data,
        addTransaksiLoading: action.payload.loading,
        addTransaksiError: action.payload.errorMessage,
      };
    case UPDATE_TRANSAKSI:
      return {
        ...state,
        updateTransaksiResult: action.payload.data,
        updateTransaksiLoading: action.payload.loading,
        updateTransaksiError: action.payload.errorMessage,
      };
    case DELETE_TRANSAKSI:
      return {
        ...state,
        deleteTransaksiResult: action.payload.data,
        deleteTransaksiLoading: action.payload.loading,
        deleteTransaksiError: action.payload.errorMessage,
      };
    case GET_DETAIL_TRANSAKSI:
      return {
        ...state,
        getDetailTransaksiResult: action.payload.data,
        getDetailTransaksiLoading: action.payload.loading,
        getDetailTransaksiError: action.payload.errorMessage,
      };
    default:
      return state;
  }
};

export default transaksi;
