import {
  GET_PELANGGAN,
  EDIT_PELANGGAN,
  UPDATE_PELANGGAN,
  ADD_PELANGGAN,
  DELETE_PELANGGAN,
  JENIS_PELANGGAN_SELECTED,
  DETAIL_PELANGGAN,
  ADD_DETAIL_PELANGGAN,
  DELETE_DETAIL_PELANGGAN,
  UPDATE_DETAIL_PELANGGAN,
  REGPELANGGAN_SELECTED,
  DATAPEMBAYARAN_SELECTED,
  ADD_COAPELANGGAN,
  GET_COAPELANGGAN,
  DELETE_COAPELANGGAN,
  GET_DP_PELANGGAN,
  ADD_DP_PELANGGAN,
  DELETE_DP_PELANGGAN,
} from "../../pages/actions/pelangganAction";

const pelanggan = (state, action) => {
  const { type } = action;
  switch (type) {
    case DELETE_COAPELANGGAN:
      return {
        ...state,
        deleteCOAPelangganResult: action.payload.data,
        deleteCOAPelangganLoading: action.payload.loading,
        deleteCOAPelangganError: action.payload.errorMessage,
      };
    case GET_COAPELANGGAN:
      return {
        ...state,
        getCOAPelangganResult: action.payload.data,
        getCOAPelangganLoading: action.payload.loading,
        getCOAPelangganError: action.payload.errorMessage,
      };
    case ADD_COAPELANGGAN:
      return {
        ...state,
        addCOAPelangganResult: action.payload.data,
        addCOAPelangganLoading: action.payload.loading,
        addCOAPelangganError: action.payload.errorMessage,
      };
    case GET_PELANGGAN:
      return {
        ...state,
        getpelangganResult: action.payload.data,
        getpelangganLoading: action.payload.loading,
        getpelangganError: action.payload.errorMessage,
      };
    case ADD_PELANGGAN:
      return {
        ...state,
        addpelangganResult: action.payload.data,
        addpelangganLoading: action.payload.loading,
        addpelangganError: action.payload.errorMessage,
      };

    case ADD_DETAIL_PELANGGAN:
      return {
        ...state,
        adddetailpelangganResult: action.payload.data,
        adddetailpelangganLoading: action.payload.loading,
        adddetailpelangganError: action.payload.errorMessage,
      };
    case EDIT_PELANGGAN:
      return {
        ...state,
        editpelangganResult: action.payload.data,
      };
    case DETAIL_PELANGGAN:
      return {
        ...state,
        getdetailpelangganResult: action.payload.data,
        getdetailpelangganLoading: action.payload.loading,
        getdetailpelangganError: action.payload.errorMessage,
      };
    case JENIS_PELANGGAN_SELECTED:
      return {
        ...state,
        jenispelangganselectedResult: action.payload.data,
      };

    case REGPELANGGAN_SELECTED:
      return {
        ...state,
        regpelangganselectedResult: action.payload.data,
      };

    case DATAPEMBAYARAN_SELECTED:
      return {
        ...state,
        datapembayaranselectedResult: action.payload.data,
      };

    case UPDATE_DETAIL_PELANGGAN:
      return {
        ...state,
        updatedetailpelangganResult: action.payload.data,
        updatedetailpelangganLoading: action.payload.loading,
        updatedetailpelangganError: action.payload.errorMessage,
      };
    case UPDATE_PELANGGAN:
      return {
        ...state,
        updatepelangganResult: action.payload.data,
        updatepelangganLoading: action.payload.loading,
        updatepelangganError: action.payload.errorMessage,
      };
    case DELETE_PELANGGAN:
      return {
        ...state,
        deletepelangganResult: action.payload.data,
        deletepelangganLoading: action.payload.loading,
        deletepelangganError: action.payload.errorMessage,
      };
    case DELETE_DETAIL_PELANGGAN:
      return {
        ...state,
        deletedetailpelangganResult: action.payload.data,
        deletedetailpelangganLoading: action.payload.loading,
        deletedetailpelangganError: action.payload.errorMessage,
      };
    case GET_DP_PELANGGAN:
      return {
        ...state,
        getdppelangganResult: action.payload.data,
        getdppelangganLoading: action.payload.loading,
        getdppelangganError: action.payload.errorMessage,
      };
    case ADD_DP_PELANGGAN:
      return {
        ...state,
        adddppelangganResult: action.payload.data,
        adddppelangganLoading: action.payload.loading,
        adddppelangganError: action.payload.errorMessage,
      };
    case DELETE_DP_PELANGGAN:
      return {
        ...state,
        deletedppelangganResult: action.payload.data,
        deletedppelangganLoading: action.payload.loading,
        deletedppelangganError: action.payload.errorMessage,
      };
    default:
      return state;
  }
};

export default pelanggan;
