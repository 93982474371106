import {
  GET_STOK_BARANG,
  ADD_BARANG,
  EDIT_STOK_BARANG,
  UPDATE_STOK_BARANG,
  DELETE_STOK_BARANG,
  GET_DETAIL_STOK,
  ADD_EXPAND,
  GET_DETAIL_STOKPOSISI,
} from '../../pages/actions/stokbarangAction';

const stokbarang = (state, action) => {
  const { type } = action;
  switch (type) {
    case GET_DETAIL_STOKPOSISI:
      return {
        ...state,
        getDetailStokPosisiResult: action.payload.data,
        getDetailStokPosisiLoading: action.payload.loading,
        getDetailStokPosisiError: action.payload.errorMessage,
      };
    case ADD_EXPAND:
      return {
        ...state,
        addExpandResult: action.payload.data,
        addExpandLoading: action.payload.loading,
        addExpandError: action.payload.errorMessage,
      };
    case GET_DETAIL_STOK:
      return {
        ...state,
        getDetailStokBarangResult: action.payload.data,
        getDetailStokBarangLoading: action.payload.loading,
        getDetailStokBarangError: action.payload.errorMessage,
      };
    case GET_STOK_BARANG:
      return {
        ...state,
        getstokbarangResult: action.payload.data,
        getstokbarangLoading: action.payload.loading,
        getstokbarangError: action.payload.errorMessage,
      };
    case ADD_BARANG:
      return {
        ...state,
        addbarangResult: action.payload.data,
        addbarangLoading: action.payload.loading,
        addbarangError: action.payload.errorMessage,
      };

    case EDIT_STOK_BARANG:
      return {
        ...state,
        editstokbarangResult: action.payload.data,
      };
    case DELETE_STOK_BARANG:
      return {
        ...state,
        deletestokbarangResult: action.payload.data,
        deletestokbarangLoading: action.payload.loading,
        deletestokbarangError: action.payload.errorMessage,
      };
    case UPDATE_STOK_BARANG:
      return {
        ...state,
        updatestokbarangResult: action.payload.data,
        updatestokbarangLoading: action.payload.loading,
        updatestokbarangError: action.payload.errorMessage,
      };
    default:
      return state;
  }
};

export default stokbarang;
