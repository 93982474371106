import {
  GET_PAKET_INTERNET,
  ADD_PAKET_INTERNET,
  EDIT_PAKET_INTERNET,
  UPDATE_PAKET_INTERNET,
} from '../../pages/actions/paketinternetAction';

const paketinternet = (state, action) => {
  const { type } = action;
  switch (type) {
    case GET_PAKET_INTERNET:
      return {
        ...state,
        getpaketinternetResult: action.payload.data,
        getpaketinternetLoading: action.payload.loading,
        getpaketinternetError: action.payload.errorMessage,
      };

    case ADD_PAKET_INTERNET:
      return {
        ...state,
        addpaketinternetResult: action.payload.data,
        addpaketinternetLoading: action.payload.loading,
        addpaketinternetError: action.payload.errorMessage,
      };
    case EDIT_PAKET_INTERNET:
      return {
        ...state,
        editpaketinternetResult: action.payload.data,
      };
    case UPDATE_PAKET_INTERNET:
      return {
        ...state,
        updatepaketinternetResult: action.payload.data,
        updatepaketinternetLoading: action.payload.loading,
        updatepaketinternetError: action.payload.errorMessage,
      };
    // case DELETE_OLT:
    //   return {
    //     ...state,
    //     deleteOltResult: action.payload.data,
    //     deleteOltLoading: action.payload.loading,
    //     deleteOltError: action.payload.errorMessage,
    //   };
    default:
      return state;
  }
};

export default paketinternet;
