import axios from 'axios';
// import Swal from 'sweetalert2';
import { API_URL_getusers } from '../constants';

export const GET_USER = 'GET_USER';
// export const ADD_FTTX = 'ADD_FTTX';
// export const DELETE_FTTX = 'DELETE_FTTX';

export const getUser = dispatch => {
  dispatch({
    type: GET_USER,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_getusers,
  })
    .then(response => {
      dispatch({
        type: GET_USER,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_USER,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

// export const addFttx = (dispatch, data) => {
//   dispatch({
//     type: ADD_FTTX,
//     payload: {
//       loading: true,
//       data: false,
//       errorMessage: false,
//     },
//   });

//   axios({
//     method: 'POST',
//     url: 'http://localhost:8000/api/createwilayahfttx/',
//     timeout: 120000,
//     data: data,
//   })
//     .then(response => {
//       if (response.data.status == 201) {
//         Swal.fire({
//           icon: 'error',
//           title: 'Oops...',
//           text: response.data.messages,
//         });
//       } else {
//         Swal.fire('Good job!', response.data.messages, 'success');
//         dispatch({
//           type: ADD_FTTX,
//           payload: {
//             loading: false,
//             data: response.data,
//             errorMessage: false,
//           },
//         });
//       }
//     })
//     .catch(error => {
//       Swal.fire({
//         icon: 'error',
//         title: 'Oops...',
//         text: error,
//       });
//       dispatch({
//         type: ADD_FTTX,
//         payload: {
//           loading: false,
//           data: false,
//           errorMessage: error.message,
//         },
//       });
//     });
// };

// export const deleteFttx = (dispatch, pk) => {
//   dispatch({
//     type: DELETE_FTTX,
//     payload: {
//       loading: true,
//       data: false,
//       errorMessage: false,
//     },
//   });
//   Swal.fire({
//     title: 'Are you sure?',
//     text: "You won't be able to revert this!",
//     icon: 'warning',
//     showCancelButton: true,
//     confirmButtonColor: '#6a82fb',
//     cancelButtonColor: '#d33',
//     confirmButtonText: 'Yes, delete it!',
//   }).then(result => {
//     if (result.isConfirmed) {
//       axios({
//         method: 'DELETE',
//         url: 'http://localhost:8000/api/edelnodefttx/' + pk,
//         timeout: 120000,
//       })
//         .then(response => {
//           Swal.fire('Deleted!', response.data.messages, 'success');
//           dispatch({
//             type: DELETE_FTTX,
//             payload: {
//               loading: false,
//               data: response.data,
//               errorMessage: false,
//             },
//           });
//         })
//         .catch(error => {
//           Swal.fire({
//             icon: 'error',
//             title: 'Oops...',
//             text: error,
//           });
//           dispatch({
//             type: DELETE_FTTX,
//             payload: {
//               loading: false,
//               data: false,
//               errorMessage: error.message,
//             },
//           });
//         });
//     }
//   });
// };
