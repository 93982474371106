import {
  GET_TAGIHAN_PELANGGAN,
  CREATE_TAGIHAN_PELANGGAN,
  EDIT_TAGIHAN_PELANGGAN,
  UPDATE_TAGIHAN_PELANGGAN,
  DELETE_TAGIHAN_PELANGGAN,
  BAYAR_TAGIHAN_PELANGGAN,
  GETBAYARTAGPEL_TAGIHAN_PELANGGAN,
  SETTING_AKUN,
  GET_PIUTANG_PELANGGAN,
  ADD_TAGIHAN,
} from "../../pages/actions/tagihanpelangganAction";

const tagihanpelanggan = (state, action) => {
  const { type } = action;
  switch (type) {
    case SETTING_AKUN:
      return {
        ...state,
        settingakunResult: action.payload.data,
        settingakunLoading: action.payload.loading,
        settingakunError: action.payload.errorMessage,
      };
    case GET_TAGIHAN_PELANGGAN:
      return {
        ...state,
        getTagihanpelangganResult: action.payload.data,
        getTagihanpelangganLoading: action.payload.loading,
        getTagihanpelangganError: action.payload.errorMessage,
      };
    case GET_PIUTANG_PELANGGAN:
      return {
        ...state,
        getPiutangpelangganResult: action.payload.data,
        getPiutangpelangganLoading: action.payload.loading,
        getPiutangpelangganError: action.payload.errorMessage,
      };
    case CREATE_TAGIHAN_PELANGGAN:
      return {
        ...state,
        createTagihanpelangganResult: action.payload.data,
        createTagihanpelangganLoading: action.payload.loading,
        createTagihanpelangganError: action.payload.errorMessage,
      };
    case ADD_TAGIHAN:
      return {
        ...state,
        addtagihanResult: action.payload.data,
        addtagihanLoading: action.payload.loading,
        addtagihanError: action.payload.errorMessage,
      };
    case EDIT_TAGIHAN_PELANGGAN:
      return {
        ...state,
        editTagihanpelangganResult: action.payload.data,
      };
    case UPDATE_TAGIHAN_PELANGGAN:
      return {
        ...state,
        updateTagihanpelangganResult: action.payload.data,
        updateTagihanpelangganLoading: action.payload.loading,
        updateTagihanpelangganError: action.payload.errorMessage,
      };

    case DELETE_TAGIHAN_PELANGGAN:
      return {
        ...state,
        deleteTagihanpelangganResult: action.payload.data,
        deleteTagihanpelangganLoading: action.payload.loading,
        deleteTagihanpelangganError: action.payload.errorMessage,
      };
    case GETBAYARTAGPEL_TAGIHAN_PELANGGAN:
      return {
        ...state,
        GetSudahBayarTagihanpelangganResult: action.payload.data,
        GetSudahBayarTagihanpelangganLoading: action.payload.loading,
        GetSudahBayarTagihanpelangganError: action.payload.errorMessage,
      };
    case BAYAR_TAGIHAN_PELANGGAN:
      return {
        ...state,
        BayarTagihanpelangganResult: action.payload.data,
        BayarTagihanpelangganLoading: action.payload.loading,
        BayarTagihanpelangganError: action.payload.errorMessage,
      };
    default:
      return state;
  }
};

export default tagihanpelanggan;
