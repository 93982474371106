import {
  GET_SATUAN_PRODUK,
  ADD_SATUAN_PRODUK,
  EDIT_SATUAN_PRODUK,
  UPDATE_SATUAN_PRODUK,
  DELETE_SATUAN_PRODUK,
} from '../../pages/actions/satuanprodukAction';

const SatuanProduk = (state, action) => {
  const { type } = action;
  switch (type) {
    case GET_SATUAN_PRODUK:
      return {
        ...state,
        getSatuanProdukResult: action.payload.data,
        getSatuanProdukLoading: action.payload.loading,
        getSatuanProdukError: action.payload.errorMessage,
      };

    case ADD_SATUAN_PRODUK:
      return {
        ...state,
        addSatuanProdukResult: action.payload.data,
        addSatuanProdukLoading: action.payload.loading,
        addSatuanProdukError: action.payload.errorMessage,
      };
    case EDIT_SATUAN_PRODUK:
      return {
        ...state,
        editSatuanProdukResult: action.payload.data,
      };
    case UPDATE_SATUAN_PRODUK:
      return {
        ...state,
        updateSatuanProdukResult: action.payload.data,
        updateSatuanProdukLoading: action.payload.loading,
        updateSatuanProdukError: action.payload.errorMessage,
      };
    case DELETE_SATUAN_PRODUK:
      return {
        ...state,
        deleteSatuanProdukResult: action.payload.data,
        deleteSatuanProdukLoading: action.payload.loading,
        deleteSatuanProdukError: action.payload.errorMessage,
      };

    default:
      return state;
  }
};

export default SatuanProduk;
