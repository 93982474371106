import axios from 'axios';
import Swal from 'sweetalert2';
import {
  API_URL_getdataproyek,
  API_URL_createdataproyek,
  API_URL_edeldataproyek,
  API_URL_createdetailproyekobjektif,
  API_URL_detailproyekobjektif,
  API_URL_createdetailproyekresiko,
  API_URL_detailproyekresiko,
  API_URL_detailproyekbudget,
  API_URL_createdetailproyekbudget,
  API_URL_detailproyekdayadukung,
  API_URL_createdetailproyekdayadukung,
  API_URL_detailproyeksow,
  API_URL_createdetailproyeksow,
  API_URL_detailproyekteampelaksana,
  API_URL_createdetailproyekteampelaksana,
  API_URL_detailproyekwaktupelaksana,
  API_URL_createdetailproyekwaktupelaksana,
  API_URL_detailproyekpengiriman,
  API_URL_createdetailproyekpengiriman,
  API_URL_createdetailproyekkegiatan,
  API_URL_detailproyekkegiatan,
  API_URL_adddokumentasikegiatan,
  API_URL_getdatapengadaan,
} from '../constants';

export const GET_USULAN_PROYEK = 'GET_USULAN_PROYEK';
export const ADD_USULAN_PROYEK = 'ADD_USULAN_PROYEK';
export const GET_PROYEK_RILIS = 'GET_PROYEK_RILIS';
export const ADD_PROYEK_RILIS = 'ADD_PROYEK_RILIS';
export const EDIT_PROYEK = 'EDIT_PROYEK';
export const UPDATE_USULAN_PROYEK = ' UPDATE_USULAN_PROYEK';
export const DELETE_TIPE_ARUS_KAS = ' DELETE_TIPE_ARUS_KAS';
export const MANAJEMEN_PROYEK_TAB = ' MANAJEMEN_PROYEK_TAB';
export const GET_OBJEKTIF_PROYEK = 'GET_OBJEKTIF_PROYEK';
export const ADD_OBJEKTIF_PROYEK = 'ADD_OBJEKTIF_PROYEK';
export const UPDATE_OBJEKTIF_PROYEK = ' UPDATE_OBJEKTIF_PROYEK';
export const ADD_RESIKO_PROYEK = 'ADD_RESIKO_PROYEK';
export const DELETE_RESIKO_PROYEK = ' DELETE_RESIKO_PROYEK';
export const GET_BUDGET_PROYEK = 'GET_BUDGET_PROYEK';
export const DELETE_BUDGET_PROYEK = ' DELETE_BUDGET_PROYEK';
export const ADD_BUDGET_PROYEK = 'ADD_BUDGET_PROYEK';
export const ADD_DAYADUKUNG_PROYEK = 'ADD_DAYADUKUNG_PROYEK';
export const DELETE_DAYADUKUNG_PROYEK = ' DELETE_DAYADUKUNG_PROYEK';
export const GET_SOW_PROYEK = 'GET_SOW_PROYEK';
export const ADD_SOW_PROYEK = 'ADD_SOW_PROYEK';
export const UPDATE_SOW_PROYEK = ' UPDATE_SOW_PROYEK';
export const ADD_TEAMPELAKSANA_PROYEK = 'ADD_TEAMPELAKSANA_PROYEK';
export const DELETE_TEAMPELAKSANA_PROYEK = ' DELETE_TEAMPELAKSANA_PROYEK';
export const ADD_WAKTUPELAKSANA_PROYEK = 'ADD_WAKTUPELAKSANA_PROYEK';
export const DELETE_WAKTUPELAKSANA_PROYEK = ' DELETE_WAKTUPELAKSANA_PROYEK';
export const ADD_PENGIRIMAN_PROYEK = 'ADD_PENGIRIMAN_PROYEK';
export const DELETE_PENGIRIMAN_PROYEK = ' DELETE_PENGIRIMAN_PROYEK';
export const ADD_KEGIATAN_PROYEK = 'ADD_KEGIATAN_PROYEK';
export const DELETE_KEGIATAN_PROYEK = ' DELETE_KEGIATAN_PROYEK';
export const GET_DATA_PENGADAAN = 'GET_DATA_PENGADAAN';

export const getDataPengadaan = (dispatch, data) => {
  dispatch({
    type: GET_DATA_PENGADAAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_getdatapengadaan + data.param,
  })
    .then(response => {
      dispatch({
        type: GET_DATA_PENGADAAN,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_DATA_PENGADAAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const addDokumentasiKegiatan = (dispatch, data, pk) => {
  dispatch({
    type: ADD_KEGIATAN_PROYEK,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'PUT',
    url: API_URL_adddokumentasikegiatan + pk,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: ADD_KEGIATAN_PROYEK,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_KEGIATAN_PROYEK,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const deleteKegiatanProyek = (dispatch, pk) => {
  dispatch({
    type: DELETE_KEGIATAN_PROYEK,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#6a82fb',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
  }).then(result => {
    if (result.isConfirmed) {
      axios({
        method: 'DELETE',
        url: API_URL_detailproyekkegiatan + pk,
        timeout: 120000,
      })
        .then(response => {
          Swal.fire('Deleted!', response.data.messages, 'success');
          dispatch({
            type: DELETE_KEGIATAN_PROYEK,
            payload: {
              loading: false,
              data: response.data,
              errorMessage: false,
            },
          });
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
          });
          dispatch({
            type: DELETE_KEGIATAN_PROYEK,
            payload: {
              loading: false,
              data: false,
              errorMessage: error.message,
            },
          });
        });
    }
  });
};

export const addKegiatanProyek = (dispatch, data) => {
  dispatch({
    type: ADD_KEGIATAN_PROYEK,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_createdetailproyekkegiatan,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: ADD_KEGIATAN_PROYEK,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_KEGIATAN_PROYEK,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const deletePengirimanProyek = (dispatch, pk) => {
  dispatch({
    type: DELETE_PENGIRIMAN_PROYEK,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#6a82fb',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
  }).then(result => {
    if (result.isConfirmed) {
      axios({
        method: 'DELETE',
        url: API_URL_detailproyekpengiriman + pk,
        timeout: 120000,
      })
        .then(response => {
          Swal.fire('Deleted!', response.data.messages, 'success');
          dispatch({
            type: DELETE_PENGIRIMAN_PROYEK,
            payload: {
              loading: false,
              data: response.data,
              errorMessage: false,
            },
          });
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
          });
          dispatch({
            type: DELETE_PENGIRIMAN_PROYEK,
            payload: {
              loading: false,
              data: false,
              errorMessage: error.message,
            },
          });
        });
    }
  });
};

export const addPengirimanProyek = (dispatch, data) => {
  dispatch({
    type: ADD_PENGIRIMAN_PROYEK,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_createdetailproyekpengiriman,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: ADD_PENGIRIMAN_PROYEK,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_PENGIRIMAN_PROYEK,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const deleteWaktuPelaksanaProyek = (dispatch, pk) => {
  dispatch({
    type: DELETE_WAKTUPELAKSANA_PROYEK,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#6a82fb',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
  }).then(result => {
    if (result.isConfirmed) {
      axios({
        method: 'DELETE',
        url: API_URL_detailproyekwaktupelaksana + pk,
        timeout: 120000,
      })
        .then(response => {
          Swal.fire('Deleted!', response.data.messages, 'success');
          dispatch({
            type: DELETE_WAKTUPELAKSANA_PROYEK,
            payload: {
              loading: false,
              data: response.data,
              errorMessage: false,
            },
          });
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
          });
          dispatch({
            type: DELETE_WAKTUPELAKSANA_PROYEK,
            payload: {
              loading: false,
              data: false,
              errorMessage: error.message,
            },
          });
        });
    }
  });
};

export const addWaktuPelaksanaProyek = (dispatch, data) => {
  dispatch({
    type: ADD_WAKTUPELAKSANA_PROYEK,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_createdetailproyekwaktupelaksana,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: ADD_WAKTUPELAKSANA_PROYEK,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_WAKTUPELAKSANA_PROYEK,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const deleteTeamPelaksanaProyek = (dispatch, pk) => {
  dispatch({
    type: DELETE_TEAMPELAKSANA_PROYEK,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#6a82fb',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
  }).then(result => {
    if (result.isConfirmed) {
      axios({
        method: 'DELETE',
        url: API_URL_detailproyekteampelaksana + pk,
        timeout: 120000,
      })
        .then(response => {
          Swal.fire('Deleted!', response.data.messages, 'success');
          dispatch({
            type: DELETE_TEAMPELAKSANA_PROYEK,
            payload: {
              loading: false,
              data: response.data,
              errorMessage: false,
            },
          });
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
          });
          dispatch({
            type: DELETE_TEAMPELAKSANA_PROYEK,
            payload: {
              loading: false,
              data: false,
              errorMessage: error.message,
            },
          });
        });
    }
  });
};

export const addTeamPelaksanaProyek = (dispatch, data) => {
  dispatch({
    type: ADD_TEAMPELAKSANA_PROYEK,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_createdetailproyekteampelaksana,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: ADD_TEAMPELAKSANA_PROYEK,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_TEAMPELAKSANA_PROYEK,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const updateSOWProyek = (dispatch, data) => {
  dispatch({
    type: UPDATE_SOW_PROYEK,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'PUT',
    url: API_URL_detailproyeksow + data.pk,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: UPDATE_SOW_PROYEK,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: UPDATE_SOW_PROYEK,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const addSOWProyek = (dispatch, data) => {
  dispatch({
    type: ADD_SOW_PROYEK,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_createdetailproyeksow,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: ADD_SOW_PROYEK,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_SOW_PROYEK,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const getSOWProyek = (dispatch, data) => {
  dispatch({
    type: GET_SOW_PROYEK,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_detailproyeksow + data.pk,
  })
    .then(response => {
      dispatch({
        type: GET_SOW_PROYEK,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_SOW_PROYEK,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const deleteDayaDukungProyek = (dispatch, pk) => {
  dispatch({
    type: DELETE_DAYADUKUNG_PROYEK,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#6a82fb',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
  }).then(result => {
    if (result.isConfirmed) {
      axios({
        method: 'DELETE',
        url: API_URL_detailproyekdayadukung + pk,
        timeout: 120000,
      })
        .then(response => {
          Swal.fire('Deleted!', response.data.messages, 'success');
          dispatch({
            type: DELETE_DAYADUKUNG_PROYEK,
            payload: {
              loading: false,
              data: response.data,
              errorMessage: false,
            },
          });
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
          });
          dispatch({
            type: DELETE_DAYADUKUNG_PROYEK,
            payload: {
              loading: false,
              data: false,
              errorMessage: error.message,
            },
          });
        });
    }
  });
};

export const addDayaDukungProyek = (dispatch, data) => {
  dispatch({
    type: ADD_DAYADUKUNG_PROYEK,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_createdetailproyekdayadukung,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: ADD_DAYADUKUNG_PROYEK,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_DAYADUKUNG_PROYEK,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const addBudgetProyek = (dispatch, data, type_budget) => {
  dispatch({
    type: ADD_BUDGET_PROYEK,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'PUT',
    url: API_URL_createdetailproyekbudget + type_budget,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: ADD_BUDGET_PROYEK,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_BUDGET_PROYEK,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const deleteBudgetProyek = (dispatch, pk) => {
  dispatch({
    type: DELETE_BUDGET_PROYEK,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#6a82fb',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
  }).then(result => {
    if (result.isConfirmed) {
      axios({
        method: 'DELETE',
        url: API_URL_detailproyekbudget + pk,
        timeout: 120000,
      })
        .then(response => {
          Swal.fire('Deleted!', response.data.messages, 'success');
          dispatch({
            type: DELETE_BUDGET_PROYEK,
            payload: {
              loading: false,
              data: response.data,
              errorMessage: false,
            },
          });
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
          });
          dispatch({
            type: DELETE_BUDGET_PROYEK,
            payload: {
              loading: false,
              data: false,
              errorMessage: error.message,
            },
          });
        });
    }
  });
};

export const getBudgetProyek = (dispatch, data) => {
  dispatch({
    type: GET_BUDGET_PROYEK,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_detailproyekbudget + data.proyek_id,
  })
    .then(response => {
      dispatch({
        type: GET_BUDGET_PROYEK,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_BUDGET_PROYEK,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const deleteResikoProyek = (dispatch, pk) => {
  dispatch({
    type: DELETE_RESIKO_PROYEK,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#6a82fb',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
  }).then(result => {
    if (result.isConfirmed) {
      axios({
        method: 'DELETE',
        url: API_URL_detailproyekresiko + pk,
        timeout: 120000,
      })
        .then(response => {
          Swal.fire('Deleted!', response.data.messages, 'success');
          dispatch({
            type: DELETE_RESIKO_PROYEK,
            payload: {
              loading: false,
              data: response.data,
              errorMessage: false,
            },
          });
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
          });
          dispatch({
            type: DELETE_RESIKO_PROYEK,
            payload: {
              loading: false,
              data: false,
              errorMessage: error.message,
            },
          });
        });
    }
  });
};

export const addResikoProyek = (dispatch, data) => {
  dispatch({
    type: ADD_RESIKO_PROYEK,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_createdetailproyekresiko,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: ADD_RESIKO_PROYEK,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_RESIKO_PROYEK,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const updateObjektifProyek = (dispatch, data) => {
  dispatch({
    type: UPDATE_OBJEKTIF_PROYEK,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'PUT',
    url: API_URL_detailproyekobjektif + data.pk,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: UPDATE_OBJEKTIF_PROYEK,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: UPDATE_OBJEKTIF_PROYEK,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const addObjektifProyek = (dispatch, data) => {
  dispatch({
    type: ADD_OBJEKTIF_PROYEK,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_createdetailproyekobjektif,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: ADD_OBJEKTIF_PROYEK,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_OBJEKTIF_PROYEK,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const getObjektifProyek = (dispatch, data) => {
  dispatch({
    type: GET_OBJEKTIF_PROYEK,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_detailproyekobjektif + data.pk,
  })
    .then(response => {
      dispatch({
        type: GET_OBJEKTIF_PROYEK,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_OBJEKTIF_PROYEK,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const manajemenProyekAktif = (dispatch, data) => {
  dispatch({
    type: MANAJEMEN_PROYEK_TAB,
    payload: {
      data: data,
    },
  });
};

export const getUsulanProyek = (dispatch, data) => {
  dispatch({
    type: GET_USULAN_PROYEK,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_getdataproyek + data.status + data.param,
  })
    .then(response => {
      dispatch({
        type: GET_USULAN_PROYEK,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_USULAN_PROYEK,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const getProyekRilis = (dispatch, data) => {
  dispatch({
    type: GET_PROYEK_RILIS,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_getdataproyek + data.status + data.param,
  })
    .then(response => {
      dispatch({
        type: GET_PROYEK_RILIS,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_PROYEK_RILIS,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const addUsulanProyek = (dispatch, data) => {
  dispatch({
    type: ADD_USULAN_PROYEK,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_createdataproyek,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: ADD_USULAN_PROYEK,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_USULAN_PROYEK,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const addProyekRilis = (dispatch, data) => {
  dispatch({
    type: ADD_PROYEK_RILIS,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_createdataproyek,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: ADD_PROYEK_RILIS,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_PROYEK_RILIS,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const editProyek = (dispatch, data) => {
  dispatch({
    type: EDIT_PROYEK,
    payload: {
      data: data,
    },
  });
};

export const updateProyek = (dispatch, data) => {
  dispatch({
    type: UPDATE_USULAN_PROYEK,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'PUT',
    url: API_URL_edeldataproyek + data.pk,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: UPDATE_USULAN_PROYEK,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: UPDATE_USULAN_PROYEK,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const deleteProyek = (dispatch, pk) => {
  dispatch({
    type: DELETE_TIPE_ARUS_KAS,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#6a82fb',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
  }).then(result => {
    if (result.isConfirmed) {
      axios({
        method: 'DELETE',
        url: API_URL_edeldataproyek + pk,
        timeout: 120000,
      })
        .then(response => {
          Swal.fire('Deleted!', response.data.messages, 'success');
          dispatch({
            type: DELETE_TIPE_ARUS_KAS,
            payload: {
              loading: false,
              data: response.data,
              errorMessage: false,
            },
          });
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
          });
          dispatch({
            type: DELETE_TIPE_ARUS_KAS,
            payload: {
              loading: false,
              data: false,
              errorMessage: error.message,
            },
          });
        });
    }
  });
};
