import axios from 'axios';
import Swal from 'sweetalert2';
import {
  API_URL_getpendapatan,
  API_URL_creatependapatan,
  API_URL_edelpendapatan,
  API_URL_getpendapatanpelanggan,
} from '../constants';

export const GET_PENDAPATAN = 'GET_PENDAPATAN';
export const ADD_PENDAPATAN = 'ADD_PENDAPATAN';
export const EDIT_PENDAPATAN = 'EDIT_PENDAPATAN';
export const UPDATE_PENDAPATAN = ' UPDATE_PENDAPATAN';
export const DELETE_PENDAPATAN = ' DELETE_PENDAPATAN';
export const GET_PENDAPATANPELANGGAN = 'GET_PENDAPATANPELANGGAN';

export const getPendapatanPelanggan = (dispatch, data) => {
  dispatch({
    type: GET_PENDAPATANPELANGGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_getpendapatanpelanggan + data.jenispelanggan + data.param,
  })
    .then(response => {
      dispatch({
        type: GET_PENDAPATANPELANGGAN,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_PENDAPATANPELANGGAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const getpendapatan = (dispatch, data) => {
  dispatch({
    type: GET_PENDAPATAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_getpendapatan + data.param,
  })
    .then(response => {
      dispatch({
        type: GET_PENDAPATAN,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_PENDAPATAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const addpendapatan = (dispatch, data) => {
  dispatch({
    type: ADD_PENDAPATAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_creatependapatan,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: ADD_PENDAPATAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_PENDAPATAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const editpendapatan = (dispatch, data) => {
  dispatch({
    type: EDIT_PENDAPATAN,
    payload: {
      data: data,
    },
  });
};

export const updatependapatan = (dispatch, data) => {
  dispatch({
    type: UPDATE_PENDAPATAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'PUT',
    url: API_URL_edelpendapatan + data.pk,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: UPDATE_PENDAPATAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: UPDATE_PENDAPATAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const deletependapatan = (dispatch, pk) => {
  dispatch({
    type: DELETE_PENDAPATAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#6a82fb',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
  }).then(result => {
    if (result.isConfirmed) {
      axios({
        method: 'DELETE',
        url: API_URL_edelpendapatan + pk,
        timeout: 120000,
      })
        .then(response => {
          Swal.fire('Deleted!', response.data.messages, 'success');
          dispatch({
            type: DELETE_PENDAPATAN,
            payload: {
              loading: false,
              data: response.data,
              errorMessage: false,
            },
          });
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
          });
          dispatch({
            type: DELETE_PENDAPATAN,
            payload: {
              loading: false,
              data: false,
              errorMessage: error.message,
            },
          });
        });
    }
  });
};
