import wilayahReducer from "./wilayah";
import fttxReducer from "./fttx";
import oltReducer from "./nodeolt";
import routerReducer from "./router";
import akunoltReducer from "./akunolt";
import regpelangganReducer from "./regpelanggan";
import oltregpelangganReducer from "./oltregpelanggan";
import setuppelangganReducer from "./setuppelanggan";
import pelangganReducer from "./pelanggan";
import userReducer from "./users";
import tagihanpelangganReducer from "./tagihanpelanggan";
import tesakunrouterReducer from "./tesakunrouter";
import tesakunoltReducer from "./tesakunolt";
import datateknisReducer from "./datateknis";
import pembayaranReducer from "./pembayaranpelanggan";
import datacoaReducer from "./datacoa";
import transaksiReducer from "./transaksi";
import stokbarangReducer from "./stokbarang";
import paketinternetReducer from "./paketinternet";
import materialReducer from "./material";
import tipebayarReducer from "./tipebayar";
import rekeningbankReducer from "./rekeningbank";
import tipearuskasReducer from "./tipearuskas";
import pengelolaanbebanReducer from "./pengelolaanbeban";
import keuanganReducer from "./keuangan";
import bebantagihanReducer from "./bebantagihan";
import hutangReducer from "./hutang";
import pengeluaranReducer from "./pengeluaran";
import danaReducer from "./dana";
import pendapatanReducer from "./pendapatan";
import proyekReducer from "./proyek";
import mataerialstokReducer from "./materialstok";
import asetReducer from "./aset";
import peralatanReducer from "./peralatan";
import perlengkapanReducer from "./perlengkapan";
import barangmasukReducer from "./barangmasuk";
import barangkeluarReducer from "./barangkeluar";
import barangreturnReducer from "./barangreturn";
import barangformresiduReducer from "./barangformresidu";
import prakeluaranReducer from "./prakeluaran";
import satuanprodukReducer from "./satuanproduk";
import lokasiinventoriReducer from "./lokasiinventori";
import salesReducer from "./sales";
import gangguanpelangganReducer from './gangguanpelanggan';


const initialState = {
  getWilayahResult: false,
  getWilayahLoading: false,
  getWilayahError: false,
  addWilayahResult: false,
  addWilayahLoading: false,
  addWilayahError: false,
  deleteWilayahResult: false,
  deleteWilayahLoading: false,
  deleteWilayahError: false,

  getFttxResult: false,
  getFttxLoading: false,
  getFttxError: false,
  addFttxResult: false,
  addFttxLoading: false,
  addFttxError: false,
  deleteFttxResult: false,
  deleteFttxLoading: false,
  deleteFttxError: false,

  getgangguanResult: false,
  getgangguanLoading: false,
  getgangguanError: false,
  gettiketgangguanResult: false,
  gettiketgangguanLoading: false,
  gettiketgangguanError: false,
  addgangguanResult: false,
  addgangguanLoading: false,
  addgangguanError: false,
  updategangguanResult: false,
  updategangguanLoading: false,
  updategangguanError: false,

  getOltResult: false,
  getOltLoading: false,
  getOltError: false,
  getOltDetailResult: false,
  getOltDetailLoading: false,
  getOltDetailError: false,
  addOltResult: false,
  addOltLoading: false,
  addOltError: false,
  deleteOltResult: false,
  deleteOltLoading: false,
  deleteOltError: false,
  editOltResult: false,
  updateOltResult: false,
  updateOltLoading: false,
  updateOltError: false,

  getRouterResult: false,
  getRouterLoading: false,
  getRouterError: false,
  addRouterResult: false,
  addRouterLoading: false,
  addRouterError: false,
  deleteRouterResult: false,
  deleteRouterLoading: false,
  deleteRouterError: false,
  editRouterResult: false,
  updateRouterResult: false,
  updateRouterLoading: false,
  updateRouterError: false,

  getAkunoltResult: false,
  getAkunoltLoading: false,
  getAkunoltError: false,
  addAkunoltResult: false,
  addAkunoltLoading: false,
  addAkunoltError: false,
  deleteAkunoltResult: false,
  deleteAkunoltLoading: false,
  deleteAkunoltError: false,
  editAkunoltResult: false,
  updateAkunoltResult: false,
  updateAkunoltLoading: false,
  updateAkunoltError: false,

  getRegpelangganResult: false,
  getRegpelangganLoading: false,
  getRegpelangganError: false,
  addRegpelangganResult: false,
  addRegpelangganLoading: false,
  addRegpelangganError: false,
  deleteRegpelangganResult: false,
  deleteRegpelangganLoading: false,
  deleteRegpelangganError: false,
  editRegpelangganResult: false,
  updateRegpelangganResult: false,
  updateRegpelangganLoading: false,
  updateRegpelangganError: false,
  uploaddataRegpelangganResult: false,
  uploaddataRegpelangganLoading: false,
  uploaddataRegpelangganError: false,

  getOltRegpelangganResult: false,
  getOltRegpelangganLoading: false,
  getOltRegpelangganError: false,
  addOltRegpelangganResult: false,
  addOltRegpelangganLoading: false,
  addOltRegpelangganError: false,
  deleteOltRegpelangganResult: false,
  deleteOltRegpelangganLoading: false,
  deleteOltRegpelangganError: false,
  editOltRegpelangganResult: false,
  updateOltRegpelangganResult: false,
  updateOltRegpelangganLoading: false,
  updateOltRegpelangganError: false,

  setuppelangganResult: false,
  setuppelangganLoading: false,
  setuppelangganError: false,

  getpelangganResult: false,
  getpelangganLoading: false,
  getpelangganError: false,
  addpelangganResult: false,
  addpelangganLoading: false,
  addpelangganError: false,
  adddetailpelangganResult: false,
  adddetailpelangganLoading: false,
  adddetailpelangganError: false,
  editpelangganResult: false,
  jenispelangganselectedResult: 1,
  regpelangganselectedResult: 1,
  datapembayaranselectedResult: "1",
  getdetailpelangganResult: false,
  getdetailpelangganLoading: false,
  getdetailpelangganError: false,
  getdppelangganResult: false,
  getdppelangganLoading: false,
  getdppelangganError: false,
  adddppelangganResult: false,
  adddppelangganLoading: false,
  adddppelangganError: false,
  deletedppelangganResult: false,
  deletedppelangganLoading: false,
  deletedppelangganError: false,
  updatedetailpelangganResult: false,
  updatedetailpelangganLoading: false,
  updatedetailpelangganError: false,
  updatepelangganResult: false,
  updatepelangganLoading: false,
  updatepelangganError: false,
  deletepelangganResult: false,
  deletepelangganLoading: false,
  deletepelangganError: false,
  deletdetailepelangganResult: false,
  deletdetailepelangganLoading: false,
  deletdetailepelangganError: false,

  addCOAPelangganResult: false,
  addCOAPelangganLoading: false,
  addCOAPelangganError: false,
  getCOAPelangganResult: false,
  getCOAPelangganLoading: false,
  getCOAPelangganError: false,
  deleteCOAPelangganResult: false,
  deleteCOAPelangganLoading: false,
  deleteCOAPelangganError: false,

  settingakunResult: false,
  settingakunLoading: false,
  settingakunError: false,
  getTagihanpelangganResult: false,
  getTagihanpelangganLoading: false,
  getTagihanpelangganError: false,
  getPiutangpelangganResult: false,
  getPiutangpelangganLoading: false,
  getPiutangpelangganError: false,
  createTagihanpelangganResult: false,
  createTagihanpelangganLoading: false,
  createTagihanpelangganError: false,
  addtagihanResult: false,
  addtagihanLoading: false,
  addtagihanError: false,
  editTagihanpelangganResult: false,
  updateTagihanpelangganResult: false,
  updateTagihanpelangganLoading: false,
  updateTagihanpelangganError: false,
  deleteTagihanpelangganResult: false,
  deleteTagihanpelangganLoading: false,
  deleteTagihanpelangganError: false,
  BayarTagihanpelangganResult: false,
  BayarTagihanpelangganLoading: false,
  BayarTagihanpelangganError: false,
  GetSudahBayarTagihanpelangganResult: false,
  GetSudahBayarTagihanpelangganLoading: false,
  GetSudahBayarTagihanpelangganError: false,

  getUserResult: false,
  getUserLoading: false,
  getUserError: false,
  editUserResult: false,
  addUserResult: false,
  addUserLoading: false,
  addUserError: false,
  deleteUserResult: false,
  deleteUserLoading: false,
  deleteUserError: false,
  updateUserResult: false,
  updateUserLoading: false,
  updateUserError: false,

  tesakunrouterResult: false,
  tesakunrouterLoading: false,
  tesakunrouterError: false,

  tesakunoltResult: false,
  tesakunoltLoading: false,
  tesakunoltError: false,

  getdatateknisResult: false,
  getdatateknisLoading: false,
  getdatateknisError: false,
  adddatateknisResult: false,
  adddatateknisLoading: false,
  adddatateknisError: false,
  updatedatateknisResult: false,
  updatedatateknisLoading: false,
  updatedatateknisError: false,
  deletedatateknisResult: false,
  deletedatateknisLoading: false,
  deletedatateknisError: false,

  getpembayaranResult: false,
  getpembayaranLoading: false,
  getpembayaranError: false,
  getpembayarandetailResult: false,
  getpembayarandetailLoading: false,
  getpembayarandetailError: false,
  getpenerimaankasirResult: false,
  getpenerimaankasirLoading: false,
  getpenerimaankasirError: false,
  detailpenerimaankasirResult: false,
  detailpenerimaankasirLoading: false,
  detailpenerimaankasirError: false,
  terimapenerimaankasirResult: false,
  terimapenerimaankasirLoading: false,
  terimapenerimaankasirError: false,
  penyerahankasirResult: false,
  penyerahankasirLoading: false,
  penyerahankasirError: false,
  editpembayaranResult: false,
  updatepembayaranResult: false,
  updatepembayaranLoading: false,
  updatepembayaranError: false,
  deletepembayaranpelangganResult: false,
  deletepembayaranpelangganLoading: false,
  deletepembayaranpelangganError: false,

  getstokbarangResult: false,
  getstokbarangLoading: false,
  getstokbarangError: false,
  addbarangResult: false,
  addbarangLoading: false,
  addbarangError: false,
  editstokbarangResult: false,
  updatestokbarangResult: false,
  updatestokbarangLoading: false,
  updatestokbarangError: false,
  deletestokbarangResult: false,
  deletestokbarangLoading: false,
  deletestokbarangError: false,

  getMaterialResiduResult: false,
  getMaterialResiduLoading: false,
  getMaterialResiduError: false,

  getpaketinternetResult: false,
  getpaketinternetLoading: false,
  getpaketinternetError: false,
  addpaketinternetResult: false,
  addpaketinternetLoading: false,
  addpaketinternetError: false,
  editpaketinternetResult: false,

  getmaterialResult: false,
  getmaterialLoading: false,
  getmaterialError: false,
  addmaterialResult: false,
  addmaterialLoading: false,
  addmaterialError: false,
  editmaterialResult: false,
  updatematerialResult: false,
  updatematerialLoading: false,
  updatematerialError: false,

  getTipebayarResult: false,
  getTipebayarLoading: false,
  getTipebayarError: false,
  addTipebayarResult: false,
  addTipebayarLoading: false,
  addTipebayarError: false,
  editTipebayarResult: false,
  updateTipebayarResult: false,
  updateTipebayarLoading: false,
  updateTipebayarError: false,
  deleteTipebayarResult: false,
  deleteTipebayarLoading: false,
  deleteTipebayarError: false,

  getRekeningbankResult: false,
  getRekeningbankLoading: false,
  getRekeningbankError: false,
  addRekeningbankResult: false,
  addRekeningbankLoading: false,
  addRekeningbankError: false,
  editRekeningbankResult: false,
  updateRekeningbankResult: false,
  updateRekeningbankLoading: false,
  updateRekeningbankError: false,
  deleteRekeningbankResult: false,
  deleteRekeningbankLoading: false,
  deleteRekeningbankError: false,

  getTipearuskasResult: false,
  getTipearuskasLoading: false,
  getTipearuskasError: false,
  addTipearuskasResult: false,
  addTipearuskasLoading: false,
  addTipearuskasError: false,
  editTipearuskasResult: false,
  updateTipearuskasResult: false,
  updateTipearuskasLoading: false,
  updateTipearuskasError: false,
  deleteTipearuskasResult: false,
  deleteTipearuskasLoading: false,
  deleteTipearuskasError: false,

  // ==================================   COA   ====================================================
  //==== Akun ====
  getAkunResult: false,
  getAkunLoading: false,
  getAkunError: false,
  addAkunResult: false,
  addAkunLoading: false,
  addAkunError: false,
  editAkunResult: false,
  updateAkunResult: false,
  updateAkunLoading: false,
  updateAkunError: false,
  deleteAkunResult: false,
  //==== Akun Buku Besar ====
  getAkunBukuBesarResult: false,
  getAkunBukuBesarLoading: false,
  getAkunBukuBesarError: false,
  addAkunBukuBesarResult: false,
  addAkunBukuBesarLoading: false,
  addAkunBukuBesarError: false,
  editAkunBukuBesarResult: false,
  updateAkunBukuBesarResult: false,
  updateAkunBukuBesarLoading: false,
  updateAkunBukuBesarError: false,
  deleteAkunBukuBesarResult: false,
  //==== Kelompok ====
  getKelompokResult: false,
  getKelompokLoading: false,
  getKelompokError: false,
  addKelompokResult: false,
  addKelompokLoading: false,
  addKelompokError: false,
  editKelompokResult: false,
  updateKelompokResult: false,
  updateKelompokLoading: false,
  updateKelompokError: false,
  deleteKelompokResult: false,
  //==== Golongan ====
  getGolonganResult: false,
  getGolonganLoading: false,
  getGolonganError: false,
  addGolonganResult: false,
  addGolonganLoading: false,
  addGolonganError: false,
  editGolonganResult: false,
  updateGolonganResult: false,
  updateGolonganLoading: false,
  updateGolonganError: false,
  deleteGolonganResult: false,
  //==== SubGolongan ====
  getSubGolonganResult: false,
  getSubGolonganLoading: false,
  getSubGolonganError: false,
  addSubGolonganResult: false,
  addSubGolonganLoading: false,
  addSubGolonganError: false,
  editSubGolonganResult: false,
  updateSubGolonganResult: false,
  updateSubGolonganLoading: false,
  updateSubGolonganError: false,
  deleteSubGolonganResult: false,
  // ===============================================================================================

  // ===============================================================================================
  // Transaksi
  getJurnalResult: false,
  getJurnalLoading: false,
  getJurnalError: false,
  getTransaksiResult: false,
  getTransaksiLoading: false,
  getTransaksiError: false,
  getDetailTransaksiResult: false,
  getDetailTransaksiLoading: false,
  getDetailTransaksiError: false,
  editTransaksiResult: false,
  updateTransaksiResult: false,
  updateTransaksiLoading: false,
  updateTransaksiError: false,
  addTransaksiResult: false,
  addTransaksiLoading: false,
  addTransaksiError: false,
  addJurnalResult: false,
  addJurnalLoading: false,
  addJurnalError: false,
  deleteTransaksiResult: false,
  deleteTransaksiLoading: false,
  deleteTransaksiError: false,

  // ===============================================================================================
  //Penagihan
  getPenagihanResult: false,
  getPenagihanLoading: false,
  getPenagihanError: false,
  addPenagihanResult: false,
  addPenagihanLoading: false,
  addPenagihanError: false,
  deletePenagihanResult: false,
  deletePenagihanLoading: false,
  deletePenagihanError: false,
  editPenagihanResult: false,
  updatePenagihanResult: false,
  updatePenagihanLoading: false,
  updatePenagihanError: false,
  //Progress
  getProgressResult: false,
  getProgressLoading: false,
  getProgressError: false,
  addProgressResult: false,
  addProgressLoading: false,
  addProgressError: false,
  deleteProgressResult: false,
  deleteProgressLoading: false,
  deleteProgressError: false,
  editProgressResult: false,
  updateProgressResult: false,
  updateProgressLoading: false,
  updateProgressError: false,
  //Pencairan
  getPencairanResult: false,
  getPencairanLoading: false,
  getPencairanError: false,
  addPencairanResult: false,
  addPencairanLoading: false,
  addPencairanError: false,
  deletePencairanResult: false,
  deletePencairanLoading: false,
  deletePencairanError: false,
  editPencairanResult: false,
  updatePencairanResult: false,
  updatePencairanLoading: false,
  updatePencairanError: false,
  //Pengajuan
  getPengajuanResult: false,
  getPengajuanLoading: false,
  getPengajuanError: false,
  addPengajuanResult: false,
  addPengajuanLoading: false,
  addPengajuanError: false,
  deletePengajuanResult: false,
  deletePengajuanLoading: false,
  deletePengajuanError: false,
  editPengajuanResult: false,
  updatePengajuanResult: false,
  updatePengajuanLoading: false,
  updatePengajuanError: false,
  //Persetujuan
  getPersetujuanResult: false,
  getPersetujuanLoading: false,
  getPersetujuanError: false,
  addPersetujuanResult: false,
  addPersetujuanLoading: false,
  addPersetujuanError: false,
  deletePersetujuanResult: false,
  deletePersetujuanLoading: false,
  deletePersetujuanError: false,
  editPersetujuanResult: false,
  updatePersetujuanResult: false,
  updatePersetujuanLoading: false,
  updatePersetujuanError: false,
  //Pembelanjaan
  getPembelanjaanResult: false,
  getPembelanjaanLoading: false,
  getPembelanjaanError: false,
  addPembelanjaanResult: false,
  addPembelanjaanLoading: false,
  addPembelanjaanError: false,
  deletePembelanjaanResult: false,
  deletePembelanjaanLoading: false,
  deletePembelanjaanError: false,
  editPembelanjaanResult: false,
  updatePembelanjaanResult: false,
  updatePembelanjaanLoading: false,
  updatePembelanjaanError: false,

  // Pengelolaan Beban
  getbebanrutinResult: false,
  getbebanrutinLoading: false,
  getbebanrutinError: false,
  getrutinfluktuatifResult: false,
  getrutinfluktuatifLoading: false,
  getrutinfluktuatifError: false,
  getpartikularResult: false,
  getpartikularLoading: false,
  getpartikularError: false,
  getOperasionalResult: false,
  getOperasionalLoading: false,
  getOperasionalError: false,
  getProyekResult: false,
  getProyekLoading: false,
  getProyekError: false,
  getLainnyaResult: false,
  getLainnyaLoading: false,
  getLainnyaError: false,
  addRutinTetapResult: false,
  addRutinTetapLoading: false,
  addRutinTetapError: false,
  addRutinFluktuatifResult: false,
  addRutinFluktuatifLoading: false,
  addRutinFluktuatifError: false,
  addPartikularResult: false,
  addPartikularLoading: false,
  addPartikularError: false,
  addOperasionalResult: false,
  addOperasionalLoading: false,
  addOperasionalError: false,
  addProyekResult: false,
  addProyekLoading: false,
  addProyekError: false,
  addLainnyaResult: false,
  addLainnyaLoading: false,
  addLainnyaError: false,
  updateRutinTetapResult: false,
  updateRutinTetapLoading: false,
  updateRutinTetapError: false,
  updaterutinfluktuatifResult: false,
  updaterutinfluktuatifLoading: false,
  updaterutinfluktuatifError: false,
  updatepartikularResult: false,
  updatepartikularLoading: false,
  updatepartikularError: false,
  updateOperasionalResult: false,
  updateOperasionalLoading: false,
  updateOperasionalError: false,
  updateProyekResult: false,
  updateProyekLoading: false,
  updateProyekError: false,
  updateLainnyaResult: false,
  updateLainnyaLoading: false,
  updateLainnyaError: false,
  editrutintetapResult: false,
  editrutinfluktuatifResult: false,
  editpartikularResult: false,
  editOperasionalResult: false,
  editProyekResult: false,
  editLainnyaResult: false,
  addDetailBebanResult: false,
  addDetailBebanLoading: false,
  addDetailBebanError: false,
  getDetailBebanResult: false,
  getDetailBebanLoading: false,
  getDetailBebanError: false,
  addSettingBebanResult: false,
  addSettingBebanLoading: false,
  addSettingBebanError: false,
  getSettingBebanResult: false,
  getSettingBebanLoading: false,
  getSettingBebanError: false,
  addCOABebanResult: false,
  addCOABebanLoading: false,
  addCOABebanError: false,
  getCOABebanResult: false,
  getCOABebanLoading: false,
  getCOABebanError: false,
  deleteCOABebanResult: false,
  deleteCOABebanLoading: false,
  deleteCOABebanError: false,
  deleteBebanResult: false,
  deleteBebanLoading: false,
  deleteBebanError: false,

  //BebanTagihan
  getBebanTagihanResult: false,
  getBebanTagihanLoading: false,
  getBebanTagihanError: false,
  addBebanTagihanResult: false,
  addBebanTagihanLoading: false,
  addBebanTagihanError: false,
  deleteBebanTagihanResult: false,
  deleteBebanTagihanLoading: false,
  deleteBebanTagihanError: false,
  editBebanTagihanResult: false,
  updateBebanTagihanResult: false,
  updateBebanTagihanLoading: false,
  updateBebanTagihanError: false,

  //Piutang
  getHutangResult: false,
  getHutangLoading: false,
  getHutangError: false,
  addHutangResult: false,
  addHutangLoading: false,
  addHutangError: false,
  deleteHutangResult: false,
  deleteHutangLoading: false,
  deleteHutangError: false,
  editHutangResult: false,
  updateHutangResult: false,
  updateHutangLoading: false,
  updateHutangError: false,

  // KEUANGAN
  setortunaiResult: false,
  setortunaiLoading: false,
  setortunaiError: false,
  transferResult: false,
  transferLoading: false,
  transferError: false,
  getrekonsiliasiResult: false,
  getrekonsiliasiLoading: false,
  getrekonsiliasiError: false,
  getrekeningkoranResult: false,
  getrekeningkoranLoading: false,
  getrekeningkoranError: false,
  addrekeningkoranResult: false,
  addrekeningkoranLoading: false,
  addrekeningkoranError: false,

  //pendapatan
  getPendapatanResult: false,
  getPendapatanLoading: false,
  getPendapatanError: false,
  getPendapatanPelangganResult: false,
  getPendapatanPelangganLoading: false,
  getPendapatanPelangganError: false,
  addPendapatanResult: false,
  addPendapatanLoading: false,
  addPendapatanError: false,
  editPendapatanResult: false,
  updatePendapatanResult: false,
  updatePendapatanLoading: false,
  updatePendapatanError: false,
  deletePendapatanResult: false,
  deletePendapatanLoading: false,
  deletePendapatanError: false,

  //---proyek
  manajemenProyekTabResult: "1",
  getUsulanProyekResult: false,
  getUsulanProyekLoading: false,
  getUsulanProyekError: false,
  addUsulanProyekResult: false,
  addUsulanProyekLoading: false,
  addUsulanProyekError: false,
  getProyekRilisResult: false,
  getProyekRilisLoading: false,
  getProyekRilisError: false,
  addProyekRilisResult: false,
  addProyekRilisLoading: false,
  addProyekRilisError: false,
  getObjektifProyekDetailResult: false,
  getObjektifProyekDetailLoading: false,
  getObjektifProyekDetailError: false,
  addObjektifProyekDetailResult: false,
  addObjektifProyekDetailLoading: false,
  addObjektifProyekDetailError: false,
  updateObjektifProyekDetailResult: false,
  updateObjektifProyekDetailLoading: false,
  updateObjektifProyekDetailError: false,
  addResikoProyekDetailResult: false,
  addResikoProyekDetailLoading: false,
  addResikoProyekDetailError: false,
  deleteResikoProyekDetailResult: false,
  deleteResikoProyekDetailLoading: false,
  deleteResikoProyekDetailError: false,
  addBudgetProyekDetailResult: false,
  addBudgetProyekDetailLoading: false,
  addBudgetProyekDetailError: false,
  deleteBudgetProyekDetailResult: false,
  deleteBudgetProyekDetailLoading: false,
  deleteBudgetProyekDetailError: false,
  getBudgetProyekDetailResult: false,
  getBudgetProyekDetailLoading: false,
  getBudgetProyekDetailError: false,
  addDayaDukungProyekDetailResult: false,
  addDayaDukungProyekDetailLoading: false,
  addDayaDukungProyekDetailError: false,
  deleteDayaDukungProyekDetailResult: false,
  deleteDayaDukungProyekDetailLoading: false,
  deleteDayaDukungProyekDetailError: false,
  getSOWProyekDetailResult: false,
  getSOWProyekDetailLoading: false,
  getSOWProyekDetailError: false,
  addSOWProyekDetailResult: false,
  addSOWProyekDetailLoading: false,
  addSOWProyekDetailError: false,
  updateSOWProyekDetailResult: false,
  updateSOWProyekDetailLoading: false,
  updateSOWProyekDetailError: false,
  addTeamPelaksanaProyekDetailResult: false,
  addTeamPelaksanaProyekDetailLoading: false,
  addTeamPelaksanaProyekDetailError: false,
  deleteTeamPelaksanaProyekDetailResult: false,
  deleteTeamPelaksanaProyekDetailLoading: false,
  deleteTeamPelaksanaProyekDetailError: false,
  addWaktuPelaksanaProyekDetailResult: false,
  addWaktuPelaksanaProyekDetailLoading: false,
  addWaktuPelaksanaProyekDetailError: false,
  deleteWaktuPelaksanaProyekDetailResult: false,
  deleteWaktuPelaksanaProyekDetailLoading: false,
  deleteWaktuPelaksanaProyekDetailError: false,
  addPengirimanProyekDetailResult: false,
  addPengirimanProyekDetailLoading: false,
  addPengirimanProyekDetailError: false,
  deletePengirimanProyekDetailResult: false,
  deletePengirimanProyekDetailLoading: false,
  deletePengirimanProyekDetailError: false,
  addKegiatanProyekDetailResult: false,
  addKegiatanProyekDetailLoading: false,
  addKegiatanProyekDetailError: false,
  deleteKegiatanProyekDetailResult: false,
  deleteKegiatanProyekDetailLoading: false,
  deleteKegiatanProyekDetailError: false,

  //Perlengkapan
  getPerlengkapanResult: false,
  getPerlengkapanLoading: false,
  getPerlengkapanError: false,
  addPerlengkapanResult: false,
  addPerlengkapanLoading: false,
  addPerlengkapanError: false,
  deletePerlengkapanResult: false,
  deletePerlengkapanLoading: false,
  deletePerlengkapanError: false,
  editPerlengkapanResult: false,
  updatePerlengkapanResult: false,
  updatePerlengkapanLoading: false,
  updatePerlengkapanError: false,

  //materialstok
  getMaterialstokResult: false,
  getMaterialstokLoading: false,
  getMaterialstokError: false,
  addMaterialstokResult: false,
  addMaterialstokLoading: false,
  addMaterialstokError: false,
  deleteMaterialstokResult: false,
  deleteMaterialstokLoading: false,
  deleteMaterialstokError: false,
  editMaterialstokResult: false,
  updateMaterialstokResult: false,
  updateMaterialstokLoading: false,
  updateMaterialstokError: false,

  //peralatan
  getPeralatanResult: false,
  getPeralatanLoading: false,
  getPeralatanError: false,
  addPeralatanResult: false,
  addPeralatanLoading: false,
  addPeralatanError: false,
  deletePeralatanResult: false,
  deletePeralatanLoading: false,
  deletePeralatanError: false,
  editPeralatanResult: false,
  updatePeralatanResult: false,
  updatePeralatanLoading: false,
  updatePeralatanError: false,

  //aset
  getAsetResult: false,
  getAsetLoading: false,
  getAsetError: false,
  addAsetResult: false,
  addAsetLoading: false,
  addAsetError: false,
  deleteAsetResult: false,
  deleteAsetLoading: false,
  deleteAsetError: false,
  editAsetResult: false,
  updateAsetResult: false,
  updateAsetLoading: false,
  updateAsetError: false,

  //barangmasuk
  getBarangmasukResult: false,
  getBarangmasukLoading: false,
  getBarangmasukError: false,
  addBarangmasukResult: false,
  addBarangmasukLoading: false,
  addBarangmasukError: false,
  deleteBarangmasukResult: false,
  deleteBarangmasukLoading: false,
  deleteBarangmasukError: false,
  editBarangmasukResult: false,
  updateBarangmasukResult: false,
  updateBarangmasukLoading: false,
  updateBarangmasukError: false,

  //Barangkeluar
  getBarangkeluarResult: false,
  getBarangkeluarLoading: false,
  getBarangkeluarError: false,
  addBarangkeluarResult: false,
  addBarangkeluarLoading: false,
  addBarangkeluarError: false,
  deleteBarangkeluarResult: false,
  deleteBarangkeluarLoading: false,
  deleteBarangkeluarError: false,
  editBarangkeluarResult: false,
  updateBarangkeluarResult: false,
  updateBarangkeluarLoading: false,
  updateBarangkeluarError: false,

  //BarangReturn
  getBarangReturnResult: false,
  getBarangReturnLoading: false,
  getBarangReturnError: false,
  addBarangReturnResult: false,
  addBarangReturnLoading: false,
  addBarangReturnError: false,
  deleteBarangReturnResult: false,
  deleteBarangReturnLoading: false,
  deleteBarangReturnError: false,
  editBarangReturnResult: false,
  updateBarangReturnResult: false,
  updateBarangReturnLoading: false,
  updateBarangReturnError: false,

  //FormResidu
  getFormResiduResult: false,
  getFormResiduLoading: false,
  getFormResiduError: false,
  addFormResiduResult: false,
  addFormResiduLoading: false,
  addFormResiduError: false,
  deleteFormResiduResult: false,
  deleteFormResiduLoading: false,
  deleteFormResiduError: false,
  editFormResiduResult: false,
  updateFormResiduResult: false,
  updateFormResiduLoading: false,
  updateFormResiduError: false,

  // Prakeluaran
  getPrakeluaranResult: false,
  getPrakeluaranLoading: false,
  getPrakeluaranError: false,
  getDpdatpelResult: false,
  getDpdatpelLoading: false,
  getDpdatpelError: false,
  getCOAPrakeluaranResult: false,
  getCOAPrakeluaranLoading: false,
  getCOAPrakeluaranError: false,
  addPrakeluaranResult: false,
  addPrakeluaranLoading: false,
  addPrakeluaranError: false,
  addDpdatpelResult: false,
  addDpdatpelLoading: false,
  addDpdatpelError: false,
  addCOAPrakeluaranResult: false,
  addCOAPrakeluaranLoading: false,
  addCOAPrakeluaranError: false,
  deletePrakeluaranResult: false,
  deletePrakeluaranLoading: false,
  deletePrakeluaranError: false,
  deleteCOAPrakeluaranResult: false,
  deleteCOAPrakeluaranLoading: false,
  deleteCOAPrakeluaranError: false,
  editPrakeluaranResult: false,
  updatePrakeluaranResult: false,
  updatePrakeluaranLoading: false,
  updatePrakeluaranError: false,

  getSatuanProdukResult: false,
  getSatuanProdukLoading: false,
  getSatuanProdukError: false,
  addSatuanProdukResult: false,
  addSatuanProdukLoading: false,
  addSatuanProdukError: false,
  editSatuanProdukResult: false,
  updateSatuanProdukResult: false,
  updateSatuanProdukLoading: false,
  updateSatuanProdukError: false,
  deleteSatuanProdukResult: false,
  deleteSatuanProdukLoading: false,
  deleteSatuanProdukError: false,

  getLokasiInventoriResult: false,
  getLokasiInventoriLoading: false,
  getLokasiInventoriError: false,
  addLokasiInventoriResult: false,
  addLokasiInventoriLoading: false,
  addLokasiInventoriError: false,
  editLokasiInventoriResult: false,
  updateLokasiInventoriResult: false,
  updateLokasiInventoriLoading: false,
  updateLokasiInventoriError: false,
  deleteLokasiInventoriResult: false,
  deleteLokasiInventoriLoading: false,
  deleteLokasiInventoriError: false,

  getDetailStokBarangResult: false,
  getDetailStokBarangLoading: false,
  getDetailStokBarangError: false,
  getDetailStokPosisiResult: false,
  getDetailStokPosisiLoading: false,
  getDetailStokPosisiError: false,

  addExpandResult: false,
  addExpandLoading: false,
  addExpandError: false,
  getDataPengadaanResult: false,
  getDataPengadaanLoading: false,
  getDataPengadaanError: false,

  getSalesResult: false,
  getSalesLoading: false,
  getSalesError: false,
  addSalesResult: false,
  addSalesLoading: false,
  addSalesError: false,
  editSalesResult: false,
  updateSalesResult: false,
  updateSalesLoading: false,
  updateSalesError: false,
  deleteSalesResult: false,
  deleteSalesLoading: false,
  deleteSalesError: false,
};

const combineReducers = (reducers) => {
  return (state, action) => {
    return Object.keys(reducers).reduce((acc, prop) => {
      return {
        ...acc,
        ...reducers[prop]({ [prop]: acc[prop] }, action),
      };
    }, state);
  };
};

const appReducers = combineReducers({
  wilayahReducer,
  fttxReducer,
  oltReducer,
  routerReducer,
  akunoltReducer,
  regpelangganReducer,
  oltregpelangganReducer,
  setuppelangganReducer,
  pelangganReducer,
  userReducer,
  tagihanpelangganReducer,
  tesakunrouterReducer,
  tesakunoltReducer,
  datateknisReducer,
  pembayaranReducer,
  datacoaReducer,
  transaksiReducer,
  stokbarangReducer,
  paketinternetReducer,
  materialReducer,
  tipebayarReducer,
  rekeningbankReducer,
  tipearuskasReducer,
  pengelolaanbebanReducer,
  keuanganReducer,
  bebantagihanReducer,
  hutangReducer,
  // pengajuanReducer,
  // persetujuanReducer,
  // pencairanReducer,
  pengeluaranReducer,
  // progresReducer,
  // pembelanjaanReducer,
  // penagihanReducer,
  danaReducer,
  pendapatanReducer,
  proyekReducer,
  barangmasukReducer,
  barangkeluarReducer,
  barangreturnReducer,
  barangformresiduReducer,
  prakeluaranReducer,
  satuanprodukReducer,
  lokasiinventoriReducer,
  salesReducer,
  gangguanpelangganReducer,
});

export { initialState, combineReducers, appReducers };
