import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isAuthenticated } from "../api/authenticationApi";

// handle the public routes
function PublicRoute({ component: Component, ...rest }) {
  console.log(isAuthenticated());
  return <Route {...rest} render={(props) => (!isAuthenticated() ? <Component {...props} /> : <Redirect to={{ pathname: "/dashboard" }} />)} />;
}

export default PublicRoute;
