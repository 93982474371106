import axios from 'axios';
import Swal from 'sweetalert2';
import {
  API_URL_getbebantagihan,
  API_URL_createbebantagihan,
  API_URL_edelbebantagihan,
} from '../constants';

export const GET_BEBANTAGIHAN = 'GET_BEBANTAGIHAN';
export const ADD_BEBANTAGIHAN = 'ADD_BEBANTAGIHAN';
export const UPDATE_BEBANTAGIHAN = ' UPDATE_BEBANTAGIHAN';
export const DELETE_BEBANTAGIHAN = ' DELETE_BEBANTAGIHAN';
export const EDIT_BEBANTAGIHAN = 'EDIT_BEBANTAGIHAN';


export const getBebanTagihan = (dispatch, data) => {
  dispatch({
    type: GET_BEBANTAGIHAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_getbebantagihan + data.param,
  })
    .then(response => {
      dispatch({
        type: GET_BEBANTAGIHAN,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_BEBANTAGIHAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const addBebanTagihan = (dispatch, data) => {
  dispatch({
    type: ADD_BEBANTAGIHAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_createbebantagihan,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: ADD_BEBANTAGIHAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_BEBANTAGIHAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const editBebanTagihan = (dispatch, data) => {
  dispatch({
    type: EDIT_BEBANTAGIHAN,
    payload: {
      data: data,
    },
  });
};

export const updateBebanTagihan = (dispatch, data) => {
  dispatch({
    type: UPDATE_BEBANTAGIHAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'PUT',
    url: API_URL_edelbebantagihan + data.pk,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: UPDATE_BEBANTAGIHAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: UPDATE_BEBANTAGIHAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const deleteBebanTagihan = (dispatch, pk) => {
  dispatch({
    type: DELETE_BEBANTAGIHAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#6a82fb',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
  }).then(result => {
    if (result.isConfirmed) {
      axios({
        method: 'DELETE',
        url: API_URL_edelbebantagihan + pk,
        timeout: 120000,
      })
        .then(response => {
          Swal.fire({
            icon: 'success',
            title: 'Good job!',
            text: response.data.messages,
            showConfirmButton: false,
            timer: 1500,
          });
          dispatch({
            type: DELETE_BEBANTAGIHAN,
            payload: {
              loading: false,
              data: response.data,
              errorMessage: false,
            },
          });
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
          });
          dispatch({
            type: DELETE_BEBANTAGIHAN,
            payload: {
              loading: false,
              data: false,
              errorMessage: error.message,
            },
          });
        });
    }
  });
};
