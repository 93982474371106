import axios from 'axios';
import Swal from 'sweetalert2';
import {
  API_URL_getpenagihan,
  API_URL_createpenagihan,
  API_URL_edelpenagihan,
  API_URL_getprogress,
  API_URL_createprogress,
  API_URL_edelprogress,
  API_URL_getpencairan,
  API_URL_createpencairan,
  API_URL_edelpencairan,
  API_URL_getpengajuan,
  API_URL_createpengajuan,
  API_URL_edelpengajuan,
  API_URL_getpersetujuan,
  API_URL_createpersetujuan,
  API_URL_edelpersetujuan,
  API_URL_getpembelanjaan,
  API_URL_createpembelanjaan,
  API_URL_edelpembelanjaan,
} from '../constants';

export const GET_PENAGIHAN = 'GET_PENAGIHAN';
export const ADD_PENAGIHAN = 'ADD_PENAGIHAN';
export const EDIT_PENAGIHAN = 'EDIT_PENAGIHAN';
export const UPDATE_PENAGIHAN = ' UPDATE_PENAGIHAN';
export const DELETE_PENAGIHAN = ' DELETE_PENAGIHAN';

export const GET_PROGRESS = 'GET_PROGRESS';
export const ADD_PROGRESS = 'ADD_PROGRESS';
export const EDIT_PROGRESS = 'EDIT_PROGRESS';
export const UPDATE_PROGRESS = ' UPDATE_PROGRESS';
export const DELETE_PROGRESS = ' DELETE_PROGRESS';

export const GET_PENCAIRAN = 'GET_PENCAIRAN';
export const ADD_PENCAIRAN = 'ADD_PENCAIRAN';
export const EDIT_PENCAIRAN = 'EDIT_PENCAIRAN';
export const UPDATE_PENCAIRAN = ' UPDATE_PENCAIRAN';
export const DELETE_PENCAIRAN = ' DELETE_PENCAIRAN';

export const GET_PENGAJUAN = 'GET_PENGAJUAN';
export const ADD_PENGAJUAN = 'ADD_PENGAJUAN';
export const EDIT_PENGAJUAN = 'EDIT_PENGAJUAN';
export const UPDATE_PENGAJUAN = ' UPDATE_PENGAJUAN';
export const DELETE_PENGAJUAN = ' DELETE_PENGAJUAN';

export const GET_PERSETUJUAN = 'GET_PERSETUJUAN';
export const ADD_PERSETUJUAN = 'ADD_PERSETUJUAN';
export const EDIT_PERSETUJUAN = 'EDIT_PERSETUJUAN';
export const UPDATE_PERSETUJUAN = ' UPDATE_PERSETUJUAN';
export const DELETE_PERSETUJUAN = ' DELETE_PERSETUJUAN';

export const GET_PEMBELANJAAN = 'GET_PEMBELANJAAN';
export const ADD_PEMBELANJAAN = 'ADD_PEMBELANJAAN';
export const EDIT_PEMBELANJAAN = 'EDIT_PEMBELANJAAN';
export const UPDATE_PEMBELANJAAN = ' UPDATE_PEMBELANJAAN';
export const DELETE_PEMBELANJAAN = ' DELETE_PEMBELANJAAN';

export const getPenagihan = (dispatch, data) => {
  dispatch({
    type: GET_PENAGIHAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_getpenagihan + data.param,
  })
    .then(response => {
      dispatch({
        type: GET_PENAGIHAN,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_PENAGIHAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const addPenagihan = (dispatch, data) => {
  dispatch({
    type: ADD_PENAGIHAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_createpenagihan,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: ADD_PENAGIHAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_PENAGIHAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const editPenagihan = (dispatch, data) => {
  dispatch({
    type: EDIT_PENAGIHAN,
    payload: {
      data: data,
    },
  });
};
export const updatePenagihan = (dispatch, data) => {
  dispatch({
    type: UPDATE_PENAGIHAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'PUT',
    url: API_URL_edelpenagihan + data.pk,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: UPDATE_PENAGIHAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: UPDATE_PENAGIHAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const deletePenagihan = (dispatch, pk) => {
  dispatch({
    type: DELETE_PENAGIHAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#6a82fb',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
  }).then(result => {
    if (result.isConfirmed) {
      axios({
        method: 'DELETE',
        url: API_URL_edelpenagihan + pk,
        timeout: 120000,
      })
        .then(response => {
          Swal.fire({
            icon: 'success',
            title: 'Good job!',
            text: response.data.messages,
            showConfirmButton: false,
            timer: 1500,
          });
          dispatch({
            type: DELETE_PENAGIHAN,
            payload: {
              loading: false,
              data: response.data,
              errorMessage: false,
            },
          });
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
          });
          dispatch({
            type: DELETE_PENAGIHAN,
            payload: {
              loading: false,
              data: false,
              errorMessage: error.message,
            },
          });
        });
    }
  });
};

export const getProgress = (dispatch, data) => {
  dispatch({
    type: GET_PROGRESS,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_getprogress + data.param,
  })
    .then(response => {
      dispatch({
        type: GET_PROGRESS,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_PROGRESS,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const addProgress = (dispatch, data) => {
  dispatch({
    type: ADD_PROGRESS,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_createprogress,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: ADD_PROGRESS,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_PROGRESS,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const editProgress = (dispatch, data) => {
  dispatch({
    type: EDIT_PROGRESS,
    payload: {
      data: data,
    },
  });
};
export const updateProgress = (dispatch, data) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'PUT',
    url: API_URL_edelprogress + data.pk,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: UPDATE_PROGRESS,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: UPDATE_PROGRESS,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const deleteProgress = (dispatch, pk) => {
  dispatch({
    type: DELETE_PROGRESS,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#6a82fb',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
  }).then(result => {
    if (result.isConfirmed) {
      axios({
        method: 'DELETE',
        url: API_URL_edelprogress + pk,
        timeout: 120000,
      })
        .then(response => {
          Swal.fire({
            icon: 'success',
            title: 'Good job!',
            text: response.data.messages,
            showConfirmButton: false,
            timer: 1500,
          });
          dispatch({
            type: DELETE_PROGRESS,
            payload: {
              loading: false,
              data: response.data,
              errorMessage: false,
            },
          });
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
          });
          dispatch({
            type: DELETE_PROGRESS,
            payload: {
              loading: false,
              data: false,
              errorMessage: error.message,
            },
          });
        });
    }
  });
};

export const getPencairan = (dispatch, data) => {
  dispatch({
    type: GET_PENCAIRAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_getpencairan + data.param,
  })
    .then(response => {
      dispatch({
        type: GET_PENCAIRAN,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_PENCAIRAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const addPencairan = (dispatch, data) => {
  dispatch({
    type: ADD_PENCAIRAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_createpencairan,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: ADD_PENCAIRAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_PENCAIRAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const editPencairan = (dispatch, data) => {
  dispatch({
    type: EDIT_PENCAIRAN,
    payload: {
      data: data,
    },
  });
};
export const updatePencairan = (dispatch, data) => {
  dispatch({
    type: UPDATE_PENCAIRAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'PUT',
    url: API_URL_edelpencairan + data.pk,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: UPDATE_PENCAIRAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: UPDATE_PENCAIRAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const deletePencairan = (dispatch, pk) => {
  dispatch({
    type: DELETE_PENCAIRAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#6a82fb',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
  }).then(result => {
    if (result.isConfirmed) {
      axios({
        method: 'DELETE',
        url: API_URL_edelpencairan + pk,
        timeout: 120000,
      })
        .then(response => {
          Swal.fire({
            icon: 'success',
            title: 'Good job!',
            text: response.data.messages,
            showConfirmButton: false,
            timer: 1500,
          });
          dispatch({
            type: DELETE_PENCAIRAN,
            payload: {
              loading: false,
              data: response.data,
              errorMessage: false,
            },
          });
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
          });
          dispatch({
            type: DELETE_PENCAIRAN,
            payload: {
              loading: false,
              data: false,
              errorMessage: error.message,
            },
          });
        });
    }
  });
};

export const getPengajuan = (dispatch, data) => {
  dispatch({
    type: GET_PENGAJUAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_getpengajuan + data.param,
  })
    .then(response => {
      dispatch({
        type: GET_PENGAJUAN,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_PENGAJUAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const addPengajuan = (dispatch, data) => {
  dispatch({
    type: ADD_PENGAJUAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_createpengajuan,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: ADD_PENGAJUAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_PENGAJUAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const editPengajuan = (dispatch, data) => {
  dispatch({
    type: EDIT_PENGAJUAN,
    payload: {
      data: data,
    },
  });
};
export const updatePengajuan = (dispatch, data) => {
  dispatch({
    type: UPDATE_PENGAJUAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'PUT',
    url: API_URL_edelpengajuan + data.pk,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: UPDATE_PENGAJUAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: UPDATE_PENGAJUAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const deletePengajuan = (dispatch, pk) => {
  dispatch({
    type: DELETE_PENGAJUAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#6a82fb',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
  }).then(result => {
    if (result.isConfirmed) {
      axios({
        method: 'DELETE',
        url: API_URL_edelpengajuan + pk,
        timeout: 120000,
      })
        .then(response => {
          Swal.fire({
            icon: 'success',
            title: 'Good job!',
            text: response.data.messages,
            showConfirmButton: false,
            timer: 1500,
          });
          dispatch({
            type: DELETE_PENGAJUAN,
            payload: {
              loading: false,
              data: response.data,
              errorMessage: false,
            },
          });
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
          });
          dispatch({
            type: DELETE_PENGAJUAN,
            payload: {
              loading: false,
              data: false,
              errorMessage: error.message,
            },
          });
        });
    }
  });
};

export const getPersetujuan = (dispatch, data) => {
  dispatch({
    type: GET_PERSETUJUAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_getpersetujuan + data.param,
  })
    .then(response => {
      dispatch({
        type: GET_PERSETUJUAN,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_PERSETUJUAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const addPersetujuan = (dispatch, data) => {
  dispatch({
    type: ADD_PERSETUJUAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_createpersetujuan,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: ADD_PERSETUJUAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_PERSETUJUAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const editPersetujuan = (dispatch, data) => {
  dispatch({
    type: EDIT_PERSETUJUAN,
    payload: {
      data: data,
    },
  });
};
export const updatePersetujuan = (dispatch, data) => {
  dispatch({
    type: UPDATE_PERSETUJUAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'PUT',
    url: API_URL_edelpersetujuan + data.pk,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: UPDATE_PERSETUJUAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: UPDATE_PERSETUJUAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const deletePersetujuan = (dispatch, pk) => {
  dispatch({
    type: DELETE_PERSETUJUAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#6a82fb',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
  }).then(result => {
    if (result.isConfirmed) {
      axios({
        method: 'DELETE',
        url: API_URL_edelpersetujuan + pk,
        timeout: 120000,
      })
        .then(response => {
          Swal.fire({
            icon: 'success',
            title: 'Good job!',
            text: response.data.messages,
            showConfirmButton: false,
            timer: 1500,
          });
          dispatch({
            type: DELETE_PERSETUJUAN,
            payload: {
              loading: false,
              data: response.data,
              errorMessage: false,
            },
          });
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
          });
          dispatch({
            type: DELETE_PERSETUJUAN,
            payload: {
              loading: false,
              data: false,
              errorMessage: error.message,
            },
          });
        });
    }
  });
};

export const getPembelanjaan = (dispatch, data) => {
  dispatch({
    type: GET_PEMBELANJAAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_getpembelanjaan + data.param,
  })
    .then(response => {
      dispatch({
        type: GET_PEMBELANJAAN,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_PEMBELANJAAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const addPembelanjaan = (dispatch, data) => {
  dispatch({
    type: ADD_PEMBELANJAAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_createpembelanjaan,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: ADD_PEMBELANJAAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_PEMBELANJAAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const editPembelanjaan = (dispatch, data) => {
  dispatch({
    type: EDIT_PEMBELANJAAN,
    payload: {
      data: data,
    },
  });
};
export const updatePembelanjaan = (dispatch, data) => {
  dispatch({
    type: UPDATE_PEMBELANJAAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'PUT',
    url: API_URL_edelpembelanjaan + data.pk,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: UPDATE_PEMBELANJAAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: UPDATE_PEMBELANJAAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const deletePembelanjaan = (dispatch, pk) => {
  dispatch({
    type: DELETE_PEMBELANJAAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#6a82fb',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
  }).then(result => {
    if (result.isConfirmed) {
      axios({
        method: 'DELETE',
        url: API_URL_edelpembelanjaan + pk,
        timeout: 120000,
      })
        .then(response => {
          Swal.fire({
            icon: 'success',
            title: 'Good job!',
            text: response.data.messages,
            showConfirmButton: false,
            timer: 1500,
          });
          dispatch({
            type: DELETE_PEMBELANJAAN,
            payload: {
              loading: false,
              data: response.data,
              errorMessage: false,
            },
          });
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
          });
          dispatch({
            type: DELETE_PEMBELANJAAN,
            payload: {
              loading: false,
              data: false,
              errorMessage: error.message,
            },
          });
        });
    }
  });
};
