import {
    GET_PRAKELUARAN,
    GET_DPDATPEL,
    GET_COAPRAKELUARAN,
    ADD_PRAKELUARAN,
    ADD_DPDATPEL,
    ADD_COAPRAKELUARAN,
    EDIT_PRAKELUARAN,
    UPDATE_PRAKELUARAN,
    DELETE_PRAKELUARAN,
    DELETE_COAPRAKELUARAN,
  } from '../../pages/actions/prakeluaranAction';
  
  const Prakeluaran = (state, action) => {
    const { type } = action;
    switch (type) {
      case GET_PRAKELUARAN:
        return {
          ...state,
          getPrakeluaranResult: action.payload.data,
          getPrakeluaranLoading: action.payload.loading,
          getPrakeluaranError: action.payload.errorMessage,
        };
      case GET_DPDATPEL:
        return {
          ...state,
          getDpdatpelResult: action.payload.data,
          getDpdatpelLoading: action.payload.loading,
          getDpdatpelError: action.payload.errorMessage,
        };
      case ADD_PRAKELUARAN:
        return {
          ...state,
          addPrakeluaranResult: action.payload.data,
          addPrakeluaranLoading: action.payload.loading,
          addPrakeluaranError: action.payload.errorMessage,
        };
      case ADD_DPDATPEL:
        return {
          ...state,
          addDpdatpelResult: action.payload.data,
          addDpdatpelLoading: action.payload.loading,
          addDpdatpelError: action.payload.errorMessage,
        };
      case EDIT_PRAKELUARAN:
        return {
          ...state,
          editPrakeluaranResult: action.payload.data,
        };
      case UPDATE_PRAKELUARAN:
        return {
          ...state,
          updatePrakeluaranResult: action.payload.data,
          updatePrakeluaranLoading: action.payload.loading,
          updatePrakeluaranError: action.payload.errorMessage,
        };
      case DELETE_PRAKELUARAN:
        return {
          ...state,
          deletePrakeluaranResult: action.payload.data,
          deletePrakeluaranLoading: action.payload.loading,
          deletePrakeluaranError: action.payload.errorMessage,
        };
        case DELETE_COAPRAKELUARAN:
          return {
            ...state,
            deleteCOAPrakeluaranResult: action.payload.data,
            deleteCOAPrakeluaranLoading: action.payload.loading,
            deleteCOAPrakeluaranError: action.payload.errorMessage,
          };
        case GET_COAPRAKELUARAN:
          return {
            ...state,
            getCOAPrakeluaranResult: action.payload.data,
            getCOAPrakeluaranLoading: action.payload.loading,
            getCOAPrakeluaranError: action.payload.errorMessage,
          };
        case ADD_COAPRAKELUARAN:
          return {
            ...state,
            addCOAPrakeluaranResult: action.payload.data,
            addCOAPrakeluaranLoading: action.payload.loading,
            addCOAPrakeluaranError: action.payload.errorMessage,
          };
      default:
        return state;
    }
  };
  
  export default Prakeluaran;
  