import { GET_PEMBAYARAN_PELANGGAN, GET_PEMBAYARAN_PELANGGAN_DETAIL, EDIT_PEMBAYARAN_PELANGGAN, UPDATE_PEMBAYARAN_PELANGGAN, DELETE_PEMBAYARAN_PELANGGAN } from "../../pages/actions/pembayaranAction";
import { GET_PENERIMAAN_KASIR, DETAIL_PENERIMAAN_KASIR, TERIMA_PENERIMAAN_KASIR, PENYERAHAN_KASIR } from "../../pages/actions/penerimaankasirAction";
const pembayaranpelanggan = (state, action) => {
  const { type } = action;
  switch (type) {
    case GET_PEMBAYARAN_PELANGGAN:
      return {
        ...state,
        getpembayaranResult: action.payload.data,
        getpembayaranLoading: action.payload.loading,
        getpembayaranError: action.payload.errorMessage,
      };
    case GET_PEMBAYARAN_PELANGGAN_DETAIL:
      return {
        ...state,
        getpembayarandetailResult: action.payload.data,
        getpembayarandetailLoading: action.payload.loading,
        getpembayarandetailError: action.payload.errorMessage,
      };
    case EDIT_PEMBAYARAN_PELANGGAN:
      return {
        ...state,
        editpembayaranResult: action.payload.data,
      };
    case UPDATE_PEMBAYARAN_PELANGGAN:
      return {
        ...state,
        updatepembayaranResult: action.payload.data,
        updatepembayaranLoading: action.payload.loading,
        updatepembayaranError: action.payload.errorMessage,
      };

    case GET_PENERIMAAN_KASIR:
      return {
        ...state,
        getpenerimaankasirResult: action.payload.data,
        getpenerimaankasirLoading: action.payload.loading,
        getpenerimaankasirError: action.payload.errorMessage,
      };

    case DETAIL_PENERIMAAN_KASIR:
      return {
        ...state,
        detailpenerimaankasirResult: action.payload.data,
        detailpenerimaankasirLoading: action.payload.loading,
        detailpenerimaankasirError: action.payload.errorMessage,
      };
    case TERIMA_PENERIMAAN_KASIR:
      return {
        ...state,
        terimapenerimaankasirResult: action.payload.data,
        terimapenerimaankasirLoading: action.payload.loading,
        terimapenerimaankasirError: action.payload.errorMessage,
      };

    case PENYERAHAN_KASIR:
      return {
        ...state,
        penyerahankasirResult: action.payload.data,
        penyerahankasirLoading: action.payload.loading,
        penyerahankasirError: action.payload.errorMessage,
      };

    case DELETE_PEMBAYARAN_PELANGGAN:
      return {
        ...state,
        deletepembayaranpelangganResult: action.payload.data,
        deletepembayaranpelangganLoading: action.payload.loading,
        deletepembayaranpelangganError: action.payload.errorMessage,
      };

    default:
      return state;
  }
};

export default pembayaranpelanggan;
