import {
    GET_BEBANTAGIHAN,
    DELETE_BEBANTAGIHAN,
    ADD_BEBANTAGIHAN,
    UPDATE_BEBANTAGIHAN,
    EDIT_BEBANTAGIHAN,
  } from '../../pages/actions/bebantagihanAction';
  
  const BebanTagihan = (state, action) => {
    const { type } = action;
    switch (type) {
      case EDIT_BEBANTAGIHAN:
        return {
          ...state,
          editBebanTagihanResult: action.payload.data,
        };
      case UPDATE_BEBANTAGIHAN:
        return {
          ...state,
          updateBebanTagihanResult: action.payload.data,
          updateBebanTagihanLoading: action.payload.loading,
          updateBebanTagihanError: action.payload.errorMessage,
        };
      case ADD_BEBANTAGIHAN:
        return {
          ...state,
          addBebanTagihanResult: action.payload.data,
          addBebanTagihanLoading: action.payload.loading,
          addBebanTagihanError: action.payload.errorMessage,
        };
      case DELETE_BEBANTAGIHAN:
        return {
          ...state,
          deleteBebanTagihanResult: action.payload.data,
          deleteBebanTagihanLoading: action.payload.loading,
          deleteBebanTagihanError: action.payload.errorMessage,
        };
      case GET_BEBANTAGIHAN:
        return {
          ...state,
          getBebanTagihanResult: action.payload.data,
          getBebanTagihanLoading: action.payload.loading,
          getBebanTagihanError: action.payload.errorMessage,
        };
        return state;
    }
  };
  
  export default BebanTagihan;
  