import axios from 'axios';
import Swal from 'sweetalert2';
import {
  API_URL_penerimaankasir,
  API_URL_detailpenerimaankasir,
  API_URL_terimapenerimaankasir,
  API_URL_penyerahankasir,
} from '../constants';

export const GET_PENERIMAAN_KASIR = 'GET_PENERIMAAN_KASIR';
export const DETAIL_PENERIMAAN_KASIR = 'DETAIL_PENERIMAAN_KASIR';
export const TERIMA_PENERIMAAN_KASIR = 'TERIMA_PENERIMAAN_KASIR';
export const PENYERAHAN_KASIR = 'PENYERAHAN_KASIR';

export const getPenerimaanKasir = (dispatch, data) => {
  dispatch({
    type: GET_PENERIMAAN_KASIR,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url:
      API_URL_penerimaankasir + data.periode + '-' + data.status + data.param,
    timeout: 120000,
  })
    .then(response => {
      dispatch({
        type: GET_PENERIMAAN_KASIR,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_PENERIMAAN_KASIR,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const getPenerimaankasirDetail = (dispatch, data) => {
  dispatch({
    type: DETAIL_PENERIMAAN_KASIR,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url:
      API_URL_detailpenerimaankasir +
      data.periode +
      '-' +
      data.penerima +
      '-' +
      data.status +
      data.param,
    timeout: 120000,
  })
    .then(response => {
      dispatch({
        type: DETAIL_PENERIMAAN_KASIR,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: DETAIL_PENERIMAAN_KASIR,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const terimaPenerimaanKasir = (dispatch, data) => {
  dispatch({
    type: TERIMA_PENERIMAAN_KASIR,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'PUT',
    url: API_URL_terimapenerimaankasir + data.pk,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: TERIMA_PENERIMAAN_KASIR,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: TERIMA_PENERIMAAN_KASIR,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const penyerahanKasir = (dispatch, data) => {
  dispatch({
    type: PENYERAHAN_KASIR,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'PUT',
    url: API_URL_penyerahankasir + data.idpenerima.pk,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: PENYERAHAN_KASIR,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: PENYERAHAN_KASIR,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
