import {
    GET_PENGELUARAN,
    DELETE_PENGELUARAN,
    ADD_PENGELUARAN,
    UPDATE_PENGELUARAN,
    EDIT_PENGELUARAN,
  } from '../../pages/actions/pengeluaranAction';
  
  const pengeluaran = (state, action) => {
    const { type } = action;
    switch (type) {
      case EDIT_PENGELUARAN:
        return {
          ...state,
          editPengeluaranResult: action.payload.data,
        };
      case UPDATE_PENGELUARAN:
        return {
          ...state,
          updatePengeluaranResult: action.payload.data,
          updatePengeluaranLoading: action.payload.loading,
          updatePengeluaranError: action.payload.errorMessage,
        };
      case ADD_PENGELUARAN:
        return {
          ...state,
          addPengeluaranResult: action.payload.data,
          addPengeluaranLoading: action.payload.loading,
          addPengeluaranError: action.payload.errorMessage,
        };
      case DELETE_PENGELUARAN:
        return {
          ...state,
          deletePengeluaranResult: action.payload.data,
          deletePengeluaranLoading: action.payload.loading,
          deletePengeluaranError: action.payload.errorMessage,
        };
      case GET_PENGELUARAN:
        return {
          ...state,
          getPengeluaranResult: action.payload.data,
          getPengeluaranLoading: action.payload.loading,
          getPengeluaranError: action.payload.errorMessage,
        };
        return state;
    }
  };
  
  export default pengeluaran;
  