import axios from "axios";
import Swal from "sweetalert2";
import { API_URL_setortunai } from "../constants";
import { API_URL_transfer } from "../constants";
import { API_URL_getrekonsiliasi } from "../constants";
import { API_URL_createrekeningkoran } from "../constants";
import { API_URL_getrekeningkoran } from "../constants";

export const SETOR_TUNAI = "SETOR_TUNAI";
export const TRANSFER = "TRANSFER";
export const GET_REKONSILIASI = "GET_REKONSILIASI";
export const GET_REKENING_KORAN = "GET_REKENING_KORAN";
export const CREATE_REKENING_KORAN = "CREATE_REKENING_KORAN";

export const getRekonsiliasi = (dispatch, data) => {
  dispatch({
    type: GET_REKONSILIASI,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: "GET",
    url: API_URL_getrekonsiliasi + data.param,
  })
    .then((response) => {
      dispatch({
        type: GET_REKONSILIASI,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_REKONSILIASI,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const getRekeningkoran = (dispatch, data) => {
  dispatch({
    type: GET_REKENING_KORAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: "GET",
    url: API_URL_getrekeningkoran + data.param,
  })
    .then((response) => {
      dispatch({
        type: GET_REKENING_KORAN,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_REKENING_KORAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const setorTunai = (dispatch, data) => {
  dispatch({
    type: SETOR_TUNAI,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: "POST",
    url: API_URL_setortunai,
    timeout: 120000,
    data: data,
  })
    .then((response) => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Good job!",
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: SETOR_TUNAI,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error,
      });
      dispatch({
        type: SETOR_TUNAI,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const Transfer = (dispatch, data) => {
  dispatch({
    type: TRANSFER,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: "POST",
    url: API_URL_transfer,
    timeout: 120000,
    data: data,
  })
    .then((response) => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Good job!",
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: TRANSFER,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error,
      });
      dispatch({
        type: TRANSFER,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const addRekeningkoran = (dispatch, data) => {
  dispatch({
    type: CREATE_REKENING_KORAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: "POST",
    url: API_URL_createrekeningkoran,
    timeout: 120000,
    data: data,
  })
    .then((response) => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Good job!",
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: CREATE_REKENING_KORAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error,
      });
      dispatch({
        type: CREATE_REKENING_KORAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
