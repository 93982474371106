import {
  GET_BEBANRUTIN,
  GET_BEBANRUTINFLUKTUATIF,
  GET_PARTIKULAR,
  GET_OPERASIONAL,
  GET_PROYEK,
  GET_LAINNYA,
  ADD_RUTINTETAP,
  ADD_RUTINFLUKTUATIF,
  ADD_PARTIKULAR,
  ADD_OPERASIONAL,
  ADD_PROYEK,
  ADD_LAINNYA,
  UPDATE_RUTINTETAP,
  UPDATE_RUTIN_FLUKTUATIF,
  UPDATE_PARTIKULAR,
  UPDATE_OPERASIONAL,
  UPDATE_PROYEK,
  UPDATE_LAINNYA,
  EDIT_RUTIN_TETAP,
  EDIT_RUTIN_FLUKTUATIF,
  EDIT_PARTIKULAR,
  EDIT_OPERASIONAL,
  EDIT_PROYEK,
  EDIT_LAINNYA,
  ADD_DETAILBEBAN,
  GET_DETAILBEBAN,
  ADD_SETTINGBEBAN,
  GET_SETTINGBEBAN,
  ADD_COABEBAN,
  GET_COABEBAN,
  DELETE_COABEBAN,
  DELETE_BEBAN,
} from '../../pages/actions/pengelolaanbebanAction';

const pengelolaanbebanReducer = (state, action) => {
  const { type } = action;
  switch (type) {
    case DELETE_BEBAN:
      return {
        ...state,
        deleteBebanResult: action.payload.data,
        deleteBebanLoading: action.payload.loading,
        deleteBebanError: action.payload.errorMessage,
      };
    case DELETE_COABEBAN:
      return {
        ...state,
        deleteCOABebanResult: action.payload.data,
        deleteCOABebanLoading: action.payload.loading,
        deleteCOABebanError: action.payload.errorMessage,
      };
    case GET_COABEBAN:
      return {
        ...state,
        getCOABebanResult: action.payload.data,
        getCOABebanLoading: action.payload.loading,
        getCOABebanError: action.payload.errorMessage,
      };
    case ADD_COABEBAN:
      return {
        ...state,
        addCOABebanResult: action.payload.data,
        addCOABebanLoading: action.payload.loading,
        addCOABebanError: action.payload.errorMessage,
      };
    case GET_SETTINGBEBAN:
      return {
        ...state,
        getSettingBebanResult: action.payload.data,
        getSettingBebanLoading: action.payload.loading,
        getSettingBebanError: action.payload.errorMessage,
      };
    case ADD_SETTINGBEBAN:
      return {
        ...state,
        addSettingBebanResult: action.payload.data,
        addSettingBebanLoading: action.payload.loading,
        addSettingBebanError: action.payload.errorMessage,
      };
    case GET_DETAILBEBAN:
      return {
        ...state,
        getDetailBebanResult: action.payload.data,
        getDetailBebanLoading: action.payload.loading,
        getDetailBebanError: action.payload.errorMessage,
      };
    case ADD_DETAILBEBAN:
      return {
        ...state,
        addDetailBebanResult: action.payload.data,
        addDetailBebanLoading: action.payload.loading,
        addDetailBebanError: action.payload.errorMessage,
      };
    case GET_BEBANRUTIN:
      return {
        ...state,
        getbebanrutinResult: action.payload.data,
        getbebanrutinLoading: action.payload.loading,
        getbebanrutinError: action.payload.errorMessage,
      };
    case GET_BEBANRUTINFLUKTUATIF:
      return {
        ...state,
        getrutinfluktuatifResult: action.payload.data,
        getrutinfluktuatifLoading: action.payload.loading,
        getrutinfluktuatifError: action.payload.errorMessage,
      };
    case GET_PARTIKULAR:
      return {
        ...state,
        getpartikularResult: action.payload.data,
        getpartikularLoading: action.payload.loading,
        getpartikularError: action.payload.errorMessage,
      };
    case GET_OPERASIONAL:
      return {
        ...state,
        getOperasionalResult: action.payload.data,
        getOperasionalLoading: action.payload.loading,
        getOperasionalError: action.payload.errorMessage,
      };
    case GET_PROYEK:
      return {
        ...state,
        getProyekResult: action.payload.data,
        getProyekLoading: action.payload.loading,
        getProyekError: action.payload.errorMessage,
      };
    case GET_LAINNYA:
      return {
        ...state,
        getLainnyaResult: action.payload.data,
        getLainnyaLoading: action.payload.loading,
        getLainnyaError: action.payload.errorMessage,
      };
    case ADD_RUTINTETAP:
      return {
        ...state,
        addRutinTetapResult: action.payload.data,
        addRutinTetapLoading: action.payload.loading,
        addRutinTetapError: action.payload.errorMessage,
      };
    case ADD_RUTINFLUKTUATIF:
      return {
        ...state,
        addRutinFluktuatifResult: action.payload.data,
        addRutinFluktuatifLoading: action.payload.loading,
        addRutinFluktuatifError: action.payload.errorMessage,
      };
    case ADD_PARTIKULAR:
      return {
        ...state,
        addPartikularResult: action.payload.data,
        addPartikularLoading: action.payload.loading,
        addPartikularError: action.payload.errorMessage,
      };
    case ADD_OPERASIONAL:
      return {
        ...state,
        addOperasionalResult: action.payload.data,
        addOperasionalLoading: action.payload.loading,
        addOperasionalError: action.payload.errorMessage,
      };
    case ADD_PROYEK:
      return {
        ...state,
        addProyekResult: action.payload.data,
        addProyekLoading: action.payload.loading,
        addProyekError: action.payload.errorMessage,
      };
    case ADD_LAINNYA:
      return {
        ...state,
        addLainnyaResult: action.payload.data,
        addLainnyaLoading: action.payload.loading,
        addLainnyaError: action.payload.errorMessage,
      };
    case EDIT_RUTIN_TETAP:
      return {
        ...state,
        editrutintetapResult: action.payload.data,
      };

    case EDIT_RUTIN_FLUKTUATIF:
      return {
        ...state,
        editrutinfluktuatifResult: action.payload.data,
      };
    case EDIT_PARTIKULAR:
      return {
        ...state,
        editpartikularResult: action.payload.data,
      };
    case EDIT_OPERASIONAL:
      return {
        ...state,
        editOperasionalResult: action.payload.data,
      };
    case EDIT_PROYEK:
      return {
        ...state,
        editProyekResult: action.payload.data,
      };
    case EDIT_LAINNYA:
      return {
        ...state,
        editLainnyaResult: action.payload.data,
      };
    case UPDATE_RUTINTETAP:
      return {
        ...state,
        updateRutinTetapResult: action.payload.data,
        updateRutinTetapLoading: action.payload.loading,
        updateRutinTetapError: action.payload.errorMessage,
      };
    case UPDATE_RUTIN_FLUKTUATIF:
      return {
        ...state,
        updaterutinfluktuatifResult: action.payload.data,
        updaterutinfluktuatifLoading: action.payload.loading,
        updaterutinfluktuatifError: action.payload.errorMessage,
      };
    case UPDATE_PARTIKULAR:
      return {
        ...state,
        updatepartikularResult: action.payload.data,
        updatepartikularLoading: action.payload.loading,
        updatepartikularError: action.payload.errorMessage,
      };
    case UPDATE_OPERASIONAL:
      return {
        ...state,
        updateOperasionalResult: action.payload.data,
        updateOperasionalLoading: action.payload.loading,
        updateOperasionalError: action.payload.errorMessage,
      };
    case UPDATE_PROYEK:
      return {
        ...state,
        updateProyekResult: action.payload.data,
        updateProyekLoading: action.payload.loading,
        updateProyekError: action.payload.errorMessage,
      };
    case UPDATE_LAINNYA:
      return {
        ...state,
        updateLainnyaResult: action.payload.data,
        updateLainnyaLoading: action.payload.loading,
        updateLainnyaError: action.payload.errorMessage,
      };
    default:
      return state;
  }
};

export default pengelolaanbebanReducer;
