import axios from 'axios';
import Swal from 'sweetalert2';
import {
  API_URL_transaksi,
  API_URL_drafttransaksi,
  API_URL_datatransaksidetail,
  API_URL_createtransaksi,
  API_URL_edelregtransaksi,
  API_URL_createjurnal,
  API_URL_deletejurnal,
  API_URL_getdatajurnal,
} from '../constants';

export const ADD_JURNAL = 'ADD_JURNAL';
export const GET_TRANSAKSI = 'GET_TRANSAKSI';
export const GET_DETAIL_TRANSAKSI = 'GET_DETAIL_TRANSAKSI';
export const EDIT_TRANSAKSI = 'EDIT_TRANSAKSI';
export const UPDATE_TRANSAKSI = 'UPDATE_TRANSAKSI';
export const ADD_TRANSAKSI = 'ADD_TRANSAKSI';
export const DELETE_TRANSAKSI = 'DELETE_TRANSAKSI';
export const DELETE_JURNAL = 'DELETE_JURNAL';
export const GET_DATA_JURNAL = 'GET_DATA_JURNAL';
export const GET_EXPORT_EXCEL = 'GET_EXPORT_EXCEL';

export const deleteJurnal = (dispatch, pk) => {
  dispatch({
    type: DELETE_JURNAL,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#6a82fb',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
  }).then(result => {
    if (result.isConfirmed) {
      axios({
        method: 'DELETE',
        url: API_URL_deletejurnal + pk,
        timeout: 120000,
      })
        .then(response => {
          Swal.fire('Deleted!', response.data.messages, 'success');
          dispatch({
            type: DELETE_JURNAL,
            payload: {
              loading: false,
              data: response.data,
              errorMessage: false,
            },
          });
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
          });
          dispatch({
            type: DELETE_JURNAL,
            payload: {
              loading: false,
              data: false,
              errorMessage: error.message,
            },
          });
        });
    }
  });
};

export const addJurnal = (dispatch, data) => {
  dispatch({
    type: ADD_JURNAL,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_createjurnal,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: 'success',
          title: response.data.messages,
        });
        dispatch({
          type: ADD_JURNAL,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.message,
      });
      dispatch({
        type: ADD_JURNAL,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const getDraftTransaksi = (dispatch, data) => {
  dispatch({
    type: GET_TRANSAKSI,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_drafttransaksi + data.param,
    timeout: 120000,
  })
    .then(response => {
      dispatch({
        type: GET_TRANSAKSI,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_TRANSAKSI,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const getTransaksi = (dispatch, data) => {
  dispatch({
    type: GET_TRANSAKSI,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_transaksi + data.param,
    timeout: 120000,
  })
    .then(response => {
      dispatch({
        type: GET_TRANSAKSI,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_TRANSAKSI,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const getDataTransaksi = (dispatch, data) => {
  dispatch({
    type: GET_DETAIL_TRANSAKSI,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_datatransaksidetail + data.id_transaksi,
    timeout: 120000,
  })
    .then(response => {
      dispatch({
        type: GET_DETAIL_TRANSAKSI,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_DETAIL_TRANSAKSI,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const editTransaksi = (dispatch, data) => {
  dispatch({
    type: EDIT_TRANSAKSI,
    payload: {
      data: data,
    },
  });
};

export const updateTransaksi = (dispatch, data, pk) => {
  dispatch({
    type: UPDATE_TRANSAKSI,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'PUT',
    url: API_URL_edelregtransaksi + pk,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: 'success',
          title: response.data.messages,
        });
        dispatch({
          type: UPDATE_TRANSAKSI,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: UPDATE_TRANSAKSI,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const addTransaksi = (dispatch, data) => {
  dispatch({
    type: ADD_TRANSAKSI,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_createtransaksi,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: 'success',
          title: response.data.messages,
        });
        dispatch({
          type: ADD_TRANSAKSI,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_TRANSAKSI,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const deleteTransaksi = (dispatch, pk) => {
  dispatch({
    type: DELETE_TRANSAKSI,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#6a82fb',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
  }).then(result => {
    if (result.isConfirmed) {
      axios({
        method: 'DELETE',
        url: API_URL_edelregtransaksi + pk,
        timeout: 120000,
      })
        .then(response => {
          Swal.fire('Deleted!', response.data.messages, 'success');
          dispatch({
            type: DELETE_TRANSAKSI,
            payload: {
              loading: false,
              data: response.data,
              errorMessage: false,
            },
          });
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
          });
          dispatch({
            type: DELETE_TRANSAKSI,
            payload: {
              loading: false,
              data: false,
              errorMessage: error.message,
            },
          });
        });
    }
  });
};

export const getDataJurnal = (dispatch, data) => {
  dispatch({
    type: GET_DATA_JURNAL,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_getdatajurnal + data.param,
    timeout: 120000,
  })
    .then(response => {
      dispatch({
        type: GET_DATA_JURNAL,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_DATA_JURNAL,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
