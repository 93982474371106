import {
  GET_PENDAPATAN,
  GET_PENDAPATANPELANGGAN,
  ADD_PENDAPATAN,
  EDIT_PENDAPATAN,
  UPDATE_PENDAPATAN,
  DELETE_PENDAPATAN,
} from '../../pages/actions/pendapatanAction';

const Pendapatan = (state, action) => {
  const { type } = action;
  switch (type) {
    case GET_PENDAPATANPELANGGAN:
      return {
        ...state,
        getPendapatanPelangganResult: action.payload.data,
        getPendapatanPelangganLoading: action.payload.loading,
        getPendapatanPelangganError: action.payload.errorMessage,
      };
    case GET_PENDAPATAN:
      return {
        ...state,
        getPendapatanResult: action.payload.data,
        getPendapatanLoading: action.payload.loading,
        getPendapatanError: action.payload.errorMessage,
      };
    case ADD_PENDAPATAN:
      return {
        ...state,
        addPendapatanResult: action.payload.data,
        addPendapatanLoading: action.payload.loading,
        addPendapatanError: action.payload.errorMessage,
      };
    case EDIT_PENDAPATAN:
      return {
        ...state,
        editPendapatanResult: action.payload.data,
      };
    case UPDATE_PENDAPATAN:
      return {
        ...state,
        updatePendapatanResult: action.payload.data,
        updatePendapatanLoading: action.payload.loading,
        updatePendapatanError: action.payload.errorMessage,
      };
    case DELETE_PENDAPATAN:
      return {
        ...state,
        deletePendapatanResult: action.payload.data,
        deletePendapatanLoading: action.payload.loading,
        deletePendapatanError: action.payload.errorMessage,
      };

    default:
      return state;
  }
};

export default Pendapatan;
