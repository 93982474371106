import {
  GET_MATERIAL,
  GET_MATERIALRESIDU,
  ADD_MATERIAL,
  EDIT_MATERIAL,
  UPDATE_MATERIAL,
  // DELETE_STOK_BARANG,
} from '../../pages/actions/materialAction';

const material = (state, action) => {
  const { type } = action;
  switch (type) {
    case GET_MATERIALRESIDU:
      return {
        ...state,
        getMaterialResiduResult: action.payload.data,
        getMaterialResiduLoading: action.payload.loading,
        getMaterialResiduError: action.payload.errorMessage,
      };
    case GET_MATERIAL:
      return {
        ...state,
        getmaterialResult: action.payload.data,
        getmaterialLoading: action.payload.loading,
        getmaterialError: action.payload.errorMessage,
      };
    case ADD_MATERIAL:
      return {
        ...state,
        addmaterialResult: action.payload.data,
        addmaterialLoading: action.payload.loading,
        addmaterialError: action.payload.errorMessage,
      };

    case EDIT_MATERIAL:
      return {
        ...state,
        editmaterialResult: action.payload.data,
      };
    case UPDATE_MATERIAL:
      return {
        ...state,
        updatematerialResult: action.payload.data,
        updatematerialLoading: action.payload.loading,
        updatematerialError: action.payload.errorMessage,
      };
    // case DELETE_STOK_BARANG:
    //   return {
    //     ...state,
    //     deletestokbarangResult: action.payload.data,
    //     deletestokbarangLoading: action.payload.loading,
    //     deletestokbarangError: action.payload.errorMessage,
    //   };

    default:
      return state;
  }
};

export default material;
