import axios from 'axios';
import Swal from 'sweetalert2';
import {
  API_URL_datapaketinternet,
  API_URL_createpaketinternet,
  API_URL_edelpaketinternet,
} from '../constants';

export const GET_PAKET_INTERNET = 'GET_PAKET_INTERNET';
export const ADD_PAKET_INTERNET = 'ADD_PAKET_INTERNET';
export const EDIT_PAKET_INTERNET = 'EDIT_PAKET_INTERNET';
export const UPDATE_PAKET_INTERNET = ' UPDATE_PAKET_INTERNET';
// export const DELETE_STOK_BARANG = ' DELETE_STOK_BARANG';

export const getPaketinternet = (dispatch, data) => {
  dispatch({
    type: GET_PAKET_INTERNET,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_datapaketinternet + data.param,
  })
    .then(response => {
      dispatch({
        type: GET_PAKET_INTERNET,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_PAKET_INTERNET,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const addPaketinternet = (dispatch, data) => {
  dispatch({
    type: ADD_PAKET_INTERNET,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_createpaketinternet,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: ADD_PAKET_INTERNET,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_PAKET_INTERNET,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const editPaketinternet = (dispatch, data) => {
  dispatch({
    type: EDIT_PAKET_INTERNET,
    payload: {
      data: data,
    },
  });
};

export const updatePaketinternet = (dispatch, data) => {
  dispatch({
    type: UPDATE_PAKET_INTERNET,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'PUT',
    url: API_URL_edelpaketinternet + data.pk,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: UPDATE_PAKET_INTERNET,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: UPDATE_PAKET_INTERNET,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

// export const deleteBarang = (dispatch, pk) => {
//   dispatch({
//     type: DELETE_STOK_BARANG,
//     payload: {
//       loading: true,
//       data: false,
//       errorMessage: false,
//     },
//   });
//   Swal.fire({
//     title: 'Are you sure?',
//     text: "You won't be able to revert this!",
//     icon: 'warning',
//     showCancelButton: true,
//     confirmButtonColor: '#6a82fb',
//     cancelButtonColor: '#d33',
//     confirmButtonText: 'Yes, delete it!',
//   }).then(result => {
//     if (result.isConfirmed) {
//       axios({
//         method: 'DELETE',
//         url: API_URL_edelstokbarang + pk,
//         timeout: 120000,
//       })
//         .then(response => {
//           Swal.fire('Deleted!', response.data.messages, 'success');
//           dispatch({
//             type: DELETE_STOK_BARANG,
//             payload: {
//               loading: false,
//               data: response.data,
//               errorMessage: false,
//             },
//           });
//         })
//         .catch(error => {
//           Swal.fire({
//             icon: 'error',
//             title: 'Oops...',
//             text: error,
//           });
//           dispatch({
//             type: DELETE_STOK_BARANG,
//             payload: {
//               loading: false,
//               data: false,
//               errorMessage: error.message,
//             },
//           });
//         });
//     }
//   });
// };
