import axios from 'axios';
import Swal from 'sweetalert2';
import {
  API_URL_getpengeluaran,
  API_URL_createpengeluaran,
  API_URL_edelpengeluaran,
} from '../constants';

export const GET_PENGELUARAN = 'GET_PENGELUARAN';
export const ADD_PENGELUARAN = 'ADD_PENGELUARAN';
export const DELETE_PENGELUARAN = ' DELETE_PENGELUARAN';
export const EDIT_PENGELUARAN = 'EDIT_PENGELUARAN';
export const UPDATE_PENGELUARAN = ' UPDATE_PENGELUARAN';

export const getPengeluaran = (dispatch, data) => {
  dispatch({
    type: GET_PENGELUARAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_getpengeluaran + data.param,
  })
    .then(response => {
      dispatch({
        type: GET_PENGELUARAN,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_PENGELUARAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const addPengeluaran = (dispatch, data) => {
  dispatch({
    type: ADD_PENGELUARAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_createpengeluaran,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: ADD_PENGELUARAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_PENGELUARAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const editPengeluaran = (dispatch, data) => {
  dispatch({
    type: EDIT_PENGELUARAN,
    payload: {
      data: data,
    },
  });
};

export const updatePengeluaran = (dispatch, data) => {
  dispatch({
    type: UPDATE_PENGELUARAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'PUT',
    url: API_URL_edelpengeluaran + data.pk,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: UPDATE_PENGELUARAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: UPDATE_PENGELUARAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const deletePengeluaran = (dispatch, pk) => {
  dispatch({
    type: DELETE_PENGELUARAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#6a82fb',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
  }).then(result => {
    if (result.isConfirmed) {
      axios({
        method: 'DELETE',
        url: API_URL_edelpengeluaran + pk,
        timeout: 120000,
      })
        .then(response => {
          Swal.fire({
            icon: 'success',
            title: 'Good job!',
            text: response.data.messages,
            showConfirmButton: false,
            timer: 1500,
          });
          dispatch({
            type: DELETE_PENGELUARAN,
            payload: {
              loading: false,
              data: response.data,
              errorMessage: false,
            },
          });
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
          });
          dispatch({
            type: DELETE_PENGELUARAN,
            payload: {
              loading: false,
              data: false,
              errorMessage: error.message,
            },
          });
        });
    }
  });
};
