import axios from 'axios';
import Swal from 'sweetalert2';
import {
  API_URL_getrutin,
  API_URL_getrutinfluktuatif,
  API_URL_getpartikular,
  API_URL_getoperasional,
  API_URL_getproyek,
  API_URL_getlainnya,
  API_URL_createrutintetap,
  API_URL_createrutinfluktuatif,
  API_URL_createpartikular,
  API_URL_createoperasional,
  API_URL_createproyek,
  API_URL_createlainnya,
  API_URL_updaterutintetap,
  API_URL_updaterutinfluktuatif,
  API_URL_updatepartikular,
  API_URL_updateoperasional,
  API_URL_edelproyek,
  API_URL_updatelainnya,
  API_URL_createdetailbeban,
  API_URL_getdetailbeban,
  API_URL_createsettingbeban,
  API_URL_getsettingbeban,
  API_URL_createcoabeban,
  API_URL_getcoabeban,
  API_URL_deletecoabeban,
  API_URL_deletebeban,
  API_URL_pilihdk,
  API_URL_getdetailbebanproyek,
  API_URL_createdetailproyek,
} from '../constants';

export const GET_BEBANRUTIN = 'GET_BEBANRUTIN';
export const GET_BEBANRUTINFLUKTUATIF = 'GET_BEBANRUTINFLUKTUATIF';
export const GET_PARTIKULAR = 'GET_PARTIKULAR';
export const GET_OPERASIONAL = 'GET_OPERASIONAL';
export const GET_PROYEK = 'GET_PROYEK';
export const GET_LAINNYA = 'GET_LAINNYA';
export const ADD_PARTIKULAR = 'ADD_PARTIKULAR';
export const ADD_RUTINTETAP = 'ADD_RUTINTETAP';
export const ADD_RUTINFLUKTUATIF = 'ADD_RUTINFLUKTUATIF';
export const ADD_OPERASIONAL = 'ADD_OPERASIONAL';
export const ADD_PROYEK = 'ADD_PROYEK';
export const ADD_LAINNYA = 'ADD_LAINNYA';
export const UPDATE_RUTINTETAP = 'UPDATE_RUTINTETAP';
export const UPDATE_RUTIN_FLUKTUATIF = 'UPDATE_RUTIN_FLUKTUATIF';
export const UPDATE_PARTIKULAR = 'UPDATE_PARTIKULAR';
export const UPDATE_OPERASIONAL = 'UPDATE_OPERASIONAL';
export const UPDATE_PROYEK = 'UPDATE_PROYEK';
export const UPDATE_LAINNYA = 'UPDATE_LAINNYA';
export const EDIT_RUTIN_TETAP = 'EDIT_RUTIN_TETAP';
export const EDIT_RUTIN_FLUKTUATIF = 'EDIT_RUTIN_FLUKTUATIF';
export const EDIT_PARTIKULAR = 'EDIT_PARTIKULAR';
export const EDIT_OPERASIONAL = 'EDIT_OPERASIONAL';
export const EDIT_PROYEK = 'EDIT_PROYEK';
export const EDIT_LAINNYA = 'EDIT_LAINNYA';
export const ADD_DETAILBEBAN = 'ADD_DETAILBEBAN';
export const GET_DETAILBEBAN = 'GET_DETAILBEBAN';
export const GET_SETTINGBEBAN = 'GET_SETTINGBEBAN';
export const GET_COABEBAN = 'GET_COABEBAN';
export const ADD_SETTINGBEBAN = 'ADD_SETTINGBEBAN';
export const ADD_COABEBAN = 'ADD_COABEBAN';
export const DELETE_COABEBAN = 'DELETE_COABEBAN';
export const DELETE_BEBAN = 'DELETE_BEBAN';

export const deleteCOABeban = (dispatch, pk) => {
  dispatch({
    type: DELETE_COABEBAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#6a82fb',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
  }).then(result => {
    if (result.isConfirmed) {
      axios({
        method: 'DELETE',
        url: API_URL_deletecoabeban + pk,
        timeout: 120000,
      })
        .then(response => {
          Swal.fire('Deleted!', response.data.messages, 'success');
          dispatch({
            type: DELETE_COABEBAN,
            payload: {
              loading: false,
              data: response.data,
              errorMessage: false,
            },
          });
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
          });
          dispatch({
            type: DELETE_COABEBAN,
            payload: {
              loading: false,
              data: false,
              errorMessage: error.message,
            },
          });
        });
    }
  });
};

export const addDKBeban = (dispatch, data) => {
  dispatch({
    type: ADD_COABEBAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'PUT',
    url: API_URL_pilihdk + data.pk,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: 'success',
          title: response.data.messages,
        });
        dispatch({
          type: ADD_COABEBAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_COABEBAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const addDetailProyek = (dispatch, data) => {
  dispatch({
    type: ADD_DETAILBEBAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_createdetailproyek,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: 'success',
          title: response.data.messages,
        });
        dispatch({
          type: ADD_DETAILBEBAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_DETAILBEBAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const addCOABeban = (dispatch, data) => {
  dispatch({
    type: ADD_COABEBAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_createcoabeban,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: 'success',
          title: response.data.messages,
        });
        dispatch({
          type: ADD_COABEBAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_COABEBAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const addDetailBeban = (dispatch, data) => {
  dispatch({
    type: ADD_DETAILBEBAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_createdetailbeban,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: 'success',
          title: response.data.messages,
        });
        dispatch({
          type: ADD_DETAILBEBAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_DETAILBEBAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const addSettingBeban = (dispatch, data) => {
  dispatch({
    type: ADD_SETTINGBEBAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_createsettingbeban,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: 'success',
          title: response.data.messages,
        });
        dispatch({
          type: ADD_SETTINGBEBAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_SETTINGBEBAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const getSettingBeban = (dispatch, data) => {
  dispatch({
    type: GET_SETTINGBEBAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_getsettingbeban + data.pk,
  })
    .then(response => {
      dispatch({
        type: GET_SETTINGBEBAN,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_SETTINGBEBAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const getDetailBeban = (dispatch, data) => {
  dispatch({
    type: GET_DETAILBEBAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_getdetailbeban + data.pk,
  })
    .then(response => {
      dispatch({
        type: GET_DETAILBEBAN,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_DETAILBEBAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const getDetailProyek = (dispatch, data) => {
  dispatch({
    type: GET_DETAILBEBAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_getdetailbebanproyek + data.pk,
  })
    .then(response => {
      dispatch({
        type: GET_DETAILBEBAN,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_DETAILBEBAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const getCOABeban = (dispatch, data) => {
  dispatch({
    type: GET_COABEBAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_getcoabeban + data.pk,
  })
    .then(response => {
      dispatch({
        type: GET_COABEBAN,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_COABEBAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const getRutin = (dispatch, data) => {
  dispatch({
    type: GET_BEBANRUTIN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_getrutin + data.param,
  })
    .then(response => {
      dispatch({
        type: GET_BEBANRUTIN,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_BEBANRUTIN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const getRutinFluktuatif = (dispatch, data) => {
  dispatch({
    type: GET_BEBANRUTINFLUKTUATIF,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_getrutinfluktuatif + data.param,
  })
    .then(response => {
      dispatch({
        type: GET_BEBANRUTINFLUKTUATIF,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_BEBANRUTINFLUKTUATIF,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const getOperasional = (dispatch, data) => {
  dispatch({
    type: GET_OPERASIONAL,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_getoperasional + data.param,
  })
    .then(response => {
      dispatch({
        type: GET_OPERASIONAL,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_OPERASIONAL,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const getPartikular = (dispatch, data) => {
  dispatch({
    type: GET_PARTIKULAR,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_getpartikular + data.param,
  })
    .then(response => {
      dispatch({
        type: GET_PARTIKULAR,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_PARTIKULAR,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const getProyek = (dispatch, data) => {
  dispatch({
    type: GET_PROYEK,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_getproyek + data.jenispelanggan + data.param,
  })
    .then(response => {
      dispatch({
        type: GET_PROYEK,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_PROYEK,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const getLainnya = (dispatch, data) => {
  dispatch({
    type: GET_LAINNYA,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_getlainnya + data.param,
  })
    .then(response => {
      dispatch({
        type: GET_LAINNYA,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_LAINNYA,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const addRutinTetap = (dispatch, data) => {
  dispatch({
    type: ADD_RUTINTETAP,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_createrutintetap,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: 'success',
          title: response.data.messages,
        });
        dispatch({
          type: ADD_RUTINTETAP,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_RUTINTETAP,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const addRutinFluktuatif = (dispatch, data) => {
  dispatch({
    type: ADD_RUTINFLUKTUATIF,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_createrutinfluktuatif,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: 'success',
          title: response.data.messages,
        });
        dispatch({
          type: ADD_RUTINFLUKTUATIF,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_RUTINFLUKTUATIF,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const addPartikular = (dispatch, data) => {
  dispatch({
    type: ADD_PARTIKULAR,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_createpartikular,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: 'success',
          title: response.data.messages,
        });
        dispatch({
          type: ADD_PARTIKULAR,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_PARTIKULAR,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const addOperasional = (dispatch, data) => {
  dispatch({
    type: ADD_OPERASIONAL,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_createoperasional,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: 'success',
          title: response.data.messages,
        });
        dispatch({
          type: ADD_OPERASIONAL,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_OPERASIONAL,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const addProyek = (dispatch, data) => {
  dispatch({
    type: ADD_PROYEK,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_createproyek,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: 'success',
          title: response.data.messages,
        });
        dispatch({
          type: ADD_PROYEK,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_PROYEK,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const addLainnya = (dispatch, data) => {
  dispatch({
    type: ADD_LAINNYA,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_createlainnya,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: 'success',
          title: response.data.messages,
        });
        dispatch({
          type: ADD_LAINNYA,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_LAINNYA,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const editRutinTetap = (dispatch, data) => {
  dispatch({
    type: EDIT_RUTIN_TETAP,
    payload: {
      data: data,
    },
  });
};

export const editRutinFluktuatif = (dispatch, data) => {
  dispatch({
    type: EDIT_RUTIN_FLUKTUATIF,
    payload: {
      data: data,
    },
  });
};

export const editdataPartikular = (dispatch, data) => {
  dispatch({
    type: EDIT_PARTIKULAR,
    payload: {
      data: data,
    },
  });
};

export const editDataOperasional = (dispatch, data) => {
  dispatch({
    type: EDIT_OPERASIONAL,
    payload: {
      data: data,
    },
  });
};

export const editDataProyek = (dispatch, data) => {
  dispatch({
    type: EDIT_PROYEK,
    payload: {
      data: data,
    },
  });
};

export const editDataLainnya = (dispatch, data) => {
  dispatch({
    type: EDIT_LAINNYA,
    payload: {
      data: data,
    },
  });
};

export const updateRutinTetap = (dispatch, data) => {
  dispatch({
    type: UPDATE_RUTINTETAP,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'PUT',
    url: API_URL_updaterutintetap + data.pk,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire('Good job!', response.data.messages, 'success');
        dispatch({
          type: UPDATE_RUTINTETAP,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: UPDATE_RUTINTETAP,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const updateRutinFluktuatif = (dispatch, data) => {
  dispatch({
    type: UPDATE_RUTIN_FLUKTUATIF,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'PUT',
    url: API_URL_updaterutinfluktuatif + data.pk,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire('Good job!', response.data.messages, 'success');
        dispatch({
          type: UPDATE_RUTIN_FLUKTUATIF,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: UPDATE_RUTIN_FLUKTUATIF,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const updatePartikular = (dispatch, data) => {
  dispatch({
    type: UPDATE_PARTIKULAR,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'PUT',
    url: API_URL_updatepartikular + data.pk,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire('Good job!', response.data.messages, 'success');
        dispatch({
          type: UPDATE_PARTIKULAR,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: UPDATE_PARTIKULAR,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const updateOperasional = (dispatch, data) => {
  dispatch({
    type: UPDATE_OPERASIONAL,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'PUT',
    url: API_URL_updateoperasional + data.pk,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire('Good job!', response.data.messages, 'success');
        dispatch({
          type: UPDATE_OPERASIONAL,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: UPDATE_OPERASIONAL,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const updateProyek = (dispatch, data) => {
  dispatch({
    type: UPDATE_PROYEK,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'PUT',
    url: API_URL_edelproyek + data.pk,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire('Good job!', response.data.messages, 'success');
        dispatch({
          type: UPDATE_PROYEK,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: UPDATE_PROYEK,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const updateLainnya = (dispatch, data) => {
  dispatch({
    type: UPDATE_LAINNYA,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'PUT',
    url: API_URL_updatelainnya + data.pk,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire('Good job!', response.data.messages, 'success');
        dispatch({
          type: UPDATE_LAINNYA,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: UPDATE_LAINNYA,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const deleteBeban = (dispatch, pk) => {
  dispatch({
    type: DELETE_BEBAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#6a82fb',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
  }).then(result => {
    if (result.isConfirmed) {
      axios({
        method: 'PUT',
        url: API_URL_deletebeban + pk,
        timeout: 120000,
      })
        .then(response => {
          Swal.fire('Deleted!', response.data.messages, 'success');
          dispatch({
            type: DELETE_BEBAN,
            payload: {
              loading: false,
              data: response.data,
              errorMessage: false,
            },
          });
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
          });
          dispatch({
            type: DELETE_BEBAN,
            payload: {
              loading: false,
              data: false,
              errorMessage: error.message,
            },
          });
        });
    }
  });
};

export const deleteBebanProyek = (dispatch, pk) => {
  dispatch({
    type: DELETE_BEBAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#6a82fb',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
  }).then(result => {
    if (result.isConfirmed) {
      axios({
        method: 'PUT',
        url: API_URL_edelproyek + pk,
        timeout: 120000,
      })
        .then(response => {
          Swal.fire('Deleted!', response.data.messages, 'success');
          dispatch({
            type: DELETE_BEBAN,
            payload: {
              loading: false,
              data: response.data,
              errorMessage: false,
            },
          });
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
          });
          dispatch({
            type: DELETE_BEBAN,
            payload: {
              loading: false,
              data: false,
              errorMessage: error.message,
            },
          });
        });
    }
  });
};
