import axios from "axios";
import Swal from "sweetalert2";
import { API_URL_pembayaranpelanggan, API_URL_pembayaranpelanggandetail, API_URL_edelpembayaran } from "../constants";

export const GET_PEMBAYARAN_PELANGGAN = "GET_PEMBAYARAN_PELANGGAN";
export const GET_PEMBAYARAN_PELANGGAN_DETAIL = "GET_PEMBAYARAN_PELANGGAN_DETAIL";
export const EDIT_PEMBAYARAN_PELANGGAN = "EDIT_PEMBAYARAN_PELANGGAN";
export const UPDATE_PEMBAYARAN_PELANGGAN = "UPDATE_PEMBAYARAN_PELANGGAN";
export const DELETE_PEMBAYARAN_PELANGGAN = "DELETE_PEMBAYARAN_PELANGGAN";

export const getPembayaranPelanggan = (dispatch, data) => {
  dispatch({
    type: GET_PEMBAYARAN_PELANGGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: "GET",
    url: API_URL_pembayaranpelanggan + data.filter + "-" + data.tanggalbayar + "-" + data.jenispembayaran + data.param,
    timeout: 120000,
  })
    .then((response) => {
      dispatch({
        type: GET_PEMBAYARAN_PELANGGAN,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_PEMBAYARAN_PELANGGAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const getPembayaranPelangganDetail = (dispatch, data) => {
  dispatch({
    type: GET_PEMBAYARAN_PELANGGAN_DETAIL,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: "GET",
    url: API_URL_pembayaranpelanggandetail + data.filter + data.param,
    timeout: 120000,
  })
    .then((response) => {
      dispatch({
        type: GET_PEMBAYARAN_PELANGGAN_DETAIL,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_PEMBAYARAN_PELANGGAN_DETAIL,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const editPembayaranPelanggan = (dispatch, data) => {
  dispatch({
    type: EDIT_PEMBAYARAN_PELANGGAN,
    payload: {
      data: data,
    },
  });
};

export const updatePembayaranPelanggan = (dispatch, data) => {
  dispatch({
    type: UPDATE_PEMBAYARAN_PELANGGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: "PUT",
    url: API_URL_edelpembayaran + data,
    timeout: 120000,
    data: data,
  })
    .then((response) => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.messages,
        });
      } else {
        Swal.fire("Good job!", response.data.messages, "success");
        dispatch({
          type: UPDATE_PEMBAYARAN_PELANGGAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error,
      });
      dispatch({
        type: UPDATE_PEMBAYARAN_PELANGGAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const deletePembayaran = (dispatch, data) => {
  dispatch({
    type: DELETE_PEMBAYARAN_PELANGGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#6a82fb",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      axios({
        method: "DELETE",
        url: API_URL_edelpembayaran + data,
        timeout: 120000,
      })
        .then((response) => {
          Swal.fire("Deleted!", response.data.messages, "success");
          dispatch({
            type: DELETE_PEMBAYARAN_PELANGGAN,
            payload: {
              loading: false,
              data: response.data,
              errorMessage: false,
            },
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error,
          });
          dispatch({
            type: DELETE_PEMBAYARAN_PELANGGAN,
            payload: {
              loading: false,
              data: false,
              errorMessage: error.message,
            },
          });
        });
    }
  });
};
