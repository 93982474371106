import {
  GET_FORMRESIDU,
  DELETE_FORMRESIDU,
  ADD_FORMRESIDU,
  UPDATE_FORMRESIDU,
  EDIT_FORMRESIDU,
} from '../../pages/actions/barangformresiduAction';

const FormResidu = (state, action) => {
  const { type } = action;
  switch (type) {
    case EDIT_FORMRESIDU:
      return {
        ...state,
        editFormResiduResult: action.payload.data,
      };
    case UPDATE_FORMRESIDU:
      return {
        ...state,
        updateFormResiduResult: action.payload.data,
        updateFormResiduLoading: action.payload.loading,
        updateFormResiduError: action.payload.errorMessage,
      };
    case ADD_FORMRESIDU:
      return {
        ...state,
        addFormResiduResult: action.payload.data,
        addFormResiduLoading: action.payload.loading,
        addFormResiduError: action.payload.errorMessage,
      };
    case DELETE_FORMRESIDU:
      return {
        ...state,
        deleteFormResiduResult: action.payload.data,
        deleteFormResiduLoading: action.payload.loading,
        deleteFormResiduError: action.payload.errorMessage,
      };
    case GET_FORMRESIDU:
      return {
        ...state,
        getFormResiduResult: action.payload.data,
        getFormResiduLoading: action.payload.loading,
        getFormResiduError: action.payload.errorMessage,
      };
      return state;
  }
};

export default FormResidu;
