import axios from "axios";
import Swal from "sweetalert2";
import { API_URL_bayartagihanpelanggan, API_URL_createtagihan, API_URL_tagihanpelanggan, API_URL_edeltagihanpelanggan, API_URL_getsudahbayartagpel, API_URL_settingakun, API_URL_piutang, API_URL_addtagihan } from "../constants";

export const GET_TAGIHAN_PELANGGAN = "GET_TAGIHAN_PELANGGAN";
export const CREATE_TAGIHAN_PELANGGAN = "CREATE_TAGIHAN_PELANGGAN";
export const EDIT_TAGIHAN_PELANGGAN = "EDIT_TAGIHAN_PELANGGAN";
export const UPDATE_TAGIHAN_PELANGGAN = "UPDATE_TAGIHAN_PELANGGAN";
export const DELETE_TAGIHAN_PELANGGAN = "DELETE_TAGIHAN_PELANGGAN";
export const BAYAR_TAGIHAN_PELANGGAN = "BAYAR_TAGIHAN_PELANGGAN";
export const BAYAR_TAGIHAN_DETAIL = "BAYAR_TAGIHAN_DETAIL";
export const GETBAYARTAGPEL_TAGIHAN_PELANGGAN = "GETBAYARTAGPEL_TAGIHAN_PELANGGAN";
export const SETTING_AKUN = "SETTING_AKUN";
export const GET_PIUTANG_PELANGGAN = "GET_PIUTANG_PELANGGAN";
export const ADD_TAGIHAN = "ADD_TAGIHAN";

export const settingAkun = (dispatch, data) => {
  dispatch({
    type: SETTING_AKUN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: "POST",
    url: API_URL_settingakun,
    timeout: 120000,
    data: data,
  })
    .then((response) => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.messages,
        });
      } else {
        Swal.fire("Good job!", response.data.messages, "success");
        dispatch({
          type: SETTING_AKUN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error,
      });
      dispatch({
        type: SETTING_AKUN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const getTagihanPelanggan = (dispatch, data) => {
  dispatch({
    type: GET_TAGIHAN_PELANGGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: "GET",
    url: API_URL_tagihanpelanggan + data.filter + "-" + data.status + "-" + data.jenistagihan + data.param,
  })
    .then((response) => {
      dispatch({
        type: GET_TAGIHAN_PELANGGAN,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_TAGIHAN_PELANGGAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const getPiutangPelanggan = (dispatch, data) => {
  dispatch({
    type: GET_PIUTANG_PELANGGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: "GET",
    url: API_URL_piutang + data.filter + data.param,
  })
    .then((response) => {
      dispatch({
        type: GET_PIUTANG_PELANGGAN,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_PIUTANG_PELANGGAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const createTagihanPelanggan = (dispatch) => {
  dispatch({
    type: CREATE_TAGIHAN_PELANGGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: "POST",
    url: API_URL_createtagihan,
    timeout: 120000,
  })
    .then((response) => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.messages,
        });
      } else {
        Swal.fire("Good job!", response.data.messages, "success");
        dispatch({
          type: CREATE_TAGIHAN_PELANGGAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error,
      });
      dispatch({
        type: CREATE_TAGIHAN_PELANGGAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const addTagihan = (dispatch, data) => {
  dispatch({
    type: ADD_TAGIHAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: "POST",
    url: API_URL_addtagihan,
    timeout: 120000,
    data: data,
  })
    .then((response) => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.messages,
        });
      } else {
        Swal.fire("Good job!", response.data.messages, "success");
        dispatch({
          type: ADD_TAGIHAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error,
      });
      dispatch({
        type: ADD_TAGIHAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const editTagihanPelanggan = (dispatch, data) => {
  dispatch({
    type: EDIT_TAGIHAN_PELANGGAN,
    payload: {
      data: data,
    },
  });
};

export const updateTagihanPelanggan = (dispatch, data) => {
  dispatch({
    type: UPDATE_TAGIHAN_PELANGGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: "PUT",
    url: API_URL_edeltagihanpelanggan + data.pk,
    timeout: 120000,
    data: data,
  })
    .then((response) => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.messages,
        });
      } else {
        Swal.fire("Good job!", response.data.messages, "success");
        dispatch({
          type: UPDATE_TAGIHAN_PELANGGAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error,
      });
      dispatch({
        type: UPDATE_TAGIHAN_PELANGGAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const deleteTagihan = (dispatch, data) => {
  dispatch({
    type: DELETE_TAGIHAN_PELANGGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#6a82fb",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      axios({
        method: "DELETE",
        url: API_URL_edeltagihanpelanggan + data,
        timeout: 120000,
      })
        .then((response) => {
          Swal.fire("Deleted!", response.data.messages, "success");
          dispatch({
            type: DELETE_TAGIHAN_PELANGGAN,
            payload: {
              loading: false,
              data: response.data,
              errorMessage: false,
            },
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error,
          });
          dispatch({
            type: DELETE_TAGIHAN_PELANGGAN,
            payload: {
              loading: false,
              data: false,
              errorMessage: error.message,
            },
          });
        });
    }
  });
};

export const bayaranTagihanDetail = (dispatch, data) => {
  dispatch({
    type: BAYAR_TAGIHAN_DETAIL,
    payload: {
      data: data,
    },
  });
};

export const getSudahBayarTagpel = (dispatch) => {
  dispatch({
    type: GETBAYARTAGPEL_TAGIHAN_PELANGGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: "GET",
    url: API_URL_getsudahbayartagpel,
  })
    .then((response) => {
      dispatch({
        type: GETBAYARTAGPEL_TAGIHAN_PELANGGAN,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: GETBAYARTAGPEL_TAGIHAN_PELANGGAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const BayarTaggihanpelanggan = (dispatch,pk, data) => {
  dispatch({
    type: BAYAR_TAGIHAN_PELANGGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: "PUT",
    url: API_URL_bayartagihanpelanggan + pk,
    timeout: 120000,
    data: data,
  })
    .then((response) => {
      if (response.data.status == 100) {
        Swal.fire({
          icon: "info",
          title: "Oops...",
          text: response.data.messages,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        dispatch({
          type: BAYAR_TAGIHAN_PELANGGAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error,
      });
      dispatch({
        type: BAYAR_TAGIHAN_PELANGGAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
