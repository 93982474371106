import {
  GET_USULAN_PROYEK,
  ADD_USULAN_PROYEK,
  GET_PROYEK_RILIS,
  ADD_PROYEK_RILIS,
  EDIT_PROYEK,
  UPDATE_USULAN_PROYEK,
  DELETE_TIPE_ARUS_KAS,
  MANAJEMEN_PROYEK_TAB,
  GET_OBJEKTIF_PROYEK,
  ADD_OBJEKTIF_PROYEK,
  UPDATE_OBJEKTIF_PROYEK,
  ADD_RESIKO_PROYEK,
  DELETE_RESIKO_PROYEK,
  GET_BUDGET_PROYEK,
  DELETE_BUDGET_PROYEK,
  ADD_BUDGET_PROYEK,
  ADD_DAYADUKUNG_PROYEK,
  DELETE_DAYADUKUNG_PROYEK,
  GET_SOW_PROYEK,
  ADD_SOW_PROYEK,
  UPDATE_SOW_PROYEK,
  ADD_TEAMPELAKSANA_PROYEK,
  DELETE_TEAMPELAKSANA_PROYEK,
  ADD_WAKTUPELAKSANA_PROYEK,
  DELETE_WAKTUPELAKSANA_PROYEK,
  ADD_PENGIRIMAN_PROYEK,
  DELETE_PENGIRIMAN_PROYEK,
  ADD_KEGIATAN_PROYEK,
  DELETE_KEGIATAN_PROYEK,
  GET_DATA_PENGADAAN,
} from '../../pages/actions/proyekAction';

const Proyek = (state, action) => {
  const { type } = action;
  switch (type) {
    case GET_DATA_PENGADAAN:
      return {
        ...state,
        getDataPengadaanResult: action.payload.data,
        getDataPengadaanLoading: action.payload.loading,
        getDataPengadaanError: action.payload.errorMessage,
      };
    case DELETE_KEGIATAN_PROYEK:
      return {
        ...state,
        deleteKegiatanProyekDetailResult: action.payload.data,
        deleteKegiatanProyekDetailLoading: action.payload.loading,
        deleteKegiatanProyekDetailError: action.payload.errorMessage,
      };
    case ADD_KEGIATAN_PROYEK:
      return {
        ...state,
        addKegiatanProyekDetailResult: action.payload.data,
        addKegiatanProyekDetailLoading: action.payload.loading,
        addKegiatanProyekDetailError: action.payload.errorMessage,
      };
    case DELETE_PENGIRIMAN_PROYEK:
      return {
        ...state,
        deletePengirimanProyekDetailResult: action.payload.data,
        deletePengirimanProyekDetailLoading: action.payload.loading,
        deletePengirimanProyekDetailError: action.payload.errorMessage,
      };
    case ADD_PENGIRIMAN_PROYEK:
      return {
        ...state,
        addPengirimanProyekDetailResult: action.payload.data,
        addPengirimanProyekDetailLoading: action.payload.loading,
        addPengirimanProyekDetailError: action.payload.errorMessage,
      };
    case DELETE_WAKTUPELAKSANA_PROYEK:
      return {
        ...state,
        deleteWaktuPelaksanaProyekDetailResult: action.payload.data,
        deleteWaktuPelaksanaProyekDetailLoading: action.payload.loading,
        deleteWaktuPelaksanaProyekDetailError: action.payload.errorMessage,
      };
    case ADD_WAKTUPELAKSANA_PROYEK:
      return {
        ...state,
        addWaktuPelaksanaProyekDetailResult: action.payload.data,
        addWaktuPelaksanaProyekDetailLoading: action.payload.loading,
        addWaktuPelaksanaProyekDetailError: action.payload.errorMessage,
      };
    case DELETE_TEAMPELAKSANA_PROYEK:
      return {
        ...state,
        deleteTeamPelaksanaProyekDetailResult: action.payload.data,
        deleteTeamPelaksanaProyekDetailLoading: action.payload.loading,
        deleteTeamPelaksanaProyekDetailError: action.payload.errorMessage,
      };
    case ADD_TEAMPELAKSANA_PROYEK:
      return {
        ...state,
        addTeamPelaksanaProyekDetailResult: action.payload.data,
        addTeamPelaksanaProyekDetailLoading: action.payload.loading,
        addTeamPelaksanaProyekDetailError: action.payload.errorMessage,
      };
    case UPDATE_SOW_PROYEK:
      return {
        ...state,
        updateSOWProyekDetailResult: action.payload.data,
        updateSOWProyekDetailLoading: action.payload.loading,
        updateSOWProyekDetailError: action.payload.errorMessage,
      };
    case ADD_SOW_PROYEK:
      return {
        ...state,
        addSOWProyekDetailResult: action.payload.data,
        addSOWProyekDetailLoading: action.payload.loading,
        addSOWProyekDetailError: action.payload.errorMessage,
      };
    case GET_SOW_PROYEK:
      return {
        ...state,
        getSOWProyekDetailResult: action.payload.data,
        getSOWProyekDetailLoading: action.payload.loading,
        getSOWProyekDetailError: action.payload.errorMessage,
      };
    case DELETE_DAYADUKUNG_PROYEK:
      return {
        ...state,
        deleteDayaDukungProyekDetailResult: action.payload.data,
        deleteDayaDukungProyekDetailLoading: action.payload.loading,
        deleteDayaDukungProyekDetailError: action.payload.errorMessage,
      };
    case ADD_DAYADUKUNG_PROYEK:
      return {
        ...state,
        addDayaDukungProyekDetailResult: action.payload.data,
        addDayaDukungProyekDetailLoading: action.payload.loading,
        addDayaDukungProyekDetailError: action.payload.errorMessage,
      };
    case ADD_BUDGET_PROYEK:
      return {
        ...state,
        addBudgetProyekDetailResult: action.payload.data,
        addBudgetProyekDetailLoading: action.payload.loading,
        addBudgetProyekDetailError: action.payload.errorMessage,
      };
    case DELETE_BUDGET_PROYEK:
      return {
        ...state,
        deleteBudgetProyekDetailResult: action.payload.data,
        deleteBudgetProyekDetailLoading: action.payload.loading,
        deleteBudgetProyekDetailError: action.payload.errorMessage,
      };
    case GET_BUDGET_PROYEK:
      return {
        ...state,
        getBudgetProyekDetailResult: action.payload.data,
        getBudgetProyekDetailLoading: action.payload.loading,
        getBudgetProyekDetailError: action.payload.errorMessage,
      };
    case DELETE_RESIKO_PROYEK:
      return {
        ...state,
        deleteResikoProyekDetailResult: action.payload.data,
        deleteResikoProyekDetailLoading: action.payload.loading,
        deleteResikoProyekDetailError: action.payload.errorMessage,
      };
    case ADD_RESIKO_PROYEK:
      return {
        ...state,
        addResikoProyekDetailResult: action.payload.data,
        addResikoProyekDetailLoading: action.payload.loading,
        addResikoProyekDetailError: action.payload.errorMessage,
      };
    case UPDATE_OBJEKTIF_PROYEK:
      return {
        ...state,
        updateObjektifProyekDetailResult: action.payload.data,
        updateObjektifProyekDetailLoading: action.payload.loading,
        updateObjektifProyekDetailError: action.payload.errorMessage,
      };
    case ADD_OBJEKTIF_PROYEK:
      return {
        ...state,
        addObjektifProyekDetailResult: action.payload.data,
        addObjektifProyekDetailLoading: action.payload.loading,
        addObjektifProyekDetailError: action.payload.errorMessage,
      };
    case GET_OBJEKTIF_PROYEK:
      return {
        ...state,
        getObjektifProyekDetailResult: action.payload.data,
        getObjektifProyekDetailLoading: action.payload.loading,
        getObjektifProyekDetailError: action.payload.errorMessage,
      };
    case MANAJEMEN_PROYEK_TAB:
      return {
        ...state,
        manajemenProyekTabResult: action.payload.data,
      };
    case GET_PROYEK_RILIS:
      return {
        ...state,
        getProyekRilisResult: action.payload.data,
        getProyekRilisLoading: action.payload.loading,
        getProyekRilisError: action.payload.errorMessage,
      };
    case ADD_PROYEK_RILIS:
      return {
        ...state,
        addProyekRilisResult: action.payload.data,
        addProyekRilisLoading: action.payload.loading,
        addProyekRilisError: action.payload.errorMessage,
      };
    case GET_USULAN_PROYEK:
      return {
        ...state,
        getUsulanProyekResult: action.payload.data,
        getUsulanProyekLoading: action.payload.loading,
        getUsulanProyekError: action.payload.errorMessage,
      };
    case ADD_USULAN_PROYEK:
      return {
        ...state,
        addUsulanProyekResult: action.payload.data,
        addUsulanProyekLoading: action.payload.loading,
        addUsulanProyekError: action.payload.errorMessage,
      };
    case EDIT_PROYEK:
      return {
        ...state,
        editProyekResult: action.payload.data,
      };
    case UPDATE_USULAN_PROYEK:
      return {
        ...state,
        updateUsulanProyekResult: action.payload.data,
        updateUsulanProyekLoading: action.payload.loading,
        updateUsulanProyekError: action.payload.errorMessage,
      };
    case DELETE_TIPE_ARUS_KAS:
      return {
        ...state,
        deleteTipearuskasResult: action.payload.data,
        deleteTipearuskasLoading: action.payload.loading,
        deleteTipearuskasError: action.payload.errorMessage,
      };
    default:
      return state;
  }
};

export default Proyek;
