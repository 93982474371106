import {
    GET_BARANGKELUAR,
    DELETE_BARANGKELUAR,
    ADD_BARANGKELUAR,
    UPDATE_BARANGKELUAR,
    EDIT_BARANGKELUAR,
  } from '../../pages/actions/barangkeluarAction';
  
  const barangkeluar = (state, action) => {
    const { type } = action;
    switch (type) {
      case EDIT_BARANGKELUAR:
        return {
          ...state,
          editBarangkeluarResult: action.payload.data,
        };
      case UPDATE_BARANGKELUAR:
        return {
          ...state,
          updateBarangkeluarResult: action.payload.data,
          updateBarangkeluarLoading: action.payload.loading,
          updateBarangkeluarError: action.payload.errorMessage,
        };
      case ADD_BARANGKELUAR:
        return {
          ...state,
          addBarangkeluarResult: action.payload.data,
          addBarangkeluarLoading: action.payload.loading,
          addBarangkeluarError: action.payload.errorMessage,
        };
      case DELETE_BARANGKELUAR:
        return {
          ...state,
          deleteBarangkeluarResult: action.payload.data,
          deleteBarangkeluarLoading: action.payload.loading,
          deleteBarangkeluarError: action.payload.errorMessage,
        };
      case GET_BARANGKELUAR:
        return {
          ...state,
          getBarangkeluarResult: action.payload.data,
          getBarangkeluarLoading: action.payload.loading,
          getBarangkeluarError: action.payload.errorMessage,
        };
        return state;
    }
  };
  
  export default barangkeluar;
  