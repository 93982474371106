import {
  GET_SALES,
  ADD_SALES,
  EDIT_SALES,
  UPDATE_SALES,
  DELETE_SALES,
} from '../../pages/actions/salesAction';

const Sales = (state, action) => {
  const { type } = action;
  switch (type) {
    case GET_SALES:
      return {
        ...state,
        getSalesResult: action.payload.data,
        getSalesLoading: action.payload.loading,
        getSalesError: action.payload.errorMessage,
      };

    case ADD_SALES:
      return {
        ...state,
        addSalesResult: action.payload.data,
        addSalesLoading: action.payload.loading,
        addSalesError: action.payload.errorMessage,
      };
    case EDIT_SALES:
      return {
        ...state,
        editSalesResult: action.payload.data,
      };
    case UPDATE_SALES:
      return {
        ...state,
        updateSalesResult: action.payload.data,
        updateSalesLoading: action.payload.loading,
        updateSalesError: action.payload.errorMessage,
      };
    case DELETE_SALES:
      return {
        ...state,
        deleteSalesResult: action.payload.data,
        deleteSalesLoading: action.payload.loading,
        deleteSalesError: action.payload.errorMessage,
      };

    default:
      return state;
  }
};

export default Sales;
