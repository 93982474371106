import {
  GET_TIPE_BAYAR,
  ADD_TIPE_BAYAR,
  EDIT_TIPE_BAYAR,
  UPDATE_TIPE_BAYAR,
  DELETE_TIPE_BAYAR
} from '../../pages/actions/tipebayarAction';

const Tipebayar = (state, action) => {
  const { type } = action;
  switch (type) {
    case GET_TIPE_BAYAR:
      return {
        ...state,
        getTipebayarResult: action.payload.data,
        getTipebayarLoading: action.payload.loading,
        getTipebayarError: action.payload.errorMessage,
      };

    case ADD_TIPE_BAYAR:
      return {
        ...state,
        addTipebayarResult: action.payload.data,
        addTipebayarLoading: action.payload.loading,
        addTipebayarError: action.payload.errorMessage,
      };
    case EDIT_TIPE_BAYAR:
      return {
        ...state,
        editTipebayarResult: action.payload.data,
      };
    case UPDATE_TIPE_BAYAR:
      return {
        ...state,
        updateTipebayarResult: action.payload.data,
        updateTipebayarLoading: action.payload.loading,
        updateTipebayarError: action.payload.errorMessage,
      };
    case DELETE_TIPE_BAYAR:
      return {
        ...state,
        deleteTipebayarResult: action.payload.data,
        deleteTipebayarLoading: action.payload.loading,
        deleteTipebayarError: action.payload.errorMessage,
      };

    default:
      return state;
  }
};

export default Tipebayar;
