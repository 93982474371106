import { SETOR_TUNAI, TRANSFER, GET_REKONSILIASI, CREATE_REKENING_KORAN, GET_REKENING_KORAN } from "../../pages/actions/keuanganAction";

const keuangan = (state, action) => {
  const { type } = action;
  switch (type) {
    case SETOR_TUNAI:
      return {
        ...state,
        setortunaiResult: action.payload.data,
        setortunaiLoading: action.payload.loading,
        setortunaiError: action.payload.errorMessage,
      };
    case TRANSFER:
      return {
        ...state,
        transferResult: action.payload.data,
        transferLoading: action.payload.loading,
        transferError: action.payload.errorMessage,
      };
    case GET_REKONSILIASI:
      return {
        ...state,
        getrekonsiliasiResult: action.payload.data,
        getrekonsiliasiLoading: action.payload.loading,
        getrekonsiliasiError: action.payload.errorMessage,
      };
    case GET_REKENING_KORAN:
      return {
        ...state,
        getrekeningkoranResult: action.payload.data,
        getrekeningkoranLoading: action.payload.loading,
        getrekeningkoranError: action.payload.errorMessage,
      };
    case CREATE_REKENING_KORAN:
      return {
        ...state,
        addrekeningkoranResult: action.payload.data,
        addrekeningkoranLoading: action.payload.loading,
        addrekeningkoranError: action.payload.errorMessage,
      };

    default:
      return state;
  }
};

export default keuangan;
