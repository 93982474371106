import axios from 'axios';
import Swal from 'sweetalert2';
import {
  API_URL_datacariakun,
  API_URL_dataakun,
  API_URL_createakun,
  API_URL_updelakun,
  API_URL_getakunbukubesar,
  API_URL_dataakunbukbes,
  API_URL_createakunbukubesar,
  API_URL_updelakunbukubesar,
  API_URL_getkelompok,
  API_URL_datakelompok,
  API_URL_createkelompok,
  API_URL_updelkelompok,
  API_URL_getgolongan,
  API_URL_datagolongan,
  API_URL_creategolongan,
  API_URL_updelgolongan,
  API_URL_getsubgolongan,
  API_URL_datasubgolongan,
  API_URL_createsubgolongan,
  API_URL_updelsubgolongan,
} from '../constants';

export const GET_AKUNBUKUBESAR = 'GET_AKUNBUKUBESAR';
export const ADD_AKUNBUKUBESAR = 'ADD_AKUNBUKUBESAR';
export const EDIT_AKUNBUKUBESAR = 'EDIT_AKUNBUKUBESAR';
export const UPDATE_AKUNBUKUBESAR = 'UPDATE_AKUNBUKUBESAR';
export const DELETE_AKUNBUKUBESAR = 'DELETE_AKUNBUKUBESAR';
export const GET_AKUN = 'GET_AKUN';
export const ADD_AKUN = 'ADD_AKUN';
export const EDIT_AKUN = 'EDIT_AKUN';
export const UPDATE_AKUN = 'UPDATE_AKUN';
export const DELETE_AKUN = 'DELETE_AKUN';
export const GET_KELOMPOK = 'GET_KELOMPOK';
export const ADD_KELOMPOK = 'ADD_KELOMPOK';
export const EDIT_KELOMPOK = 'EDIT_KELOMPOK';
export const UPDATE_KELOMPOK = 'UPDATE_KELOMPOK';
export const DELETE_KELOMPOK = 'DELETE_KELOMPOK';
export const GET_GOLONGAN = 'GET_GOLONGAN';
export const ADD_GOLONGAN = 'ADD_GOLONGAN';
export const EDIT_GOLONGAN = 'EDIT_GOLONGAN';
export const UPDATE_GOLONGAN = 'UPDATE_GOLONGAN';
export const DELETE_GOLONGAN = 'DELETE_GOLONGAN';
export const GET_SUBGOLONGAN = 'GET_SUBGOLONGAN';
export const ADD_SUBGOLONGAN = 'ADD_SUBGOLONGAN';
export const EDIT_SUBGOLONGAN = 'EDIT_SUBGOLONGAN';
export const UPDATE_SUBGOLONGAN = 'UPDATE_SUBGOLONGAN';
export const DELETE_SUBGOLONGAN = 'DELETE_SUBGOLONGAN';

// ============================================ Akun ====================================
export const getCariAkun = (dispatch, data) => {
  dispatch({
    type: GET_AKUN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_datacariakun + data.param,
  })
    .then(response => {
      dispatch({
        type: GET_AKUN,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_AKUN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const getAkun = (dispatch, data) => {
  dispatch({
    type: GET_AKUN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_dataakun + data.param,
  })
    .then(response => {
      dispatch({
        type: GET_AKUN,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_AKUN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const addAkun = (dispatch, data) => {
  dispatch({
    type: ADD_AKUN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_createakun,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: 'success',
          title: response.data.messages,
        });
        dispatch({
          type: ADD_AKUN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_AKUN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const editAkun = (dispatch, data) => {
  dispatch({
    type: EDIT_AKUN,
    payload: {
      data: data,
    },
  });
};
export const updateAkun = (dispatch, data) => {
  dispatch({
    type: UPDATE_AKUN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'PUT',
    url: API_URL_updelakun + data.pk,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire('Good job!', response.data.messages, 'success');
        dispatch({
          type: UPDATE_AKUN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: UPDATE_AKUN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const deleteAkun = (dispatch, pk) => {
  dispatch({
    type: DELETE_AKUN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#6a82fb',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
  }).then(result => {
    if (result.isConfirmed) {
      axios({
        method: 'DELETE',
        url: API_URL_updelakun + pk,
        timeout: 120000,
      })
        .then(response => {
          Swal.fire('Deleted!', response.data.messages, 'success');
          dispatch({
            type: DELETE_AKUN,
            payload: {
              loading: false,
              data: response.data,
              errorMessage: false,
            },
          });
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
          });
          dispatch({
            type: DELETE_AKUN,
            payload: {
              loading: false,
              data: false,
              errorMessage: error.message,
            },
          });
        });
    }
  });
};
// ============================================ Akun Buku Besar ====================================
export const getAkunBukuBesar = (dispatch, data) => {
  dispatch({
    type: GET_AKUNBUKUBESAR,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_dataakunbukbes + data.param,
  })
    .then(response => {
      dispatch({
        type: GET_AKUNBUKUBESAR,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_AKUNBUKUBESAR,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const addAkunbukubesar = (dispatch, data) => {
  dispatch({
    type: ADD_AKUNBUKUBESAR,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_createakunbukubesar,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: 'success',
          title: response.data.messages,
        });
        dispatch({
          type: ADD_AKUNBUKUBESAR,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_AKUNBUKUBESAR,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const editAkunbukubesar = (dispatch, data) => {
  dispatch({
    type: EDIT_AKUNBUKUBESAR,
    payload: {
      data: data,
    },
  });
};
export const updateAkunbukubesar = (dispatch, data) => {
  dispatch({
    type: UPDATE_AKUNBUKUBESAR,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'PUT',
    url: API_URL_updelakunbukubesar + data.pk,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire('Good job!', response.data.messages, 'success');
        dispatch({
          type: UPDATE_AKUNBUKUBESAR,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: UPDATE_AKUNBUKUBESAR,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const deleteAkunbukubesar = (dispatch, pk) => {
  dispatch({
    type: DELETE_AKUNBUKUBESAR,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#6a82fb',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
  }).then(result => {
    if (result.isConfirmed) {
      axios({
        method: 'DELETE',
        url: API_URL_updelakunbukubesar + pk,
        timeout: 120000,
      })
        .then(response => {
          Swal.fire('Deleted!', response.data.messages, 'success');
          dispatch({
            type: DELETE_AKUNBUKUBESAR,
            payload: {
              loading: false,
              data: response.data,
              errorMessage: false,
            },
          });
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
          });
          dispatch({
            type: DELETE_AKUNBUKUBESAR,
            payload: {
              loading: false,
              data: false,
              errorMessage: error.message,
            },
          });
        });
    }
  });
};
// ============================================ Kelompok ====================================
export const getKelompok = (dispatch, data) => {
  dispatch({
    type: GET_KELOMPOK,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_datakelompok + data.param,
  })
    .then(response => {
      dispatch({
        type: GET_KELOMPOK,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_KELOMPOK,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const addKelompok = (dispatch, data) => {
  dispatch({
    type: ADD_KELOMPOK,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_createkelompok,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: 'success',
          title: response.data.messages,
        });
        dispatch({
          type: ADD_KELOMPOK,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_KELOMPOK,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const editKelompok = (dispatch, data) => {
  dispatch({
    type: EDIT_KELOMPOK,
    payload: {
      data: data,
    },
  });
};
export const updateKelompok = (dispatch, data) => {
  dispatch({
    type: UPDATE_KELOMPOK,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'PUT',
    url: API_URL_updelkelompok + data.pk,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire('Good job!', response.data.messages, 'success');
        dispatch({
          type: UPDATE_KELOMPOK,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: UPDATE_KELOMPOK,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const deleteKelompok = (dispatch, pk) => {
  dispatch({
    type: DELETE_KELOMPOK,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#6a82fb',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
  }).then(result => {
    if (result.isConfirmed) {
      axios({
        method: 'DELETE',
        url: API_URL_updelkelompok + pk,
        timeout: 120000,
      })
        .then(response => {
          Swal.fire('Deleted!', response.data.messages, 'success');
          dispatch({
            type: DELETE_KELOMPOK,
            payload: {
              loading: false,
              data: response.data,
              errorMessage: false,
            },
          });
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
          });
          dispatch({
            type: DELETE_KELOMPOK,
            payload: {
              loading: false,
              data: false,
              errorMessage: error.message,
            },
          });
        });
    }
  });
};
// ============================================ Golongan ====================================
export const getGolongan = (dispatch, data) => {
  dispatch({
    type: GET_GOLONGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_datagolongan + data.param,
  })
    .then(response => {
      dispatch({
        type: GET_GOLONGAN,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_GOLONGAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const addGolongan = (dispatch, data) => {
  dispatch({
    type: ADD_GOLONGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_creategolongan,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: 'success',
          title: response.data.messages,
        });
        dispatch({
          type: ADD_GOLONGAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_GOLONGAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const editGolongan = (dispatch, data) => {
  dispatch({
    type: EDIT_GOLONGAN,
    payload: {
      data: data,
    },
  });
};
export const updateGolongan = (dispatch, data) => {
  dispatch({
    type: UPDATE_GOLONGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'PUT',
    url: API_URL_updelgolongan + data.pk,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire('Good job!', response.data.messages, 'success');
        dispatch({
          type: UPDATE_GOLONGAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: UPDATE_GOLONGAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const deleteGolongan = (dispatch, pk) => {
  dispatch({
    type: DELETE_GOLONGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#6a82fb',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
  }).then(result => {
    if (result.isConfirmed) {
      axios({
        method: 'DELETE',
        url: API_URL_updelgolongan + pk,
        timeout: 120000,
      })
        .then(response => {
          Swal.fire('Deleted!', response.data.messages, 'success');
          dispatch({
            type: DELETE_GOLONGAN,
            payload: {
              loading: false,
              data: response.data,
              errorMessage: false,
            },
          });
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
          });
          dispatch({
            type: DELETE_GOLONGAN,
            payload: {
              loading: false,
              data: false,
              errorMessage: error.message,
            },
          });
        });
    }
  });
};
// ============================================ Subgolongan ====================================
export const getSubGolongan = (dispatch, data) => {
  dispatch({
    type: GET_SUBGOLONGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_datasubgolongan + data.param,
  })
    .then(response => {
      dispatch({
        type: GET_SUBGOLONGAN,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_SUBGOLONGAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const addSubGolongan = (dispatch, data) => {
  dispatch({
    type: ADD_SUBGOLONGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_createsubgolongan,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: 'success',
          title: response.data.messages,
        });
        dispatch({
          type: ADD_SUBGOLONGAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_SUBGOLONGAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const editSubGolongan = (dispatch, data) => {
  dispatch({
    type: EDIT_SUBGOLONGAN,
    payload: {
      data: data,
    },
  });
};
export const updateSubGolongan = (dispatch, data) => {
  dispatch({
    type: UPDATE_SUBGOLONGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'PUT',
    url: API_URL_updelsubgolongan + data.pk,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire('Good job!', response.data.messages, 'success');
        dispatch({
          type: UPDATE_SUBGOLONGAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: UPDATE_SUBGOLONGAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const deleteSubGolongan = (dispatch, pk) => {
  dispatch({
    type: DELETE_SUBGOLONGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#6a82fb',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
  }).then(result => {
    if (result.isConfirmed) {
      axios({
        method: 'DELETE',
        url: API_URL_updelsubgolongan + pk,
        timeout: 120000,
      })
        .then(response => {
          Swal.fire('Deleted!', response.data.messages, 'success');
          dispatch({
            type: DELETE_SUBGOLONGAN,
            payload: {
              loading: false,
              data: response.data,
              errorMessage: false,
            },
          });
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
          });
          dispatch({
            type: DELETE_SUBGOLONGAN,
            payload: {
              loading: false,
              data: false,
              errorMessage: error.message,
            },
          });
        });
    }
  });
};
