import {
  GET_BARANGRETURN,
  DELETE_BARANGRETURN,
  ADD_BARANGRETURN,
  UPDATE_BARANGRETURN,
  EDIT_BARANGRETURN,
} from '../../pages/actions/barangreturnAction';

const BarangReturn = (state, action) => {
  const { type } = action;
  switch (type) {
    case EDIT_BARANGRETURN:
      return {
        ...state,
        editBarangReturnResult: action.payload.data,
      };
    case UPDATE_BARANGRETURN:
      return {
        ...state,
        updateBarangReturnResult: action.payload.data,
        updateBarangReturnLoading: action.payload.loading,
        updateBarangReturnError: action.payload.errorMessage,
      };
    case ADD_BARANGRETURN:
      return {
        ...state,
        addBarangReturnResult: action.payload.data,
        addBarangReturnLoading: action.payload.loading,
        addBarangReturnError: action.payload.errorMessage,
      };
    case DELETE_BARANGRETURN:
      return {
        ...state,
        deleteBarangReturnResult: action.payload.data,
        deleteBarangReturnLoading: action.payload.loading,
        deleteBarangReturnError: action.payload.errorMessage,
      };
    case GET_BARANGRETURN:
      return {
        ...state,
        getBarangReturnResult: action.payload.data,
        getBarangReturnLoading: action.payload.loading,
        getBarangReturnError: action.payload.errorMessage,
      };
      return state;
  }
};

export default BarangReturn;
