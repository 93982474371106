import {
  GET_PENAGIHAN,
  ADD_PENAGIHAN,
  EDIT_PENAGIHAN,
  UPDATE_PENAGIHAN,
  DELETE_PENAGIHAN,
  GET_PROGRESS,
  ADD_PROGRESS,
  EDIT_PROGRESS,
  UPDATE_PROGRESS,
  DELETE_PROGRESS,
  GET_PENCAIRAN,
  ADD_PENCAIRAN,
  EDIT_PENCAIRAN,
  UPDATE_PENCAIRAN,
  DELETE_PENCAIRAN,
  GET_PENGAJUAN,
  DELETE_PENGAJUAN,
  ADD_PENGAJUAN,
  UPDATE_PENGAJUAN,
  EDIT_PENGAJUAN,
  GET_PERSETUJUAN,
  DELETE_PERSETUJUAN,
  ADD_PERSETUJUAN,
  UPDATE_PERSETUJUAN,
  EDIT_PERSETUJUAN,
  GET_PEMBELANJAAN,
  DELETE_PEMBELANJAAN,
  ADD_PEMBELANJAAN,
  UPDATE_PEMBELANJAAN,
  EDIT_PEMBELANJAAN,
} from '../../pages/actions/danaAction';

const dana = (state, action) => {
  const { type } = action;
  switch (type) {
    case GET_PENAGIHAN:
      return {
        ...state,
        getPenagihanResult: action.payload.data,
        getPenagihanLoading: action.payload.loading,
        getPenagihanError: action.payload.errorMessage,
      };
    case ADD_PENAGIHAN:
      return {
        ...state,
        addPenagihanResult: action.payload.data,
        addPenagihanLoading: action.payload.loading,
        addPenagihanError: action.payload.errorMessage,
      };
    case EDIT_PENAGIHAN:
      return {
        ...state,
        editPenagihanResult: action.payload.data,
      };
    case UPDATE_PENAGIHAN:
      return {
        ...state,
        updatePenagihanResult: action.payload.data,
        updatePenagihanLoading: action.payload.loading,
        updatePenagihanError: action.payload.errorMessage,
      };
    case DELETE_PENAGIHAN:
      return {
        ...state,
        deletePenagihanResult: action.payload.data,
        deletePenagihanLoading: action.payload.loading,
        deletePenagihanError: action.payload.errorMessage,
      };
    case GET_PROGRESS:
      return {
        ...state,
        getProgressResult: action.payload.data,
        getProgressLoading: action.payload.loading,
        getProgressError: action.payload.errorMessage,
      };
    case ADD_PROGRESS:
      return {
        ...state,
        addProgressResult: action.payload.data,
        addProgressLoading: action.payload.loading,
        addProgressError: action.payload.errorMessage,
      };
    case EDIT_PROGRESS:
      return {
        ...state,
        editProgressResult: action.payload.data,
      };
    case UPDATE_PROGRESS:
      return {
        ...state,
        updateProgressResult: action.payload.data,
        updateProgressLoading: action.payload.loading,
        updateProgressError: action.payload.errorMessage,
      };
    case DELETE_PROGRESS:
      return {
        ...state,
        deleteProgressResult: action.payload.data,
        deleteProgressLoading: action.payload.loading,
        deleteProgressError: action.payload.errorMessage,
      };
    case GET_PENCAIRAN:
      return {
        ...state,
        getPencairanResult: action.payload.data,
        getPencairanLoading: action.payload.loading,
        getPencairanError: action.payload.errorMessage,
      };
    case ADD_PENCAIRAN:
      return {
        ...state,
        addPencairanResult: action.payload.data,
        addPencairanLoading: action.payload.loading,
        addPencairanError: action.payload.errorMessage,
      };
    case EDIT_PENCAIRAN:
      return {
        ...state,
        editPencairanResult: action.payload.data,
      };
    case UPDATE_PENCAIRAN:
      return {
        ...state,
        updatePencairanResult: action.payload.data,
        updatePencairanLoading: action.payload.loading,
        updatePencairanError: action.payload.errorMessage,
      };
    case DELETE_PENCAIRAN:
      return {
        ...state,
        deletePencairanResult: action.payload.data,
        deletePencairanLoading: action.payload.loading,
        deletePencairanError: action.payload.errorMessage,
      };
    case GET_PENGAJUAN:
      return {
        ...state,
        getPengajuanResult: action.payload.data,
        getPengajuanLoading: action.payload.loading,
        getPengajuanError: action.payload.errorMessage,
      };
    case ADD_PENGAJUAN:
      return {
        ...state,
        addPengajuanResult: action.payload.data,
        addPengajuanLoading: action.payload.loading,
        addPengajuanError: action.payload.errorMessage,
      };
    case EDIT_PENGAJUAN:
      return {
        ...state,
        editPengajuanResult: action.payload.data,
      };
    case UPDATE_PENGAJUAN:
      return {
        ...state,
        updatePengajuanResult: action.payload.data,
        updatePengajuanLoading: action.payload.loading,
        updatePengajuanError: action.payload.errorMessage,
      };
    case DELETE_PENGAJUAN:
      return {
        ...state,
        deletePengajuanResult: action.payload.data,
        deletePengajuanLoading: action.payload.loading,
        deletePengajuanError: action.payload.errorMessage,
      };
    case GET_PERSETUJUAN:
      return {
        ...state,
        getPersetujuanResult: action.payload.data,
        getPersetujuanLoading: action.payload.loading,
        getPersetujuanError: action.payload.errorMessage,
      };
    case ADD_PERSETUJUAN:
      return {
        ...state,
        addPersetujuanResult: action.payload.data,
        addPersetujuanLoading: action.payload.loading,
        addPersetujuanError: action.payload.errorMessage,
      };
    case EDIT_PERSETUJUAN:
      return {
        ...state,
        editPersetujuanResult: action.payload.data,
      };
    case UPDATE_PERSETUJUAN:
      return {
        ...state,
        updatePersetujuanResult: action.payload.data,
        updatePersetujuanLoading: action.payload.loading,
        updatePersetujuanError: action.payload.errorMessage,
      };
    case DELETE_PERSETUJUAN:
      return {
        ...state,
        deletePersetujuanResult: action.payload.data,
        deletePersetujuanLoading: action.payload.loading,
        deletePersetujuanError: action.payload.errorMessage,
      };
    case GET_PEMBELANJAAN:
      return {
        ...state,
        getPembelanjaanResult: action.payload.data,
        getPembelanjaanLoading: action.payload.loading,
        getPembelanjaanError: action.payload.errorMessage,
      };
    case ADD_PEMBELANJAAN:
      return {
        ...state,
        addPembelanjaanResult: action.payload.data,
        addPembelanjaanLoading: action.payload.loading,
        addPembelanjaanError: action.payload.errorMessage,
      };
    case EDIT_PEMBELANJAAN:
      return {
        ...state,
        editPembelanjaanResult: action.payload.data,
      };
    case UPDATE_PEMBELANJAAN:
      return {
        ...state,
        updatePembelanjaanResult: action.payload.data,
        updatePembelanjaanLoading: action.payload.loading,
        updatePembelanjaanError: action.payload.errorMessage,
      };
    case DELETE_PEMBELANJAAN:
      return {
        ...state,
        deletePembelanjaanResult: action.payload.data,
        deletePembelanjaanLoading: action.payload.loading,
        deletePembelanjaanError: action.payload.errorMessage,
      };
    default:
      return state;
  }
};

export default dana;
