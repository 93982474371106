import axios from "axios";
import Swal from "sweetalert2";

import { API_URL_createdatateknis } from "../constants";
import { API_URL_detilteknispelanggan } from "../constants";
import { API_URL_edeldatateknis } from "../constants";

export const GET_DATA_TEKNIS = "GET_DATA_TEKNIS";
export const ADD_DATA_TEKNIS = "ADD_DATA_TEKNIS";
export const UPDATE_DATA_TEKNIS = "UPDATE_DATA_TEKNIS";
export const DELETE_DATA_TEKNIS = "DELETE_DATA_TEKNIS";

export const getDatateknis = (dispatch, data) => {
  dispatch({
    type: GET_DATA_TEKNIS,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: "POST",
    url: API_URL_detilteknispelanggan,
    data: data,
  })
    .then((response) => {
      dispatch({
        type: GET_DATA_TEKNIS,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_DATA_TEKNIS,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const addDatateknis = (dispatch, data) => {
  console.log(data);
  dispatch({
    type: ADD_DATA_TEKNIS,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: "POST",
    url: API_URL_createdatateknis,
    timeout: 120000,
    data: data,
  })
    .then((response) => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.messages,
        });
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: response.data.messages,
        });
        dispatch({
          type: ADD_DATA_TEKNIS,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error,
      });
      dispatch({
        type: ADD_DATA_TEKNIS,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const updateDatateknis = (dispatch, data) => {
  dispatch({
    type: UPDATE_DATA_TEKNIS,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: "PUT",
    url: API_URL_edeldatateknis + data.pk,
    timeout: 120000,
    data: data,
  })
    .then((response) => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.messages,
        });
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: response.data.messages,
        });
        dispatch({
          type: UPDATE_DATA_TEKNIS,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error,
      });
      dispatch({
        type: UPDATE_DATA_TEKNIS,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const deleteDatateknis = (dispatch, pk) => {
  dispatch({
    type: DELETE_DATA_TEKNIS,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#6a82fb",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      axios({
        method: "DELETE",
        url: API_URL_edeldatateknis + pk,
        timeout: 120000,
      })
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Good job!",
            text: response.data.messages,
            showConfirmButton: false,
            timer: 1500,
          });
          dispatch({
            type: DELETE_DATA_TEKNIS,
            payload: {
              loading: false,
              data: response.data,
              errorMessage: false,
            },
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error,
          });
          dispatch({
            type: DELETE_DATA_TEKNIS,
            payload: {
              loading: false,
              data: false,
              errorMessage: error.message,
            },
          });
        });
    }
  });
};
