import axios from 'axios';
import Swal from 'sweetalert2';
import {
  API_URL_getperlengkapan,
  API_URL_createperlengkapan,
  API_URL_edelperlengkapan,
} from '../constants';

export const GET_PERLENGKAPAN = 'GET_PERLENGKAPAN';
export const ADD_PERLENGKAPAN = 'ADD_PERLENGKAPAN';
export const DELETE_PERLENGKAPAN = ' DELETE_PERLENGKAPAN';
export const EDIT_PERLENGKAPAN = 'EDIT_PERLENGKAPAN';
export const UPDATE_PERLENGKAPAN = ' UPDATE_PERLENGKAPAN';

export const getPerlengkapan = (dispatch, data) => {
  dispatch({
    type: GET_PERLENGKAPAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_getperlengkapan + data.param,
  })
    .then(response => {
      dispatch({
        type: GET_PERLENGKAPAN,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_PERLENGKAPAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const addPerlengkapan = (dispatch, data) => {
  dispatch({
    type: ADD_PERLENGKAPAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_createperlengkapan,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: ADD_PERLENGKAPAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_PERLENGKAPAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const editPerlengkapan = (dispatch, data) => {
  dispatch({
    type: EDIT_PERLENGKAPAN,
    payload: {
      data: data,
    },
  });
};

export const updatePerlengkapan = (dispatch, data) => {
  dispatch({
    type: UPDATE_PERLENGKAPAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'PUT',
    url: API_URL_edelperlengkapan + data.pk,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: UPDATE_PERLENGKAPAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: UPDATE_PERLENGKAPAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const deletePerlengkapan = (dispatch, pk) => {
  dispatch({
    type: DELETE_PERLENGKAPAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#6a82fb',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
  }).then(result => {
    if (result.isConfirmed) {
      axios({
        method: 'DELETE',
        url: API_URL_edelperlengkapan + pk,
        timeout: 120000,
      })
        .then(response => {
          Swal.fire({
            icon: 'success',
            title: 'Good job!',
            text: response.data.messages,
            showConfirmButton: false,
            timer: 1500,
          });
          dispatch({
            type: DELETE_PERLENGKAPAN,
            payload: {
              loading: false,
              data: response.data,
              errorMessage: false,
            },
          });
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
          });
          dispatch({
            type: DELETE_PERLENGKAPAN,
            payload: {
              loading: false,
              data: false,
              errorMessage: error.message,
            },
          });
        });
    }
  });
};
