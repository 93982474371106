import {
  GET_USER,
  // ADD_USER,
  // DELETE_USER,
  // EDIT_USER,
  // UPDATE_USER,
} from '../../pages/actions/userAction.js';

const users = (state, action) => {
  const { type } = action;
  switch (type) {
    case GET_USER:
      return {
        ...state,
        getUserResult: action.payload.data,
        getUserLoading: action.payload.loading,
        getUserError: action.payload.errorMessage,
      };
    // case ADD_USER:
    //   return {
    //     ...state,
    //     addUserResult: action.payload.data,
    //     addUserLoading: action.payload.loading,
    //     addUserError: action.payload.errorMessage,
    //   };
    // case DELETE_USER:
    //   return {
    //     ...state,
    //     deleteUserResult: action.payload.data,
    //     deleteUserLoading: action.payload.loading,
    //     deleteUserError: action.payload.errorMessage,
    //   };
    // case EDIT_USER:
    //   return {
    //     ...state,
    //     editUserResult: action.payload.data,
    //   };
    // case UPDATE_USER:
    //   return {
    //     ...state,
    //     updateUserResult: action.payload.data,
    //     updateUserLoading: action.payload.loading,
    //     updateUserError: action.payload.errorMessage,
    //   };
    default:
      return state;
  }
};

export default users;
