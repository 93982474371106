import GAListener from "./components/GAListener";
import { MainLayout } from "./components/Layout";
import PageSpinner from "./components/PageSpinner";
import React from "react";
import componentQueries from "react-component-queries";
import { BrowserRouter, Redirect, Switch, Route } from "react-router-dom";
import "./styles/reduction.scss";
import PrivateRoute from "./components/authentication/Privateroute";
import PublicRoute from "./components/authentication/PublicRoute";
import Login from "./components/authentication/Login";
import reactRouterToArray from "react-router-to-array";
import { isAuthenticated } from "./components/api/authenticationApi";

const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const Dashboardteknis = React.lazy(() => import("./pages/Dashboardteknis"));
const Dashboardsales = React.lazy(() => import("./pages/Dashboardsales"));
const Material = React.lazy(() => import("./pages/inventori/datastok/materialstok/listing/Material"));
const ProdukRitel = React.lazy(() => import("./pages/filemaster/paketinternet/Paketinternet"));
const Tipebayar = React.lazy(() => import("./pages/filemaster/tipebayar/Tipebayar"));
const Tipearuskas = React.lazy(() => import("./pages/filemaster/tipearuskas/Tipearuskas"));
const Rekeningbank = React.lazy(() => import("./pages/filemaster/rekeningbank/Rekeningbank"));
const RegistrasiTab = React.lazy(() => import("./pages/pelanggan/registrasi/RegistrasiTab"));
const Configfttx = React.lazy(() => import("./pages/fttxconfigure/Configurefttx"));
const DatapelangganTab = React.lazy(() => import("./pages/pelanggan/datapelanggan/DatapelangganTab"));
const Tiketgangguan = React.lazy(() =>
  import('pages/pelanggan/tiketgangguan/GangguanTab'),
);
const Detailpelanggan = React.lazy(() => import("./pages/pelanggan/datapelanggan/Detailpelanggan"));
const TagihanTab = React.lazy(() => import("./pages/pelanggan/tagihan/DatatagihanTab"));
const Dp = React.lazy(() => import("./pages/pelanggan/datapelanggan/Dp"));
const Datapembayaran = React.lazy(() => import("./pages/pelanggan/datapembayaran/DatapembayaranTab"));
const PenerimaankasirTab = React.lazy(() => import("./pages/pelanggan/penerimaankasir/PenerimaankasirTab"));
const Detailpenerimaankasir = React.lazy(() => import("./pages/pelanggan/penerimaankasir/Detailpenerimaankasir"));
const Datapiutang = React.lazy(() => import("./pages/pelanggan/piutang/Piutang"));
const Kiriminvoice = React.lazy(() => import("./pages/pelanggan/kiriminvoice/Kiriminvoice"));
const Bayartagihan = React.lazy(() => import("./pages/pelanggan/bayartagihan/Bayartagihan"));

const Tspelanggan = React.lazy(() => import("./pages/pelanggan/datapelanggan/Tspelanggan"));
const Coa = React.lazy(() => import("./pages/akuntansi/coa/CoaTab"));
const Draftransaki = React.lazy(() => import("./pages/akuntansi/transaksi/DraftTransaksi"));
const Transaksi = React.lazy(() => import("./pages/akuntansi/transaksi/Transaksi"));
const Jurnal = React.lazy(() => import("./pages/akuntansi/transaksi/Jurnal"));
const Datajurnal = React.lazy(() => import("./pages/akuntansi/jurnal/Datajurnal"));
// Awal Dana
const PengeluaranTab = React.lazy(() => import("./pages/dana/dana-pengeluaran/PengeluaranTab"));
const PemasukanTab = React.lazy(() => import("./pages/dana/dana-pemasukan/PemasukanTab"));
const RincianPenagihan = React.lazy(() => import("./pages/dana/dana-pemasukan/penagihan/RincianPenagihan"));
const RincianProgress = React.lazy(() => import("./pages/dana/dana-pemasukan/progress/RincianProgress"));
const RincianPencairan = React.lazy(() => import("./pages/dana/dana-pemasukan/pencairan/RincianPencairan"));
const RincianPengajuan = React.lazy(() => import("./pages/dana/dana-pengeluaran/pengajuan/RincianPengajuan"));
const RincianPersetujuan = React.lazy(() => import("./pages/dana/dana-pengeluaran/persetujuan/RincianPersetujuan"));
const RincianPembelanjaan = React.lazy(() => import("./pages/dana/dana-pengeluaran/pembelanjaan/RincianPembelanjaan"));
// Akhir Dana
const ComponentToPrint = React.lazy(() => import("./pages/pelanggan/datapembayaran/Print"));
const Historypembayaran = React.lazy(() => import("./pages/pelanggan/datapembayaran/Datapembayarandetail"));
const Rutin = React.lazy(() => import("./pages/pengelolaanbeban/rutin/Rutin"));
const Partikular = React.lazy(() => import("./pages/pengelolaanbeban/partikular/Partikular"));
const InvoiceBeban = React.lazy(() => import("./pages/pengelolaanbeban/bebaninvoice/Invoicebeban"));
const Bebanproyek = React.lazy(() => import("./pages/pengelolaanbeban/bebanproyek/Bebanproyek"));
const Bebanoperasional = React.lazy(() => import("./pages/pengelolaanbeban/bebanoperasional/Bebanoperasional"));
const Bebanlainlain = React.lazy(() => import("./pages/pengelolaanbeban/bebanlainlain/Bebanlainlain"));
const Bebantagihan = React.lazy(() => import("./pages/pengelolaanbeban/bebantagihan/Bebantagihan"));
const Kasandbank = React.lazy(() => import("./pages/keuangan/kasandbank/Kasandbank"));
const RekonsiliasiTab = React.lazy(() => import("./pages/keuangan/rekonsiliasi/RekonsiliasiTab"));
const Hutang = React.lazy(() => import("./pages/pengelolaanbeban/hutang/Hutang"));
//anggaran
const AnggaranBelanjaTab = React.lazy(() => import("./pages/anggaran/belanja/BelanjaTab"));
const DetailBRT = React.lazy(() => import("./pages/anggaran/belanja/rutintetap/DetailRutinTetap"));
const DetailBRF = React.lazy(() => import("./pages/anggaran/belanja/rutinfluktuatif/DetailRutinFluktuatif"));
const DetailBPA = React.lazy(() => import("./pages/anggaran/belanja/partikular/DetailPartikular"));
const DetailBOP = React.lazy(() => import("./pages/anggaran/belanja/operasional/DetailOperasional"));
const DetailBPR = React.lazy(() => import("./pages/anggaran/belanja/proyek/DetailProyek"));
const DetailBLL = React.lazy(() => import("./pages/anggaran/belanja/lainnya/DetailLainnya"));
const AnggaranPendapatanTab = React.lazy(() => import("./pages/anggaran/pendapatan/PendapatanTab"));
// ArtaKelola
//preaksptasi
const DetailPreakseptasi = React.lazy(() => import('./pages/artakelola/preakseptasi/DetailPreakseptasi'),);
const DetailPreritel = React.lazy(() => import('./pages/artakelola/preakseptasi/DetailPreritel'),);
const PreakseptasiTab = React.lazy(() => import('./pages/artakelola/preakseptasi/PreakseptasiTab'),);
//Prakeluran
const Prakeluaran = React.lazy(() => import('./pages/artakelola/prakeluaran/Prakeluaran'),);
const DetailPrakeluaran = React.lazy(() => import('./pages/artakelola/prakeluaran/DetailPrakeluaran'),);
//Realisasi
// const Realisasi = React.lazy(() => import('./pages/artakelola/realisasi/RealisasiTab'),);
//inventori
const InventoriPengadaanTab = React.lazy(() => import('./pages/inventori/pengadaan/PengadaanTab'));
const IndatastokTab = React.lazy(() => import('./pages/inventori/datastok/IndatastokTab'));
const IndataasetTab = React.lazy(() => import('./pages/inventori/dataaset/IndataasetTab'));
const InpengelolaTab = React.lazy(() => import('./pages/inventori/pengelola/InpengelolaTab'));
const SatuanProduk = React.lazy(() => import('./pages/filemaster/satuanproduk/SatuanProduk'));
const LokasiInventori = React.lazy(() => import('./pages/filemaster/lokasiinventori/LokasiInventori'));

//--proyek----
const ManajemenProyekTab = React.lazy(() => import("./pages/proyek/manajemen-proyek/ManajemenProyekTab"));
const DetailUsulan = React.lazy(() => import("./pages/proyek/manajemen-proyek/usulan/DetailUsulan"));
const RincianBudget = React.lazy(() => import("./pages/proyek/manajemen-proyek/usulan/RincianBudget"));
const DetailProyekRilis = React.lazy(() => import("./pages/proyek/manajemen-proyek/proyekrilis/DetailProyekRilis"));
const ArtefakProyekTab = React.lazy(() => import("./pages/proyek/artefak-proyek/ArtefakProyekTab"));
const ProyekRilisDetailTab = React.lazy(() => import("./pages/proyek/manajemen-proyek/proyekrilis/ProyekRilisDetailTab"));
const Sales = React.lazy(() => import('./pages/filemaster/sales/Sales'));


const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split("/").pop()}`;
};
const route = window.location.pathname;

class App extends React.Component {
  render() {
    let allroute = reactRouterToArray(
      <PrivateRoute path="/" component={Dashboard}>
        <PublicRoute exact path="/login" component={Login} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/paketinternet" component={ProdukRitel} />
        <PrivateRoute exact path="/tipebayar" component={Tipebayar} />
        <PrivateRoute exact path="/rekeningbank" component={Rekeningbank} />
        <PrivateRoute exact path="/registrasi" component={RegistrasiTab} />
        <PrivateRoute exact path="/configfttx" component={Configfttx} />
        <PrivateRoute exact path="/datapelanggan" component={DatapelangganTab} />
        <PrivateRoute exact path="/datatagihan" component={TagihanTab} />
        <PrivateRoute exact path="/kiriminvoice" component={Kiriminvoice} />
        <PrivateRoute exact path="/bayartagihan" component={Bayartagihan} />
        <PrivateRoute exact path="/datapembayaran" component={Datapembayaran} />
        <PrivateRoute exact path="/datapiutang" component={Datapiutang} />
        <PrivateRoute exact path="/tspelanggan" component={Tspelanggan} />
        <PrivateRoute exact path="/coa" component={Coa} />
        <PrivateRoute exact path="/draftransaksi" component={Draftransaki} />
        <PrivateRoute exact path="/transaksi" component={Transaksi} />
        <PrivateRoute exact path="/jurnal" component={Jurnal} />
        <PrivateRoute exact path="/datajurnal" component={Datajurnal} />
        <PrivateRoute exact path="/cetakkwitansi" component={ComponentToPrint} />
        <PrivateRoute exact path="/historypembayaran" component={Historypembayaran} />
        <PrivateRoute exact path="/bebaninvoice" component={InvoiceBeban} />
        <PrivateRoute exact path="/bebanproyek" component={Bebanproyek} />
        <PrivateRoute exact path="/bebanoperasional" component={Bebanoperasional} />
        <PrivateRoute exact path="/bebanlainlain" component={Bebanlainlain} />
        <PrivateRoute exact path="/bebanrutin" component={Rutin} />
        <PrivateRoute exact path="/bebanpartikular" component={Partikular} />
        <PrivateRoute exact path="/kasdanbank" component={Kasandbank} />
        <PrivateRoute exact path="/bebantagihan" component={Bebantagihan} />
        <PrivateRoute exact path="/hutang" component={Hutang} />
        <PrivateRoute exact path="/datastok" component={IndatastokTab} />
        <PrivateRoute exact path="/dataaset" component={IndataasetTab} />
        <PrivateRoute exact path="/pengelola" component={InpengelolaTab} />
      </PrivateRoute>
    );
    //outputs: ['/', '/about', '/about/home', '/users']

    return (
      <BrowserRouter basename={getBasename()}>
        <GAListener>
          <Switch>
            {allroute.map((item, index) => JSON.stringify(item))}

            <PublicRoute exact path="/login" component={Login} />

            <MainLayout breakpoint={this.props.breakpoint}>
              <React.Suspense fallback={<PageSpinner />}>
                <PrivateRoute exact path="/" component={Dashboard} />
                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                <PrivateRoute exact path="/dashboard-teknis" component={Dashboardteknis} />
                <PrivateRoute exact path="/dashboard-sales" component={Dashboardsales} />
                <PrivateRoute exact path="/produk-ritel" component={ProdukRitel} />
                <PrivateRoute exact path="/tipebayar" component={Tipebayar} />
                <PrivateRoute exact path="/tipearuskas" component={Tipearuskas} />
                <PrivateRoute exact path="/rekeningbank" component={Rekeningbank} />
                <PrivateRoute exact path="/satuanproduk" component={SatuanProduk} />
                <PrivateRoute exact path="/lokasiinventori" component={LokasiInventori} />
                <PrivateRoute exact path="/registrasi" component={RegistrasiTab} />
                <PrivateRoute exact path="/configfttx" component={Configfttx} />
                <PrivateRoute exact path="/datapelanggan" component={DatapelangganTab} />
                <PrivateRoute
                  exact
                  path="/tiketgangguan"
                  component={Tiketgangguan}
                />
                <PrivateRoute exact path="/detail-pelanggan" component={Detailpelanggan} />
                <PrivateRoute exact path="/downpayment" component={Dp} />
                <PrivateRoute exact path="/datatagihan" component={TagihanTab} />
                <PrivateRoute exact path="/kiriminvoice" component={Kiriminvoice} />
                <PrivateRoute exact path="/bayartagihan" component={Bayartagihan} />
                <PrivateRoute exact path="/datapembayaran" component={Datapembayaran} />
                <PrivateRoute exact path="/penerimaan" component={PenerimaankasirTab} />
                <PrivateRoute exact path="/detail-penerimaankasir" component={Detailpenerimaankasir} />
                <PrivateRoute exact path="/datapiutang" component={Datapiutang} />
                <PrivateRoute exact path="/tspelanggan" component={Tspelanggan} />
                <PrivateRoute exact path="/cetakkwitansi" component={ComponentToPrint} />
                <PrivateRoute exact path="/historypembayaran" component={Historypembayaran} />
                {/* Inventori */}
                <PrivateRoute exact path="/inventori/datastok" component={IndatastokTab}/>
                <PrivateRoute exact path="/dataaset" component={IndataasetTab}/>
                <PrivateRoute exact path="/inventori/pengelola" component={InpengelolaTab}/>
                <PrivateRoute exact path="/inventori/datastok/material" component={Material}/>
                <PrivateRoute exact path="/inventori/pengadaan" component={InventoriPengadaanTab}/>
                {isAuthenticated() !== null ? (
                  isAuthenticated().level == "Admin" || isAuthenticated().level == "Keuangan" || isAuthenticated().level == "Direktur" ? (
                    <>
                      <PrivateRoute exact path="/coa" component={Coa} />
                      <PrivateRoute exact path="/draftransaksi" component={Draftransaki} />
                      <PrivateRoute exact path="/transaksi" component={Transaksi} />
                      <PrivateRoute exact path="/jurnal" component={Jurnal} />
                      <PrivateRoute exact path="/datajurnal" component={Datajurnal} />
                      <PrivateRoute exact path="/bebaninvoice" component={InvoiceBeban} />
                      <PrivateRoute exact path="/bebanproyek" component={Bebanproyek} />
                      <PrivateRoute exact path="/bebanoperasional" component={Bebanoperasional} />
                      <PrivateRoute exact path="/bebanlainlain" component={Bebanlainlain} />
                      <PrivateRoute exact path="/bebanrutin" component={Rutin} />
                      <PrivateRoute exact path="/bebanpartikular" component={Partikular} />
                      <PrivateRoute exact path="/kasdanbank" component={Kasandbank} />
                      <PrivateRoute exact path="/rekonsiliasi" component={RekonsiliasiTab} />
                      <PrivateRoute exact path="/bebantagihan" component={Bebantagihan} />
                      <PrivateRoute exact path="/hutang" component={Hutang} />
                      <PrivateRoute exact path="/dana-pengeluaran" component={PengeluaranTab} />
                      <PrivateRoute exact path="/dana-pemasukan" component={PemasukanTab} />
                      <PrivateRoute exact path="/rincian-penagihan" component={RincianPenagihan} />
                      <PrivateRoute exact path="/rincian-progress" component={RincianProgress} />
                      <PrivateRoute exact path="/rincian-pencairan" component={RincianPencairan} />
                      <PrivateRoute exact path="/rincian-pengajuan" component={RincianPengajuan} />
                      <PrivateRoute exact path="/rincian-persetujuan" component={RincianPersetujuan} />
                      <PrivateRoute exact path="/rincian-pembelanjaan" component={RincianPembelanjaan} />
                      <PrivateRoute exact path="/anggaran-belanja" component={AnggaranBelanjaTab} />
                      <PrivateRoute exact path="/anggaran-pendapatan" component={AnggaranPendapatanTab} />
                      <PrivateRoute exact path="/detail-rutin-tetap" component={DetailBRT} />
                      <PrivateRoute exact path="/detail-rutin-fluktuatif" component={DetailBRF} />
                      <PrivateRoute exact path="/detail-partikular" component={DetailBPA} />
                      <PrivateRoute exact path="/detail-operasional" component={DetailBOP} />
                      <PrivateRoute exact path="/detail-proyek" component={DetailBPR} />
                      <PrivateRoute exact path="/detail-lainnya" component={DetailBLL} />
                      <PrivateRoute exact path="/material" component={Material} />
                      <PrivateRoute exact path="/manajemen-proyek" component={ManajemenProyekTab} />
                      <PrivateRoute exact path="/manajemen-proyek/detail-usulan/rincian-budget" component={RincianBudget} />
                      <PrivateRoute exact path="/manajemen-proyek/detail-usulan" component={DetailUsulan} />
                      <PrivateRoute exact path="/manajemen-proyek/detail-proyekrilis" component={ProyekRilisDetailTab} />
                      <PrivateRoute exact path="/artefak-proyek" component={ArtefakProyekTab} />
                      {/* Artakelola */}
                      <PrivateRoute exact path="/detail-preritel" component={DetailPreritel}/>
                      <PrivateRoute exact path="/detail-preakseptasi" component={DetailPreakseptasi}/>
                      <PrivateRoute exact path="/detail-prakeluaran" component={DetailPrakeluaran}/>
                      <PrivateRoute exact path="/preakseptasi" component={PreakseptasiTab}/>
                      <PrivateRoute exact path="/prakeluaran" component={Prakeluaran}/>
                      <PrivateRoute exact path="/sales" component={Sales} />
                      {/* <PrivateRoute exact path="/realisasi" component={Realisasi}/> */}
                    </>
                  ) : null
                ) : null}
              </React.Suspense>
            </MainLayout>
            <Redirect to="/login" />
          </Switch>
        </GAListener>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: "xs" };
  }

  if (576 < width && width < 767) {
    return { breakpoint: "sm" };
  }

  if (768 < width && width < 991) {
    return { breakpoint: "md" };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: "lg" };
  }

  if (width > 1200) {
    return { breakpoint: "xl" };
  }

  return { breakpoint: "xs" };
};

export default componentQueries(query)(App);
