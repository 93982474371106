import {
    GET_TIPE_ARUS_KAS,
    ADD_TIPE_ARUS_KAS,
    EDIT_TIPE_ARUS_KAS,
    UPDATE_TIPE_ARUS_KAS,
    DELETE_TIPE_ARUS_KAS
  } from '../../pages/actions/tipearuskasAction';
  
  const Tipearuskas = (state, action) => {
    const { type } = action;
    switch (type) {
      case GET_TIPE_ARUS_KAS:
        return {
          ...state,
          getTipearuskasResult: action.payload.data,
          getTipearuskasLoading: action.payload.loading,
          getTipearuskasError: action.payload.errorMessage,
        };
  
      case ADD_TIPE_ARUS_KAS:
        return {
          ...state,
          addTipearuskasResult: action.payload.data,
          addTipearuskasLoading: action.payload.loading,
          addTipearuskasError: action.payload.errorMessage,
        };
      case EDIT_TIPE_ARUS_KAS:
        return {
          ...state,
          editTipearuskasResult: action.payload.data,
        };
      case UPDATE_TIPE_ARUS_KAS:
        return {
          ...state,
          updateTipearuskasResult: action.payload.data,
          updateTipearuskasLoading: action.payload.loading,
          updateTipearuskasError: action.payload.errorMessage,
        };
      case DELETE_TIPE_ARUS_KAS:
        return {
          ...state,
          deleteTipearuskasResult: action.payload.data,
          deleteTipearuskasLoading: action.payload.loading,
          deleteTipearuskasError: action.payload.errorMessage,
        };
  
      default:
        return state;
    }
  };
  
  export default Tipearuskas;
  