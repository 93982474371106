import React from 'react';
import { MdExitToApp } from 'react-icons/md';
import { ListGroupItem } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { logout } from '../api/authenticationApi';
import './logout.css';

const Logout = props => {
  const history = useHistory();

  const handleLogout = () => {
    logout();
    history.push('/login');
    // window.location.href = '/login';
  };

  return (
    <div>
      <ListGroupItem
        tag="button"
        action
        className="border-light logout active"
        onClick={handleLogout}
      >
        <MdExitToApp /> {props.keluar}
      </ListGroupItem>
    </div>
  );
};

export default Logout;
