import Avatar from "../../components/Avatar";
import { UserCard } from "../../components/Card";
import Logout from "../authentication/Logout";
import { isAuthenticated } from "../api/authenticationApi";
// import Notifications from '../../components/Notifications';
// import SearchInput from '../../components/SearchInput';
// import { notificationsData } from 'demos/header';
// import withBadge from 'hocs/withBadge';
import React from "react";
import {
  MdClearAll,
  // MdNotificationsActive,
  // MdNotificationsNone,
  MdPersonPin,
  MdSettingsApplications,
} from "react-icons/md";
import jwt_decode from "jwt-decode";
import {
  Button,
  ListGroup,
  ListGroupItem,
  // NavbarToggler,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
} from "reactstrap";
import bn from "../../utils/bemnames";
const bem = bn.create("header");

// const MdNotificationsActiveWithBadge = withBadge({
//   size: 'md',
//   color: 'primary',
//   style: {
//     top: -10,
//     right: -10,
//     display: 'inline-flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//   },
//   children: <small>5</small>,
// })(MdNotificationsActive);

class Header extends React.Component {
  state = {
    isOpenNotificationPopover: false,
    isNotificationConfirmed: false,
    isOpenUserCardPopover: false,
  };

  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });

    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: true });
    }
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  handleSidebarControlButton = (event) => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector(".cr-sidebar").classList.toggle("cr-sidebar--open");
  };

  render() {
    // const { isNotificationConfirmed } = this.state;
    const theme = localStorage.getItem("theme");
    const datatheme=['themeappsgradien','themeappsblue','themeappsred','themeappsgreen','themeappsyellow','themeappsorange','themeappspink','themeappsungu','themeappscyan']

    return (
      <Navbar light expand className={bem.b("bg-white")}>
        <Nav navbar className="mr-2">
        <button
            style={{ padding: '0rem 0.3rem',borderRadius:5,color:"#ffff"}}
            className={theme === null?"themeappscyan":theme}
            onClick={this.handleSidebarControlButton}
          >
            <MdClearAll size={30} />
          </button>
        </Nav>
        {/* <Nav navbar>
          <SearchInput />
        </Nav> */}

        <Nav navbar className={bem.e("nav-right")}>
          {/* <p>{username}</p> */}
          <NavItem className="d-inline-flex">
            {/* <NavLink id="Popover1" className="position-relative">
              {isNotificationConfirmed ? (
                <MdNotificationsNone
                  size={25}
                  className="text-secondary can-click"
                  onClick={this.toggleNotificationPopover}
                />
              ) : (
                <MdNotificationsActiveWithBadge
                  size={25}
                  className="text-secondary can-click animated swing infinite"
                  onClick={this.toggleNotificationPopover}
                />
              )}
            </NavLink> */}
            {/* <Popover
              placement="bottom"
              isOpen={this.state.isOpenNotificationPopover}
              toggle={this.toggleNotificationPopover}
              target="Popover1"
            >
              <PopoverBody>
                <Notifications notificationsData={notificationsData} />
              </PopoverBody>
            </Popover> */}
          </NavItem>

          <NavItem>
            <NavLink id="Popover2">
              <Avatar onClick={this.toggleUserCardPopover} className="can-click" />
            </NavLink>
            <Popover placement="bottom-end" isOpen={this.state.isOpenUserCardPopover} toggle={this.toggleUserCardPopover} target="Popover2" className="p-0 border-0" style={{ minWidth: 250 }}>
              <PopoverBody className="p-0 border-light">
                <UserCard title={isAuthenticated() == null ? "ok" : isAuthenticated().username} subtitle={isAuthenticated() == null ? "ok" : isAuthenticated().level} text="Last updated 3 mins ago" className={`${"border-light "}${theme === null ?"themeappscyan":theme}`}>
                  <ListGroup flush>
                    <ListGroupItem tag="button" action className="border-light">
                      <MdPersonPin /> Profile
                    </ListGroupItem>
                    <ListGroupItem tag="button" action className="border-light">
                      <MdSettingsApplications /> Settings
                    </ListGroupItem>
                    <Logout keluar="Logout" />
                  </ListGroup>
                </UserCard>
              </PopoverBody>
            </Popover>
          </NavItem>
        </Nav>
      </Navbar>
    );
  }
}

export default Header;
