import {
    GET_BARANGMASUK,
    DELETE_BARANGMASUK,
    ADD_BARANGMASUK,
    UPDATE_BARANGMASUK,
    EDIT_BARANGMASUK,
  } from '../../pages/actions/barangmasukAction';
  
  const barangmasuk = (state, action) => {
    const { type } = action;
    switch (type) {
      case EDIT_BARANGMASUK:
        return {
          ...state,
          editBarangmasukResult: action.payload.data,
        };
      case UPDATE_BARANGMASUK:
        return {
          ...state,
          updateBarangmasukResult: action.payload.data,
          updateBarangmasukLoading: action.payload.loading,
          updateBarangmasukError: action.payload.errorMessage,
        };
      case ADD_BARANGMASUK:
        return {
          ...state,
          addBarangmasukResult: action.payload.data,
          addBarangmasukLoading: action.payload.loading,
          addBarangmasukError: action.payload.errorMessage,
        };
      case DELETE_BARANGMASUK:
        return {
          ...state,
          deleteBarangmasukResult: action.payload.data,
          deleteBarangmasukLoading: action.payload.loading,
          deleteBarangmasukError: action.payload.errorMessage,
        };
      case GET_BARANGMASUK:
        return {
          ...state,
          getBarangmasukResult: action.payload.data,
          getBarangmasukLoading: action.payload.loading,
          getBarangmasukError: action.payload.errorMessage,
        };
        return state;
    }
  };
  
  export default barangmasuk;
  