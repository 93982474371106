export const localhost = `https://api.erpkeuangan.jpdn.net.id`; 
// export const localhost = `http://localhost:8000`; 
const localhostconfig = `https://api.teknis.qnn.co.id`;
const apiURL = ``;
const endpoint = `${localhost}${apiURL}`;
const endpointconfig = `${localhostconfig}${apiURL}`;

//DASHBOARD

export const API_URL_kirimreminder = `${endpoint}/kirimreminder/`;

export const API_URL_totaltagihanpowertel = `${endpoint}/totaltagihanpowertel/`;
export const API_URL_tagihanbelumbayarpowertel = `${endpoint}/tagihanbelumbayarpowertel/`;
export const API_URL_tagihanlunaspowertel = `${endpoint}/tagihanlunaspowertel/`;
export const API_URL_totaltagihantamin = `${endpoint}/totaltagihantamin/`;
export const API_URL_tagihanbelumbayartamin = `${endpoint}/tagihanbelumbayartamin/`;
export const API_URL_tagihanlunastamin = `${endpoint}/tagihanlunastamin/`;

export const API_URL_pertumbuhanpelanggan = `${endpoint}/pertumbuhanpelanggan/`;
export const API_URL_pertumbuhantagihan = `${endpoint}/pertumbuhantagihan/`;
export const API_URL_grafiksales = `${endpoint}/grafiksales/`;
export const API_URL_grafikpersales = `${endpoint}/grafikpersales/`;
export const API_URL_grafiksalesperminggu = `${endpoint}/grafiksalesperminggu/`;
export const API_URL_grafiksalesperbulan = `${endpoint}/grafiksalesperbulan/`;

export const API_URL_datapelangganbulanlalu = `${endpoint}/datapelangganbulanlalu/`;
export const API_URL_datapelangganbulanini = `${endpoint}/datapelangganbulanini/`;
export const API_URL_datapelangganbulanlalusemua = `${endpoint}/datapelangganbulanlalusemua/`;
export const API_URL_datapelangganbulaninisemua = `${endpoint}/datapelangganbulaninisemua/`;
export const API_URL_datapelangganmingguini = `${endpoint}/datapelangganmingguini/`;
export const API_URL_datapelangganminggulalu = `${endpoint}/datapelangganminggulalu/`;
export const API_URL_datapelanggankemarin = `${endpoint}/datapelanggankemarin/`;
export const API_URL_datapelangganhariini = `${endpoint}/datapelangganhariini/`;
export const API_URL_antrianinstalasipelanggan = `${endpoint}/antrianinstalasipelanggan/`;

// NEW API ROUTE
export const API_URL_loginuser = `${endpoint}/login/`;
export const API_URL_getakunolt = `${endpoint}/getakunolt/`;
export const API_URL_edelakunolt = `${endpoint}/edelakunolt/`;

// FILE MASTER
export const API_URL_tipepembayarannonritel = `${endpoint}/tipepembayarannonritel/`;
export const API_URL_tipelayanan = `${endpoint}/tipelayanan/`;
export const API_URL_getjenispelanggan = `${endpoint}/jenispelanggan/`;
export const API_URL_datapaketinternet = `${endpoint}/datapaketinternet/`;
export const API_URL_createpaketinternet = `${endpoint}/createpaketinternet/`;
export const API_URL_edelpaketinternet = `${endpoint}/edelpaketinternet/`;

export const API_URL_datagangguanpelanggan = `${endpoint}/datagangguanpelanggan/`;
export const API_URL_tiketgangguan = `${endpoint}/tiketgangguan/`;
export const API_URL_edeldatagangguan = `${endpoint}/edeldatagangguan/`;
export const API_URL_createdatagangguan = `${endpoint}/createdatagangguan/`;

export const API_URL_perusahaan = `${endpoint}/getperusahaan/`;

export const API_URL_datamaterial = `${endpoint}/datamaterial/`;
export const API_URL_creatematerial = `${endpoint}/creatematerial/`;
export const API_URL_edelmaterial = `${endpoint}/edelmaterial/`;

export const API_URL_datatipebayar = `${endpoint}/datatipebayar/`;
export const API_URL_createtipebayar = `${endpoint}/createtipebayar/`;
export const API_URL_edeltipebayar = `${endpoint}/edeltipebayar/`;

export const API_URL_gettipearuskas = `${endpoint}/gettipearuskas/`;
export const API_URL_createtipearuskas = `${endpoint}/createtipearuskas/`;
export const API_URL_edeltipearuskas = `${endpoint}/edeltipearuskas/`;

export const API_URL_datarekeningbank = `${endpoint}/datarekeningbank/`;
export const API_URL_createrekeningbank = `${endpoint}/createrekeningbank/`;
export const API_URL_edelrekeningbank = `${endpoint}/edelrekeningbank/`;

export const API_URL_getdatasatuanproduk = `${endpoint}/getdatasatuanproduk/`;
export const API_URL_getsatuanproduk = `${endpoint}/getsatuanproduk/`;
export const API_URL_createsatuanproduk = `${endpoint}/createsatuanproduk/`;
export const API_URL_edelsatuanproduk = `${endpoint}/edelsatuanproduk/`;

export const API_URL_getdatalokasi = `${endpoint}/getdatalokasi/`;
export const API_URL_getlokasi = `${endpoint}/getlokasi/`;
export const API_URL_getposisi = `${endpoint}/getposisi/`;
export const API_URL_createlokasi = `${endpoint}/createlokasi/`;
export const API_URL_edellokasi = `${endpoint}/edellokasi/`;

// KEUANGAN
export const API_URL_kasdanbank = `${endpoint}/kasdanbank/`;
export const API_URL_kaskasir = `${endpoint}/kaskasir/`;
export const API_URL_kaspenerimaan = `${endpoint}/kaspenerimaan/`;
export const API_URL_pettycash = `${endpoint}/pettycash/`;
export const API_URL_rekeningutama = `${endpoint}/rekeningutama/`;
export const API_URL_rekeningpembayaran = `${endpoint}/rekeningpembayaran/`;
export const API_URL_rekeningpembayaran1 = `${endpoint}/rekeningpembayaran1/`;
export const API_URL_rekeningbelanja = `${endpoint}/rekeningbelanja/`;
export const API_URL_setortunai = `${endpoint}/setortunai/`;
export const API_URL_transfer = `${endpoint}/transfer/`;
export const API_URL_getrekonsiliasi = `${endpoint}/rekonsiliasi/`;
export const API_URL_getrekeningkoran = `${endpoint}/rekeningkoran/`;
export const API_URL_createrekeningkoran = `${endpoint}/createrekeningkoran/`;

export const API_URL_getakunrouter = `${endpoint}/getakunrouter/`;

export const API_URL_edelakunrouter = `${endpoint}/edelakunrouter/`;

export const API_URL_getprovinsi = `${endpoint}/getprovinsi/`;
export const API_URL_getkabkot = `${endpoint}/getkabkot/`;

export const API_URL_getoltnode = `${endpoint}/getoltnode/`;
export const API_URL_getoltnodedetail = `${endpoint}/getoltnodedetail/`;
export const API_URL_createoltnode = `${endpoint}/createoltnode/`;
export const API_URL_edeloltnode = `${endpoint}/edeloltnode/`;

export const API_URL_getregpelanggan = `${endpoint}/getregpelanggan/`;
export const API_URL_regpelanggan = `${endpoint}/regpelanggan/`;
export const API_URL_edelregpelanggan = `${endpoint}/edelregpelanggan/`;

export const API_URL_getoltregpelanggan = `${endpoint}/getoltregpelanggan/`;
export const API_URL_createoltregpelanggan = `${endpoint}/createoltregpelanggan/`;
export const API_URL_edeloltregpelanggan = `${endpoint}/edeloltregpelanggan/`;

export const API_URL_ambildataconfig = `${endpoint}/ambildataconfig/`;
export const API_URL_setuppelanggan = `${endpoint}/setuppelanggan/`;

export const API_URL_spesifikpelanggan = `${endpoint}/spesifikpelanggan/`;
export const API_URL_detektiketgangguan = `${endpoint}/detektiketgangguan/`;
export const API_URL_detilteknispelanggan = `${endpoint}/detailteknispelanggan/`;
export const API_URL_infomodempelanggan = `${endpoint}/infomodempelanggan/`;
export const API_URL_rebootmodem = `${endpoint}/rebootmodem/`;
export const API_URL_restorefactory = `${endpoint}/restorefactory/`;

export const API_URL_datapelanggan = `${endpoint}/pelanggan/`;
export const API_URL_createdetailpelanggan = `${endpoint}/createdetailpelanggan/`;
export const API_URL_edeldetailpelanggan = `${endpoint}/edeldetailpelanggan/`;
export const API_URL_createpelanggan = `${endpoint}/createpelanggan/`;
export const API_URL_datapelanggandetail = `${endpoint}/datapelanggandetail/`;
export const API_URL_pelanggandetail = `${endpoint}/detailpelanggan/`;

export const API_URL_getdppelanggan = `${endpoint}/dppelanggan/`;
export const API_URL_createdppelanggan = `${endpoint}/createdppelanggan/`;
export const API_URL_edeldppelanggan = `${endpoint}/edeldppelanggan/`;
export const API_URL_bayartagihanauto = `${endpoint}/bayartagihanauto/`;

export const API_URL_statuspelanggan = `${endpoint}/getstatuspelanggan/`;
export const API_URL_tipepelanggan = `${endpoint}/gettipepelanggan/`;

export const API_URL_getusers = `${endpoint}/getusers/`;

export const API_URL_createdatateknis = `${endpoint}/createdatateknis/`;

export const API_URL_edeldatateknis = `${endpoint}/edeldatateknis/`;

export const API_URL_getcoapelanggan = `${endpoint}/getcoapelanggan/`;
export const API_URL_createcoapelanggan = `${endpoint}/createcoapelanggan/`;
export const API_URL_deletecoapelanggan = `${endpoint}/deletecoapelanggan/`;
export const API_URL_pilihdkpelanggan = `${endpoint}/pilihdkpelanggan/`;

// ###############################################
export const API_URL_getpengembanganwilayahfttx = `${endpoint}/getpengembanganwilayahfttx/`;
export const API_URL_createwilayahfttx = `${endpoint}/createwilayahfttx/`;
export const API_URL_edelwilayahfttx = `${endpoint}/edelwilayahfttx/`;

export const API_URL_getlistfttx = `${endpoint}/getnodefttx/`;

// ################

export const API_URL_setuppelanggan1 = `${endpoint}/setuppelanggan1/`;
export const API_URL_infogantimodem = `${endpoint}/infogantimodem/`;
export const API_URL_cekpasswifi = `${endpoint}/cekpasswifi/`;
export const API_URL_bandwidthupgrade = `${endpoint}/bandwidthupgrade/`;
export const API_URL_gantipassword = `${endpoint}/gantipassword/`;
export const API_URL_cekInfodialup = `${endpoint}/cekInfodialup/`;
export const API_URL_gantimodem = `${endpoint}/gantimodem/`;

export const API_URL_detektroti = `${endpoint}/detektroti/`;
export const API_URL_closedatagangguan = `${endpoint}/closedatagangguan/`;
export const API_URL_Settingswifi = `${endpoint}/settingswifi/`;
export const API_URL_ambildatawifi = `${endpoint}/ambildatawifi/`;

export const API_URL_testkoneksirouter = `${endpoint}/testkoneksirouter/`;
export const API_URL_createakunrouter = `${endpoint}/createakunrouter/`;
export const API_URL_createakunolt = `${endpoint}/createakunolt/`;
export const API_URL_testkoneksiolt = `${endpoint}/testkoneksiolt/`;

export const API_URL_getwilayah = `${endpoint}/getwilayah/`;

export const API_URL_editregpelanggan = `${endpoint}/editregpelanggan/`;
export const API_URL_getidkotakab = `${endpoint}/getidkotakab/`;
export const API_URL_getprofileforselect = `${endpoint}/getprofileforselect/`;

export const API_URL_getprofilebandwidth = `${endpoint}/getprofilebandwidth/`;
export const API_URL_getprofilevlan = `${endpoint}/getprofilevlan/`;
export const API_URL_getprofilebwdb = `${endpoint}/getprofilebwdb/`;
export const API_URL_saveprofiledownload = `${endpoint}/saveprofiledownload/`;
export const API_URL_delprofiledownload = `${endpoint}/delprofiledownload/`;

export const API_URL_createnodefttx = `${endpoint}/createnodefttx/`;
export const API_URL_edelnodefttx = `${endpoint}/edelnodefttx/`;

export const API_URL_saveprofileupload = `${endpoint}/saveprofileupload/`;
export const API_URL_delprofileupload = `${endpoint}/delprofileupload/`;

export const API_URL_saveprofilevlanoam = `${endpoint}/saveprofilevlanoam/`;
export const API_URL_delprofilevlanoam = `${endpoint}/delprofilevlanoam/`;

export const API_URL_saveprofilevlanservis = `${endpoint}/saveprofilevlanservis/`;
export const API_URL_delprofilevlanservis = `${endpoint}/delprofilevlanservis/`;

export const API_URL_configpelanggan = `${endpoint}/configpelanggan/`;
export const API_URL_getpaketinternet = `${endpoint}/getpaketinternet/`;

// KEUANGAN
export const API_URL_getmaterial = `${endpoint}/material/`;
export const API_URL_getstokbarang = `${endpoint}/stokbarang/`;
export const API_URL_getstokresidu = `${endpoint}/getstokresidu/`;
export const API_URL_createstokbarang = `${endpoint}/createstokbarang/`;
export const API_URL_edelstokbarang = `${endpoint}/edelstokbarang/`;

export const API_URL_gettipebayar = `${endpoint}/gettipebayar/`;
export const API_URL_getrekeningbank = `${endpoint}/getrekeningbank/`;
export const API_URL_createtagihan = `${endpoint}/createtagihan/`;
export const API_URL_addtagihan = `${endpoint}/addtagihan/`;
export const API_URL_tagihanpelanggan = `${endpoint}/tagihan/`;
export const API_URL_periodetagihan = `${endpoint}/periodetagihan/`;
export const API_URL_tagihanpelanggantotal = `${endpoint}/tagihantotal/`;
export const API_URL_tagihanpelangganlunas = `${endpoint}/tagihanlunas/`;
export const API_URL_tagihanpelangganbelumbayar = `${endpoint}/tagihanbelumbayar/`;
export const API_URL_pembayaranpelanggan = `${endpoint}/datapembayaran/`;
export const API_URL_exportdatapembayaran = `${endpoint}/exportdatapembayaran/`;
export const API_URL_exportdatapelanggan = `${endpoint}/exportdatapelanggan/`;
export const API_URL_exportdatatagihan = `${endpoint}/exportdatatagihan/`;
export const API_URL_exportdatapiutang = `${endpoint}/exportdatapiutang/`;
export const API_URL_exportdataoutofarea = `${endpoint}/exportdataoutofarea/`;
export const API_URL_exportgangguan = `${endpoint}/exportgangguan/`;
export const API_URL_pembayaranpelanggandetail = `${endpoint}/datapembayarandetail/`;
export const API_URL_kasir = `${endpoint}/kasir/`;
export const API_URL_penerimaankasir = `${endpoint}/penerimaankasir/`;
export const API_URL_detailpenerimaankasir = `${endpoint}/detailpenerimaankasir/`;
export const API_URL_terimapenerimaankasir = `${endpoint}/terimapenerimaankasir/`;
export const API_URL_penyerahankasir = `${endpoint}/penyerahankasir/`;
export const API_URL_edelpembayaran = `${endpoint}/edelpembayaran/`;
export const API_URL_edeltagihanpelanggan = `${endpoint}/edeltagihanpelanggan/`;
export const API_URL_getsudahbayartagpel = `${endpoint}/getsudahbayartagpel/`;
export const API_URL_bayartagihanpelanggan = `${endpoint}/bayartagihanpelanggan/`;
export const API_URL_settingakun = `${endpoint}/settingakun/`;
export const API_URL_piutang = `${endpoint}/piutang/`;
//pengeluaran
export const API_URL_getpengeluaran = `${endpoint}/getpengeluaran/`;
export const API_URL_createpengeluaran = `${endpoint}/createpengeluaran/`;
export const API_URL_edelpengeluaran = `${endpoint}/edelpengeluaran/`;

// Akuntansi
// ====== CoA =====
// GET
export const API_URL_dataakunbukbes = `${endpoint}/akunbukbes/`;
export const API_URL_datakelompok = `${endpoint}/kelompok/`;
export const API_URL_datagolongan = `${endpoint}/golongan/`;
export const API_URL_datasubgolongan = `${endpoint}/subgolongan/`;
export const API_URL_dataakun = `${endpoint}/akun/`;
export const API_URL_datacariakun = `${endpoint}/cariakun/`;

// GET
export const API_URL_getrekeninguse = `${endpoint}/getrekeninguse/`;
export const API_URL_getakunbukubesar = `${endpoint}/getakunbukbes/`;
export const API_URL_getabbkel = `${endpoint}/getabbkel/`;
export const API_URL_getkelompok = `${endpoint}/getkelompok/`;
export const API_URL_getgolongan = `${endpoint}/getgolongan/`;
export const API_URL_getsubgolongan = `${endpoint}/getsubgolongan/`;
export const API_URL_getakun = `${endpoint}/getakun/`;
export const API_URL_getdataakun = `${endpoint}/getdataakun/`;
export const API_URL_getakunactive = `${endpoint}/getakunactive/`;
export const API_URL_getallakun = `${endpoint}/getallakun/`;

//POST
export const API_URL_createakunbukubesar = `${endpoint}/createakunbukbes/`;
export const API_URL_createkelompok = `${endpoint}/createkelompok/`;
export const API_URL_creategolongan = `${endpoint}/creategolongan/`;
export const API_URL_createsubgolongan = `${endpoint}/createsubgolongan/`;
export const API_URL_createakun = `${endpoint}/createakun/`;

//PUT
export const API_URL_updelakunbukubesar = `${endpoint}/updelakunbukbes/`;
export const API_URL_updelkelompok = `${endpoint}/updelkelompok/`;
export const API_URL_updelgolongan = `${endpoint}/updelgolongan/`;
export const API_URL_updelsubgolongan = `${endpoint}/updelsubgol/`;
export const API_URL_updelakun = `${endpoint}/updelakun/`;

//transaksi
export const API_URL_media = `${endpoint}`;
export const API_URL_getjurnal = `${endpoint}/getjurnal/`;
export const API_URL_transaksi = `${endpoint}/gettransaksi/`;
export const API_URL_drafttransaksi = `${endpoint}/getdrafttransaksi/`;
export const API_URL_createtransaksi = `${endpoint}/createtransaksi/`;
export const API_URL_edelregtransaksi = `${endpoint}/edelregtransaksi/`;
export const API_URL_datatransaksidetail = `${endpoint}/datatransaksidetail/`;
export const API_URL_debitkredit = `${endpoint}/debitkredit/`;
export const API_URL_akun = `${endpoint}/akunjurnal/`;
export const API_URL_tipearuskas = `${endpoint}/tipearuskas/`;
export const API_URL_createjurnal = `${endpoint}/createjurnal/`;
export const API_URL_deletejurnal = `${endpoint}/deletejurnal/`;
export const API_URL_getdatajurnal = `${endpoint}/getdatajurnal/`;
export const API_URL_export_jurnal_xls = `${endpoint}/export_jurnal_xls/`;

//Pengelolaan Beban
export const API_URL_getdetailbebanproyek = `${endpoint}/getdetailbebanproyek/`;
export const API_URL_getcoabeban = `${endpoint}/getcoabeban/`;
export const API_URL_getdetailbeban = `${endpoint}/getdetailbeban/`;
export const API_URL_getsettingbeban = `${endpoint}/getsettingbeban/`;
export const API_URL_getrutin = `${endpoint}/getrutin/`;
export const API_URL_getrutinfluktuatif = `${endpoint}/getrutinfluktuatif/`;
export const API_URL_getpartikular = `${endpoint}/getpartikular/`;
export const API_URL_getoperasional = `${endpoint}/getoperasional/`;
export const API_URL_getproyek = `${endpoint}/getproyek/`;
export const API_URL_getlainnya = `${endpoint}/getlainnya/`;
export const API_URL_createrutinfluktuatif = `${endpoint}/createrutinfluktuatif/`;
export const API_URL_createrutintetap = `${endpoint}/createrutintetap/`;
export const API_URL_createpartikular = `${endpoint}/createpartikular/`;
export const API_URL_createoperasional = `${endpoint}/createoperasional/`;
export const API_URL_createproyek = `${endpoint}/createproyek/`;
export const API_URL_createlainnya = `${endpoint}/createlainnya/`;
export const API_URL_updaterutintetap = `${endpoint}/updaterutintetap/`;
export const API_URL_updaterutinfluktuatif = `${endpoint}/updaterutinfluktuatif/`;
export const API_URL_updatepartikular = `${endpoint}/updatepartikular/`;
export const API_URL_updateoperasional = `${endpoint}/updateoperasional/`;
export const API_URL_edelproyek = `${endpoint}/edelproyek/`;
export const API_URL_updatelainnya = `${endpoint}/updatelainnya/`;
export const API_URL_createdetailbeban = `${endpoint}/createdetailbeban/`;
export const API_URL_createsettingbeban = `${endpoint}/createsettingbeban/`;
export const API_URL_createcoabeban = `${endpoint}/createcoabeban/`;
export const API_URL_createdetailproyek = `${endpoint}/createdetailproyek/`;
export const API_URL_deletecoabeban = `${endpoint}/deletecoabeban/`;
export const API_URL_deletebeban = `${endpoint}/deletebeban/`;
export const API_URL_pilihdk = `${endpoint}/pilihdk/`;

//bebantagihan
export const API_URL_getbebantagihan = `${endpoint}/getbebantagihan/`;
export const API_URL_createbebantagihan = `${endpoint}/createbebantagihan/`;
export const API_URL_edelbebantagihan = `${endpoint}/edelbebantagihan/`;

//hutang
export const API_URL_gethutang = `${endpoint}/gethutang/`;
export const API_URL_createhutang = `${endpoint}/createhutang/`;
export const API_URL_edelhutang = `${endpoint}/edelhutang/`;
//penagihan
export const API_URL_getpenagihan = `${endpoint}/getpenagihan/`;
export const API_URL_createpenagihan = `${endpoint}/createpenagihan/`;
export const API_URL_edelpenagihan = `${endpoint}/edelpenagihan/`;
//progress
export const API_URL_getprogress = `${endpoint}/getprogress/`;
export const API_URL_createprogress = `${endpoint}/createprogress/`;
export const API_URL_edelprogress = `${endpoint}/edelprogress/`;
//pencairan
export const API_URL_getpencairan = `${endpoint}/getpencairan/`;
export const API_URL_createpencairan = `${endpoint}/createpencairan/`;
export const API_URL_edelpencairan = `${endpoint}/edelpencairan/`;
//pengajuan
export const API_URL_getpengajuan = `${endpoint}/getpengajuan/`;
export const API_URL_createpengajuan = `${endpoint}/createpengajuan/`;
export const API_URL_edelpengajuan = `${endpoint}/edelpengajuan/`;
//persetujuan
export const API_URL_getpersetujuan = `${endpoint}/getpersetujuan/`;
export const API_URL_createpersetujuan = `${endpoint}/createpersetujuan/`;
export const API_URL_edelpersetujuan = `${endpoint}/edelpersetujuan/`;
//pembelanjaan
export const API_URL_getpembelanjaan = `${endpoint}/getpembelanjaan/`;
export const API_URL_createpembelanjaan = `${endpoint}/createpembelanjaan/`;
export const API_URL_edelpembelanjaan = `${endpoint}/edelpembelanjaan/`;

//suspanse or open
export const API_URL_suspanse = `${endpoint}/suspanse/`;
export const API_URL_opensuspanse = `${endpoint}/opensuspanse/`;

//pendapatan
export const API_URL_getpendapatan = `${endpoint}/getpendapatan/`;
export const API_URL_getpendapatanupdate = `${endpoint}/getpendapatanupdate/`;
export const API_URL_getpendapatanpelanggan = `${endpoint}/getpendapatanpelanggan/`;
export const API_URL_creatependapatan = `${endpoint}/creatependapatan/`;
export const API_URL_edelpendapatan = `${endpoint}/edelpendapatan/`;
export const API_URL_datapelanggantype = `${endpoint}/datapelanggantype/`;
export const API_URL_gettypelayanan = `${endpoint}/gettypelayanan/`;

//--proyek--
export const API_URL_getdataproyek = `${endpoint}/getdataproyek/`;
export const API_URL_createdataproyek = `${endpoint}/createdataproyek/`;
export const API_URL_edeldataproyek = `${endpoint}/edeldataproyek/`;
export const API_URL_getPelangganSelect = `${endpoint}/getPelangganSelect/`;
export const API_URL_createdetailproyekobjektif = `${endpoint}/createdetailproyekobjektif/`;
export const API_URL_detailproyekobjektif = `${endpoint}/detailproyekobjektif/`;
export const API_URL_createdetailproyekresiko = `${endpoint}/createdetailproyekresiko/`;
export const API_URL_detailproyekresiko = `${endpoint}/detailproyekresiko/`;
export const API_URL_createdetailproyekbudget = `${endpoint}/createdetailproyekbudget/`;
export const API_URL_detailproyekbudget = `${endpoint}/detailproyekbudget/`;
export const API_URL_createdetailproyekdayadukung = `${endpoint}/createdetailproyekdayadukung/`;
export const API_URL_detailproyekdayadukung = `${endpoint}/detailproyekdayadukung/`;
export const API_URL_createdetailproyeksow = `${endpoint}/createdetailproyeksow/`;
export const API_URL_detailproyeksow = `${endpoint}/detailproyeksow/`;
export const API_URL_createdetailproyekteampelaksana = `${endpoint}/createdetailproyekteampelaksana/`;
export const API_URL_detailproyekteampelaksana = `${endpoint}/detailproyekteampelaksana/`;
export const API_URL_createdetailproyekwaktupelaksana = `${endpoint}/createdetailproyekwaktupelaksana/`;
export const API_URL_detailproyekwaktupelaksana = `${endpoint}/detailproyekwaktupelaksana/`;
export const API_URL_createdetailproyekpengiriman = `${endpoint}/createdetailproyekpengiriman/`;
export const API_URL_detailproyekpengiriman = `${endpoint}/detailproyekpengiriman/`;
export const API_URL_createdetailproyekkegiatan = `${endpoint}/createdetailproyekkegiatan/`;
export const API_URL_detailproyekkegiatan = `${endpoint}/detailproyekkegiatan/`;
export const API_URL_adddokumentasikegiatan = `${endpoint}/adddokumentasikegiatan/`;
export const API_URL_getdatapengadaan = `${endpoint}/getdatapengadaan/`;

//perlengkapan
export const API_URL_getperlengkapan = `${endpoint}/getperlengkapan/`;
export const API_URL_createperlengkapan = `${endpoint}/createperlengkapan/`;
export const API_URL_edelperlengkapan = `${endpoint}/edelperlengkapan/`;

//materialstok
// export const API_URL_getmaterialstok = `${endpoint}/getmaterialstok/`;
// export const API_URL_creatematerialstok = `${endpoint}/creatematerialstok/`;
// export const API_URL_edelmaterialstok = `${endpoint}/edelmaterialstok/`;
export const API_URL_getdetailstok = `${endpoint}/getdetailstok/`;
export const API_URL_getdetailstokposisi = `${endpoint}/getdetailstokposisi/`;
export const API_URL_getstok = `${endpoint}/getstok/`;

//aset
export const API_URL_getaset = `${endpoint}/getaset/`;
export const API_URL_createaset = `${endpoint}/createaset/`;
export const API_URL_edelaset = `${endpoint}/edelaset/`;

//peralatan
export const API_URL_getperalatan = `${endpoint}/getperalatan/`;
export const API_URL_createperalatan = `${endpoint}/createperalatan/`;
export const API_URL_edelperalatan = `${endpoint}/edelperalatan/`;

//barangmasuk
export const API_URL_getbarangmasuk = `${endpoint}/getbarangmasuk/`;
export const API_URL_createbarangmasuk = `${endpoint}/createbarangmasuk/`;
export const API_URL_edelbarangmasuk = `${endpoint}/edelbarangmasuk/`;

//barangkeluar
export const API_URL_getbarangkeluar = `${endpoint}/getbarangkeluar/`;
export const API_URL_createbarangkeluar = `${endpoint}/createbarangkeluar/`;
export const API_URL_edelbarangkeluar = `${endpoint}/edelbarangkeluar/`;

//barangreturn
export const API_URL_getbarangreturn = `${endpoint}/getbarangreturn/`;
export const API_URL_createbarangreturn = `${endpoint}/createbarangreturn/`;
export const API_URL_edelbarangreturn = `${endpoint}/edelbarangreturn/`;

//barangformresidu
export const API_URL_getbarangformresidu = `${endpoint}/getbarangformresidu/`;
export const API_URL_createbarangformresidu = `${endpoint}/createbarangformresidu/`;
export const API_URL_edelbarangformresidu = `${endpoint}/edelbarangformresidu/`;

//detailbarangmasuk
export const API_URL_detailbarangmasuk = `${endpoint}/detailbarangmasuk/`;
export const API_URL_addexpand = `${endpoint}/addexpand/`;

// Prakeluaran
export const API_URL_dataprakeluaran = `${endpoint}/getprakeluaran/`;
export const API_URL_createprakeluaran = `${endpoint}/createprakeluaran/`;
export const API_URL_edelprakeluaran = `${endpoint}/edelprakeluaran/`;
export const API_URL_getdpdatpel = `${endpoint}/getdpdatpel/`;
export const API_URL_createdpdatpel = `${endpoint}/createdpdatpel/`;
export const API_URL_getcoaprakeluaran = `${endpoint}/coaprakeluaran/`;
export const API_URL_createcoaprakeluaran = `${endpoint}/createcoaprakeluaran/`;
export const API_URL_deletecoaprakeluaran = `${endpoint}/deletecoaprakeluaran/`;
export const API_URL_pilihdkcoaprakeluaran = `${endpoint}/pilihdkcoaprakeluaran/`;

//sales
export const API_URL_getsales = `${endpoint}/getsales/`;
export const API_URL_createsales = `${endpoint}/createsales/`;
export const API_URL_edelsales = `${endpoint}/edelsales/`;
