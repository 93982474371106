import {
  GET_HUTANG,
  DELETE_HUTANG,
  ADD_HUTANG,
  UPDATE_HUTANG,
  EDIT_HUTANG,
} from '../../pages/actions/hutangAction';

const Hutang = (state, action) => {
  const { type } = action;
  switch (type) {
    case EDIT_HUTANG:
      return {
        ...state,
        editHutangResult: action.payload.data,
      };
    case UPDATE_HUTANG:
      return {
        ...state,
        updateHutangResult: action.payload.data,
        updateHutangLoading: action.payload.loading,
        updateHutangError: action.payload.errorMessage,
      };
    case ADD_HUTANG:
      return {
        ...state,
        addHutangResult: action.payload.data,
        addHutangLoading: action.payload.loading,
        addHutangError: action.payload.errorMessage,
      };
    case DELETE_HUTANG:
      return {
        ...state,
        deleteHutangResult: action.payload.data,
        deleteHutangLoading: action.payload.loading,
        deleteHutangError: action.payload.errorMessage,
      };
    case GET_HUTANG:
      return {
        ...state,
        getHutangResult: action.payload.data,
        getHutangLoading: action.payload.loading,
        getHutangError: action.payload.errorMessage,
      };
      return state;
  }
};

export default Hutang;
